<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Teleport to="body">
    <div
      v-if="showVideoCall"
      ref="videoCallPlayer"
      class="video-call-player"
      :class="{ 'is-minimized': !cameraControlsOpen }"
      v-element-visibility="onElementVisibility"
      style="touch-action: none"
      :style="style"
    >
      <div class="drag-handle stack" ref="dragHandle">
        <div class="drag-icon-wrap">
          <font-awesome-icon icon="fa-regular fa-grip-lines" class="white" />
        </div>
      </div>
      <div class="video-section-main overflow-x relative">
        <div
          v-if="!isMidCall"
          class="pill"
          v-skeleton="{
            loading: state.loading,
            minWidth: 25,
            maxWidth: 25
          }"
        >
          <font-awesome-icon
            icon="fa-light fa-circle-exclamation"
            class="icon-size-1"
          />
          Premium accounts can request calling transcripts
        </div>

        <div class="remote-video" ref="remoteVideoDiv">
          <!-- local video -->
          <div
            class="local-video"
            style="align-self: center"
            ref="localVideoDiv"
          >
            <div
              v-if="!showErrorMsg && !state.loading"
              class="remote-audio"
              :class="{
                muted: state.remoteAudioMuted,
                unmuted: !state.remoteAudioMuted
              }"
            >
              <network-quality-bar
                :quality="state.localNetworkQuality"
              ></network-quality-bar>

              <!-- <font-awesome-icon
                icon="fa-light fa-microphone-slash"
                class="muted"
              />
              <font-awesome-icon
                icon="fa-light fa-microphone"
                class="unmuted"
              />
              <span class="sr-only"
                >Remote User Audio:
                {{ state.remoteAudioMuted ? 'muted' : 'unmuted' }}</span
              > -->
            </div>
          </div>
          <!-- /local video -->

          <!-- status text -->
          <div v-if="showConnecting" class="connecting-text font-bold">
            Connecting to {{ fullUserInfo.coparentFirstName }}
            <div class="ml-1">
              <Loading :loading="true" />
            </div>
          </div>

          <!-- error text -->
          <div
            class="error-text"
            :class="{ 'is-minimized': !cameraControlsOpen }"
          >
            {{ state.errorMsg }}
          </div>
        </div>
        <!-- Voicemail prompt -->
        <div v-if="state.showVoiceMailPrompt" class="stack voicemail-prompt">
          No response.
          <br />
          Would you like to send a video voicemail
          <br />
          <div class="flex gap-1">
            <button class="btn secondary" @click="startVoicemail()">
              Send Video Voicemail
            </button>
            <!-- <button class="btn secondary" @click="cleanUpAndClose()">
                  close
                </button> -->
          </div>
        </div>
        <!-- Time remaining for call -->
        <div
          v-if="showElapsedTime"
          class="in-call-alerts-div"
          :class="{ 'is-minimized': !cameraControlsOpen }"
        >
          <div
            v-if="state.remoteAudioMuted && !state.isVoiceMail"
            class="call-alert"
          >
            <font-awesome-icon
              icon="fa-light fa-microphone-slash"
              class="icon-size-1"
            />
            <span> {{ fullUserInfo.coparentFirstName }} is muted. </span>
          </div>
          <div
            v-if="!state.remoteVideoOn && !state.isVoiceMail"
            class="call-alert"
          >
            <font-awesome-icon
              icon="fa-light fa-video-slash"
              class="icon-size-1"
            />
            <span>
              {{ fullUserInfo.coparentFirstName }}'s camera is disabled.
            </span>
          </div>
          <div v-if="showRemainingTime" class="call-alert">
            <font-awesome-icon
              icon="fa-light fa-circle-exclamation"
              class="icon-size-1"
            />
            <span>
              {{ state.formattedTimeRemaining }}
            </span>
          </div>
          <div class="call-alert">
            <!-- todo: find icon -->
            <font-awesome-icon
              icon="fa-light fa-arrows-rotate"
              class="icon-size-1"
            />
            <span>
              {{ state.formattedTimeElapsed }}
            </span>
          </div>
        </div>
        <!-- Control bar -->
        <!-- v-if="showControls" -->
        <div
          :class="{ 'is-minimized': !cameraControlsOpen }"
          class="camera-controls"
          v-skeleton="{
            loading: state.loading,
            minWidth: 25,
            maxWidth: 25
          }"
        >
          <div class="relative flex flex-column w-100 items-center">
            <button @click="toggleCameraControls" class="camera-control-toggle">
              <font-awesome-icon
                icon="fa-light fa-chevron-down"
                class="white icon-size-1"
              />
              <span class="sr-only">minimize</span>
            </button>

            <div class="controls-div">
              <!-- Camera -->
              <div class="flex align-center controls-relative gap-1">
                <Popper
                  class="info"
                  placement="top"
                  :arrow="true"
                  :show="state.showVideoPopper"
                >
                  <button
                    class="video-control"
                    v-if="state.localVideoOn"
                    @click="turnLocalVideoOff()"
                    ref="videoToggle"
                  >
                    <font-awesome-icon
                      icon="fa-light fa-video"
                      class="icon-size-3"
                    />
                    <span class="sr-only">Camera off</span>
                  </button>
                  <template #content>
                    <div
                      class="popper"
                      v-on-click-outside="onClickOutsideVideoToggle"
                    >
                      <span
                        >Must have your camera turned on to start a video
                        call</span
                      >
                    </div>
                  </template>
                </Popper>
                <button
                  class="video-control"
                  v-if="!state.localVideoOn"
                  @click="turnLocalVideoOn()"
                >
                  <font-awesome-icon
                    icon="fa-light fa-video-slash"
                    class="icon-size-3"
                  />
                  <span class="sr-only">Camera on</span>
                </button>

                <button
                  @click="toggleVideoOptions"
                  class="camera-control-toggle"
                  ref="videoToggle"
                  v-on-click-outside="onClickOutsideVideoToggle"
                >
                  <font-awesome-icon
                    icon="fa-light fa-chevron-up"
                    class="white icon-size-1"
                  />
                  <span class="sr-only">minimize</span>
                </button>
                <ul class="dropdown-control-options" v-if="videoOptionsOpen">
                  <li class="dropdown-control-label font-bold">
                    Select a camera
                  </li>
                  <li
                    v-for="camera in getUniqueDevices(cameras)"
                    :key="camera.deviceId"
                    :class="{
                      'is-selected': camera.deviceId == videoInputId
                    }"
                  >
                    <button
                      class="ellipsis"
                      type="button"
                      @click="selectVideoSource(camera.deviceId)"
                    >
                      <font-awesome-icon
                        v-if="camera.deviceId == videoInputId"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      {{ camera.label || 'Unnamed Device' }}
                    </button>
                  </li>
                  <!-- <li class="dropdown-control-divider"></li> -->
                  <li>
                    <button type="button" @click="openDevicesModal('video')">
                      Video Settings
                    </button>
                  </li>
                  <li
                    :class="{
                      'is-selected': state.isBlurred
                    }"
                  >
                    <button type="button" @click="toggleBlur">
                      <font-awesome-icon
                        v-if="state.isBlurred"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      Blur background
                    </button>
                  </li>
                </ul>
              </div>
              <!-- /Camera -->

              <!-- Mute -->
              <div class="flex align-center controls-relative gap-1">
                <Popper
                  class="info"
                  placement="top"
                  :arrow="true"
                  :show="state.showMutePopper"
                >
                  <button
                    class="video-control"
                    v-if="!state.localAudioMuted"
                    @click="muteLocalAudio()"
                  >
                    <font-awesome-icon
                      icon="fa-light fa-microphone"
                      class="icon-size-3"
                    />
                    <span class="sr-only">Mute</span>
                  </button>
                  <template #content>
                    <div
                      class="popper"
                      v-on-click-outside="onClickOutsideAudioToggle"
                    >
                      <span
                        >Must have microphone turned on to start a video
                        call</span
                      >
                    </div>
                  </template>
                </Popper>
                <button
                  class="video-control"
                  v-if="state.localAudioMuted"
                  @click="unMuteLocalAudio()"
                  ref="audioToggle"
                >
                  <font-awesome-icon
                    icon="fa-light fa-microphone-slash"
                    class="icon-size-3"
                  />
                  <span class="sr-only">UnMute</span>
                </button>

                <button
                  @click="toggleAudioOptions"
                  class="camera-control-toggle"
                  ref="audioToggle"
                >
                  <font-awesome-icon
                    icon="fa-light fa-chevron-up"
                    class="white icon-size-1"
                  />
                  <span class="sr-only">minimize</span>
                </button>

                <ul class="dropdown-control-options" v-if="audioOptionsOpen">
                  <li class="dropdown-control-label font-bold">
                    Select a microphone
                  </li>
                  <li
                    v-for="mics in getUniqueDevices(microphones)"
                    :key="mics.deviceId"
                    :class="{
                      'is-selected': mics.deviceId == audioInputId
                    }"
                  >
                    <button
                      class="ellipsis"
                      type="button"
                      @click="selectAudioSource(mics.deviceId)"
                    >
                      <font-awesome-icon
                        v-if="mics.deviceId == audioInputId"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      {{ mics.label || 'Unnamed Device' }}
                    </button>
                  </li>
                  <li class="dropdown-control-divider"></li>
                  <li class="dropdown-control-label font-bold mt-000">
                    Select a speaker
                  </li>
                  <!-- default audio output == empty sink id -->
                  <li
                    :class="{
                      'is-selected': '' == audioOutputId
                    }"
                  >
                    <button
                      class="ellipsis"
                      type="button"
                      @click="selectAudioOutput('')"
                    >
                      <font-awesome-icon
                        v-if="'' == audioOutputId"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      Default
                    </button>
                  </li>
                  <li
                    v-for="speaker in getUniqueDevices(speakers)"
                    :key="speaker.deviceId"
                    :class="{
                      'is-selected': speaker.deviceId == audioOutputId
                    }"
                  >
                    <button
                      class="ellipsis"
                      type="button"
                      @click="selectAudioOutput(speaker.deviceId)"
                    >
                      <font-awesome-icon
                        v-if="speaker.deviceId == audioOutputId"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      {{ speaker.label || 'Unnamed Device' }}
                    </button>
                  </li>
                  <li>
                    <button type="button" @click="openDevicesModal('audio')">
                      Audio Settings
                    </button>
                  </li>
                </ul>
              </div>

              <!-- Flip cameras -->
              <button
                v-if="isMobileDevice"
                class="video-control"
                @click="swapCameras()"
              >
                <font-awesome-icon
                  icon="fa-light fa-camera-rotate"
                  class="icon-size-3"
                />
                <span class="sr-only">Flip cameras</span>
              </button>
              <!-- /Mute -->

              <!-- End Call -->
              <button
                class="cancel"
                v-if="state.isVoiceMail"
                @click="endVoiceMail()"
              >
                <font-awesome-icon
                  id="chevron"
                  icon="fa-light fa-xmark"
                  class="icon-size-4"
                />
                <span class="sr-only">End Voicemail</span>
              </button>

              <button
                class="cancel"
                v-if="!state.isVoiceMail"
                @click="endCall()"
              >
                <font-awesome-icon
                  id="chevron"
                  icon="fa-light fa-xmark"
                  class="icon-size-4"
                />
                <span class="sr-only">End Call</span>
              </button>
              <!-- /End Call -->
            </div>
          </div>
        </div>

        <!-- Start / cancel -->
        <div v-if="showSendCancel" class="send-cancel">
          <button
            class="btn send"
            v-if="
              state.canMakeVideoCall &&
              !state.loading &&
              !showConnecting &&
              !state.errorMsg
            "
            @click="startVideoCall()"
            v-skeleton="state.loading"
          >
            <font-awesome-icon icon="fa-light fa-video" />
            <span class="sr-only">Start call</span>
          </button>

          <!-- <button
                class="btn cancel"
                @click="cancelCall()"
                v-skeleton="state.loading"
              >
                <font-awesome-icon icon="fa-light fa-xmark" />
                <span class="sr-only">Cancel</span>
              </button> -->
        </div>

        <!-- Pre-call messaging -->
        <pre-call-checks
          v-if="showPreCallCheck"
          :loading="state.loading"
        ></pre-call-checks>

        <!-- Time remaining for call " -->
        <!-- <div v-if="showRemainingTime" class="in-call-alerts-div">
              {{ state.formattedTimeRemaining }}
            </div> -->

        <!-- Device settings -->
        <!-- <p class="check-settings font-bold">
              <span>Check your</span> <br />
              <button
                role="button"
                class="btn-text font-bold"
                @click="openDevicesModal('video')"
              >
                Video settings
              </button>
              and
              <button
                role="button"
                class="btn-text font-bold"
                @click="openDevicesModal('audio')"
              >
                Audio settings
              </button>
            </p> -->
      </div>

      <!-- Resize handle -->
      <div
        ref="resizeHandle"
        class="resize-handle"
        @mousedown.stop.prevent="startResize"
      >
        <font-awesome-icon
          icon="fa-regular fa-arrow-down-left-and-arrow-up-right-to-center"
          class="white"
        />
      </div>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCallingStore } from '@/stores/CallingStore'
import { useDraggable, useEventBus, useTimeoutFn } from '@vueuse/core'
import { watch, computed, ref, type Ref, reactive } from 'vue'
import { useCommonStore } from '@/stores/CommonStore'
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  connect,
  LocalVideoTrack,
  LocalAudioTrack,
  Room,
  RemoteParticipant,
  RemoteTrackPublication,
  type RemoteTrack,
  type ConnectOptions,
  type RemoteVideoTrack,
  Logger
} from 'twilio-video'
import constants from '@/exports/constants'
import { realtimeDatabaseService } from '@/services/realtimeDBService'
import type { IRealTimeNotification } from '@/models/interfaces'
import moment from 'moment'
import preCallChecks from './components/PreCallChecks.vue'
import { useModals } from '@/composables/useModal/useModal'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { vElementVisibility } from '@vueuse/components'
import videoSettings from './components/VideoSettings.vue'
import audioSettings from './components/AudioSettings.vue'
import { useDevicesList } from '@vueuse/core'
import helper from '@/exports/helper'
import Loading from '@/components/library/Loading.vue'
import Popper from 'vue3-popper'
import { useRoute } from 'vue-router'
import {
  Pipeline,
  GaussianBlurBackgroundProcessor
} from '@twilio/video-processors'
import NetworkQualityBar from './components/NetworkQualityBar.vue'

import { vOnClickOutside } from '@vueuse/components'
import type { OnClickOutsideOptions } from '@vueuse/core'

const accountSettingsStore = useAccountSettingsStore()
const { callingBalance, showAddMinutesSuccessToast } =
  storeToRefs(accountSettingsStore)
const { fetchCallingBalance, setShowAddMinutesSuccessToast } =
  accountSettingsStore
const commonStore = useCommonStore()
const { fullUserInfo, isMobileDevice, isMobileWidth } = storeToRefs(commonStore)
const { setToast } = commonStore
const callingStore = useCallingStore()
const {
  createRoom,
  joinRoom,
  completeRoom,
  updateCall,
  getActiveVideoCallByItemID,
  setShowVideoCall,
  updateVideoCallStatus,
  logAction,
  setActiveCall,
  setMediatId,
  setVideoCallThreadId,
  bothParentsPassPrecheck,
  fetchCallingItems
} = callingStore
const {
  canCallStatus,
  showVideoCall,
  roomResult,
  isActiveCallIncoming,
  activeCall,
  videoInputId,
  audioInputId,
  audioOutputId,
  videoCallThreadId
} = storeToRefs(callingStore)

const FACING_MODES = {
  user: 'user',
  environment: 'environment'
}
const { createSlot, closeModal, generateModal } = useModals()

const WAITING_ROOM_TIMEOUT = 30 * 1000 // in millisends
const logger = Logger.getLogger('twilio-video')
logger.setLevel('error')

const {
  videoInputs: cameras,
  audioInputs: microphones,
  audioOutputs: speakers,
  ensurePermissions
} = useDevicesList()

const blurBackground = new GaussianBlurBackgroundProcessor({
  assetsPath: '/twilio',
  pipeline: Pipeline.WebGL2,
  debounce: true
})

interface NetworkQualityStats {
  // Define the structure of the network quality stats if known, otherwise use any
  [key: string]: any
}

interface IVideoCallingState {
  canMakeVideoCall: boolean
  videoCallStatus: null | number
  audioLevel: number
  remoteAudioMuted: boolean
  remoteVideoOn: boolean
  facingMode: string
  isVoiceMail: boolean
  showVoiceMailPrompt: boolean
  localAudioMuted: boolean
  localVideoOn: boolean
  callTimeRemaining: number
  formattedTimeRemaining: string
  formattedTimeElapsed: string
  errorMsg: string
  errorType: 'video' | 'audio' | ''
  loading: boolean
  showMutePopper: boolean
  showVideoPopper: boolean
  blurProcessor: GaussianBlurBackgroundProcessor | null
  isBlurred: boolean
  localNetworkQuality: number | null
  remoteNetworkQuality: number | null
}

const initialState = (): IVideoCallingState => ({
  canMakeVideoCall: true,
  videoCallStatus: constants.VIDEO_CALL_STATUS_ENUM.PreCheck,
  audioLevel: 0,
  remoteAudioMuted: false,
  remoteVideoOn: false,
  facingMode: FACING_MODES.user,
  isVoiceMail: false,
  showVoiceMailPrompt: false,
  localAudioMuted: false,
  localVideoOn: true,
  callTimeRemaining: 0,
  formattedTimeRemaining: '',
  formattedTimeElapsed: '',
  errorMsg: '',
  errorType: '',
  loading: false,
  showMutePopper: false,
  showVideoPopper: false,
  blurProcessor: null,
  isBlurred: false,
  localNetworkQuality: null,
  remoteNetworkQuality: null
})

const state = reactive<IVideoCallingState>(initialState())

function resetState(): void {
  const newState = initialState()
  Object.keys(newState).forEach((key) => {
    // Use as keyof State to ensure type safety when accessing properties
    const k = key as keyof IVideoCallingState
    ;(state[k] as any) = newState[k]
  })
}

let localVideoTrack: null | void | LocalVideoTrack = null
let localAudioTrack: null | void | LocalAudioTrack = null
let room: null | void | Room = null
const videoCallPlayer = ref<HTMLElement | null>(null)
const localVideoDiv = ref() as Ref<HTMLElement>
const remoteVideoDiv = ref() as Ref<HTMLElement>
const resizeHandle = ref<HTMLElement | null>(null)
const dragHandle = ref<HTMLElement | null>(null)

let callTimerId: number | null = null
let waitingRoomTimerId: number | null = null

const route = useRoute()

watch(showVideoCall, (newValue, oldValue) => {
  if (newValue === true) {
    cameraControlsOpen.value = true
    init()
    callRequest.on((acceptedCall: boolean) => {
      if (acceptedCall) {
        joinVideoCall()
      }
    })
  } else {
    cleanUpAndClose()
  }
})

watch(
  showAddMinutesSuccessToast,
  async (newValue, oldValue) => {
    if (newValue === true && showVideoCall.value) {
      await fetchCallingBalance()
      getLocalMedia()
      setShowAddMinutesSuccessToast(false)
      useTimeoutFn(
        () =>
          setToast({
            showToast: true,
            text: 'Success! Calling minutes added.',
            type: 'success',
            showCloseButton: true
          }),
        1500
      )
    }
  },
  {
    immediate: true
  }
)

const callRequest = useEventBus<boolean>('callRequest')

// Computed
const showEndCall = computed(() => {
  return (
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.MidCall ||
    state.isVoiceMail
  )
})

const isMidCall = computed(() => {
  return state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.MidCall
})

const showPreCallCheck = computed(() => {
  return (
    !isActiveCallIncoming.value &&
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.PreCheck
  )
})

const showConnecting = computed(() => {
  return (
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom ||
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.CoparentAccepted
  )
})

const showSendCancel = computed(() => {
  return (
    !isActiveCallIncoming.value &&
    (state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom ||
      state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.PreCheck)
  )
})

const showRemainingTime = computed(
  () => state.formattedTimeRemaining.length > 0
)

const showElapsedTime = computed(() => state.formattedTimeElapsed.length > 0)

const showErrorMsg = computed(() => {
  return state.errorMsg.length > 0
})

function getUniqueDevices(devices: MediaDeviceInfo[]) {
  const unique = devices.filter(
    (obj, index) =>
      devices.findIndex((item) => item.deviceId === obj.deviceId) === index
  )
  return unique
}

// Timers
function startWaitingRoomTimer() {
  waitingRoomTimerId = setTimeout(() => {
    console.log('stopping the waiting room')
    state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomTimedOut

    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.WaitingRoomTimeout,
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomTimedOut,
      'WaitingRoomTimeout'
    )

    state.showVoiceMailPrompt = true
  }, WAITING_ROOM_TIMEOUT) as unknown as number
}

function startCallTimer() {
  state.callTimeRemaining = getCallMaxTimeInSeconds()
  const startTime = moment()
  const endTime = moment().add(state.callTimeRemaining, 'seconds')

  callTimerId = setInterval(() => {
    const now = moment()
    const remainingSeconds = endTime.diff(now) / 1000
    const elapsedSeconds = now.diff(startTime) / 1000 // Convert milliseconds to seconds

    state.formattedTimeElapsed = helper.secondsToTimestamp(elapsedSeconds, true)

    // Display the time once the timer gets to 5 minutes remaining
    if (remainingSeconds <= 5 * 60 && !isActiveCallIncoming.value) {
      state.formattedTimeRemaining = `You have ${Math.ceil(remainingSeconds / 60)} Available Minute${Math.ceil(remainingSeconds / 60) == 1 ? '' : 's'} remaining.`
    }

    if (remainingSeconds <= 0 && !isActiveCallIncoming.value) {
      endCall()
    }
  }, 1000) as unknown as number
}

// breaks when updating status to balance empty completed :')
async function endCallTimer() {
  try {
    // Call time is up, so update and close call
    state.videoCallStatus = constants.VIDEO_CALL_ACTION_ENUM.CompleteCall
    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.CompleteCall,
      constants.VIDEO_CALL_STATUS_ENUM.BalanceEmptyCompleted,
      'BalanceEmptyCompleted'
    )
    if (activeCall.value?.itemID) {
      completeRoom({
        type: constants.CALL_TYPE_ENUM.video,
        itemID: activeCall.value?.itemID
      })

      await fetchCallingBalance()
    }

    disconnectCall()
    cleanUpAndClose()
  } catch (error) {
    console.error('Error in cleanUpAndClose:', error)
  }
}

// Currently not using visibilty changes, but may need
function onElementVisibility(state: any) {
  if (state == 'true' || state == true) {
    console.log('state: ' + state)
  } else {
    console.log('else state: ' + state)
  }
}

async function init() {
  // check if call is incoming
  console.log('isActiveCallIncoming.value = ' + isActiveCallIncoming.value)
  if (isActiveCallIncoming.value == true) {
    console.log('init - joining call')
    joinVideoCall()
  } else {
    state.loading = true
    console.log('init - about to start call')
    await bothParentsPassPrecheck()
    state.canMakeVideoCall =
      canCallStatus.value.get(constants.CAN_CALL_STATUS.allowed) ?? false
    getLocalMedia()
  }
}

function cleanUpAndClose() {
  setMediatId('audioinput', '')
  setMediatId('audiooutput', '')
  setMediatId('videoinput', '')

  console.trace()
  removeLocalVideo()
  if (waitingRoomTimerId) {
    clearTimeout(waitingRoomTimerId)
  }
  if (callTimerId) {
    clearInterval(callTimerId)
  }
  if (activeCall.value && route.name == 'calling') {
    fetchCallingItems()
    fetchCallingBalance()
  }
  if (room) {
    if (room.state != 'disconnected') {
      room.disconnect()
    }
    room = null
  }
  resetState()
  setActiveCall(null)
  setShowVideoCall(false)
  setVideoCallThreadId(0)
  callRequest.reset() // stop listening when video call modal is closed
  document.onvisibilitychange = null
  console.log('All clean')
}

// Local media calls
async function getLocalMedia() {
  console.log('getLocalMedia - creating tracks')
  // get local video
  ensurePermissions()
  await getLocalVideo()
  await getLocalAudio()
  state.loading = false
}

async function getLocalVideo() {
  if (localVideoTrack) {
    stopDetachUnpublishTrack(localVideoTrack)
  }

  localVideoTrack = await createLocalVideoTrack({
    facingMode: 'user',
    deviceId: videoInputId.value.length > 0 ? videoInputId.value : undefined,
    name: videoInputId.value.length > 0 ? videoInputId.value : undefined
  }).catch(handleVideoError)

  if (localVideoTrack) {
    setMediatId(
      'videoinput',
      localVideoTrack.mediaStreamTrack.getSettings().deviceId ??
        localVideoTrack.name
    )
    attachVideoTrackToDOM()
  }
}

function checkWebGLErrors(gl: WebGL2RenderingContext) {
  const error = gl.getError()
  if (error !== gl.NO_ERROR) {
    console.error('WebGL error:', error)
  } else {
    console.log('No WebGL errors.')
  }
}

async function getLocalAudio() {
  // stopping, unpublishing, detaching any tracks currently attached
  if (localAudioTrack) {
    stopDetachUnpublishTrack(localAudioTrack)
  }

  localAudioTrack = await createLocalAudioTrack({
    noiseSuppression: false,
    echoCancellation: false,
    deviceId: audioInputId.value.length > 0 ? audioInputId.value : undefined,
    name: audioInputId.value.length > 0 ? audioInputId.value : undefined
  }).catch(handleAudioError)
  if (localAudioTrack) {
    console.log(
      'set localAudio from getLocalAudio: ' +
        localAudioTrack.mediaStreamTrack.getSettings().deviceId ??
        localAudioTrack.name
    )
    setMediatId(
      'audioinput',
      localAudioTrack.mediaStreamTrack.getSettings().deviceId ??
        localAudioTrack.name
    )
  }
}

function setAudioOutput() {
  // get twilio video elements to set the speaker device id
  // Safari does not support this functionality
  const audioElements = document.querySelectorAll('audio')
  console.log(`Audio elements found ${audioElements[0]}`)
  if (audioElements) {
    audioElements.forEach((audioElement) => {
      if (
        typeof audioElement.setSinkId === 'function' &&
        audioOutputId.value.length > 0
      ) {
        audioElement
          .setSinkId(audioOutputId.value)
          .then(() =>
            console.log(`Audio output device changed to ${audioOutputId.value}`)
          )
          .catch((error) =>
            console.error('Error changing audio output device:', error)
          )
      } else {
        console.error('Browser does not support setSinkId:')
      }
    })
  }
}

function stopDetachUnpublishTrack(
  localTrack: LocalVideoTrack | LocalAudioTrack
) {
  if (room) {
    room.localParticipant.unpublishTrack(localTrack)
  }
  localTrack?.stop()
  localTrack?.detach().forEach((element: HTMLMediaElement) => {
    element.srcObject = null
  })
}

function attachVideoTrackToDOM() {
  if (localVideoTrack) {
    const existingVideoElement = document.getElementById(
      'local-video'
    ) as HTMLVideoElement
    if (existingVideoElement) {
      // If a video element exists, we want to replace its source with the new track
      // First, detach any existing track from the existing video element
      existingVideoElement.srcObject = null
      // Reattach the track, which effectively replaces the previous one
      localVideoTrack.attach(existingVideoElement)
    } else {
      const trackElement = localVideoTrack.attach()
      trackElement.id = 'local-video' // Use track SID for unique identification
      trackElement.classList.add('local-video-video')
      localVideoDiv.value.appendChild(trackElement)
    }
  } else {
    console.log('Video Track Not Found: Unable to Attach.')
  }
}

function openDevicesModal(kind: string) {
  if (kind == 'video') {
    videoOptionsOpen.value = false
  } else {
    audioOptionsOpen.value = false
  }

  const el = generateModal({
    slot: {
      content: createSlot(
        'content',
        kind == 'video' ? videoSettings : audioSettings,
        {
          initialBlur: state.isBlurred,
          onUpdateTrack: (
            newTrack: LocalVideoTrack | LocalAudioTrack | null,
            isBlurred?: boolean
          ) => {
            if (kind == 'video') {
              if (newTrack) {
                // stop, detach, unpublish current
                if (localVideoTrack) {
                  stopDetachUnpublishTrack(localVideoTrack)
                }

                localVideoTrack = newTrack as LocalVideoTrack
                attachVideoTrackToDOM()
                publishLocalVideoTrack()
              }

              if (isBlurred) {
                blurVideo()
              } else if (!newTrack) {
                unBlurVideo()
              }
            } else {
              console.log('Updating Input')
              if (newTrack) {
                // stop, detach, unpublish current audio
                if (localAudioTrack) {
                  stopDetachUnpublishTrack(localAudioTrack)
                }

                localAudioTrack = newTrack as LocalAudioTrack
                publishLocalVideoTrack()
              }
            }
            closeModal(el)
          },
          onUpdateAudioOutput: () => {
            console.log('Updating SinkId..')
            if (
              state.videoCallStatus != constants.VIDEO_CALL_STATUS_ENUM.PreCheck
            ) {
              // setting sink id to blank means default
              setAudioOutput()
            }
          },
          onCancel: () => {
            console.log('onClose from selectMedia')
            closeModal(el)
          }
        }
      ).content
    },
    config: {
      showHeader: false,
      showFooter: false,
      showCloseButton: false,
      closeOnOutsideClick: false
    }
  })
}

// start of call / voicemail
async function startVoicemail() {
  state.showVoiceMailPrompt = false
  state.isVoiceMail = true

  if (callTimerId) {
    clearInterval(callTimerId)
  }
  startCallTimer()

  await createRoom({
    threadID: videoCallThreadId.value,
    type: constants.CALL_TYPE_ENUM.voicemail
  })
  if (roomResult.value?.itemID) {
    // connect to video call with media
    if (roomResult.value?.accessToken && localAudioTrack && localVideoTrack) {
      connectToRoom(roomResult.value?.accessToken, {
        name: roomResult.value?.roomName,
        tracks: [localAudioTrack, localVideoTrack]
      })
    }
    if (localVideoTrack) {
      localVideoDiv.value.remove()
      remoteVideoDiv.value.appendChild(localVideoTrack.attach())
    }
  }
}

async function joinVideoCall() {
  state.loading = true
  logActionLocal(
    constants.VIDEO_CALL_ACTION_ENUM.VideoCallAccepted,
    'VideoCallAccepted'
  )
  console.log('joinVideoCall - calling getLocalMedia')
  await getLocalMedia()
  // make sure we have an active call
  if (activeCall.value) {
    console.log('Active call: ' + activeCall.value)
    // get access token
    await joinRoom({
      joinVideoCallItemID: activeCall.value?.itemID
    })

    console.log('roomAccess: ' + roomResult.value?.accessToken)

    if (roomResult.value?.accessToken && localVideoTrack && localAudioTrack) {
      const videoOptions = isMobileDevice
        ? { height: 480, frameRate: 24, width: 640 }
        : { height: 720, frameRate: 24, width: 1280 }
      await connectToRoom(roomResult.value?.accessToken, {
        name: roomResult.value?.roomName,
        video: videoOptions,
        tracks: [localVideoTrack, localAudioTrack],
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        }
      })

      console.log('joinVideoCall - connected to rrom')
    }
    if (roomResult.value?.errorMessage) {
      state.errorMsg = roomResult.value?.errorMessage
      console.log('Error connecting call: ' + roomResult.value?.errorMessage)
    }
  }
}

async function startVideoCall() {
  if (state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom)
    return
  console.log('startVideoCall - starting video call')
  state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom
  // create the video call. Stores the roomResult when completed
  await createRoom({
    threadID: videoCallThreadId.value,
    type: constants.CALL_TYPE_ENUM.video
  })
  await notifiyCoParent()
  //get active call
  if (roomResult.value?.itemID) {
    getActiveVideoCallByItemID(roomResult.value?.itemID)
    // connect to video call without media
    if (roomResult.value?.accessToken) {
      const videoOptions = isMobileDevice
        ? { height: 480, frameRate: 24, width: 640 }
        : { height: 720, frameRate: 24, width: 1280 }
      connectToRoom(roomResult.value?.accessToken, {
        name: roomResult.value?.roomName,
        audio: true,
        maxAudioBitrate: 16000,
        video: videoOptions,
        tracks: [],
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        }
      })
    }
    // start the timeout for the waiting room
    startWaitingRoomTimer()
    console.log('startVideoCall - create room and conencted to it')
  }
}

async function notifiyCoParent() {
  // make sure the room has been created
  if (roomResult.value?.itemID && fullUserInfo.value.userId) {
    console.log('starting write to firebase')
    const videoCallNotification: IRealTimeNotification = {
      created: moment().toISOString(),
      expires: moment().add(30, 'seconds').toISOString(),
      roomId: roomResult.value?.itemID,
      senderUserId: fullUserInfo.value.userId,
      type: 'videoCall'
    }
    const path = '/notifications/' + fullUserInfo.value.caseId

    await realtimeDatabaseService
      .writeData(path, videoCallNotification)
      .then(() => console.log('Write operation successful'))
      .catch((error) => console.error('Write operation failed', error))
    console.log('Notification sent successfully.')
  }
}

async function connectToRoom(
  token: string,
  connectOptions: ConnectOptions | undefined
) {
  console.log('connecting to room')
  try {
    room = await connect(token, connectOptions)

    console.log('connected to room: ' + room)
  } catch (error) {
    // Handle media error here.
    console.log('error connecting to room: ' + error)
    handleError(error)
  }

  // if callee, caller should be in the room already
  room?.participants.forEach((participant) => {
    remoteCallerConnected(participant)
    console.log('participant in room: ' + participant)
  })

  // if caller, listen for callee to connect to room
  room?.on('participantConnected', (participant) => {
    console.log('on participant connected in room: ' + participant)
    remoteCallerConnected(participant)
  })

  // Handle a disconnected RemoteParticipant.
  room?.on('participantDisconnected', () => {
    console.log('on participant disconnected from room: ')

    disconnectRemoteCaller()
  })

  room?.on('disconnected', () => {
    console.log('participant disconnected ')
    // callee has been disconnected
    if (isActiveCallIncoming.value == true) {
      logActionLocal(
        constants.VIDEO_CALL_ACTION_ENUM.LeavingFromDisconnect,
        'LeavingFromDisconnect'
      )
    }
  })
  room?.on('reconnected', () => {
    console.log('participant reconnected ')
  })
  room?.on('reconnecting', () => {
    console.log('participant reconnecting ')
  })

  room?.localParticipant.setNetworkQualityConfiguration({
    local: 2,
    remote: 1
  })

  room?.localParticipant.on(
    'networkQualityLevelChanged',
    setLocalNetworkQualityStats
  )

  if (isMobileDevice.value) {
    console.log('isMobileDevice: ' + isMobileDevice)
    // TODO(mmalavalli): investigate why "pagehide" is not working in iOS Safari.
    // In iOS Safari, "beforeunload" is not fired, so use "pagehide" instead.
    window.onpagehide = () => {
      room?.disconnect()
    }

    // On mobile browsers, use "visibilitychange" event to determine when
    // the app is backgrounded or foregrounded.
    document.onvisibilitychange = async () => {
      if (document.visibilityState === 'hidden') {
        console.log('onvisibilitychange: hidden')
        // When the app is backgrounded, your app can no longer capture
        // video frames. So, stop and unpublish the LocalVideoTrack.
        localVideoTrack?.stop()
        if (localVideoTrack) {
          room?.localParticipant.unpublishTrack(localVideoTrack)
        }
      } else if (showVideoCall.value) {
        // When the app is foregrounded, your app can now continue to
        // capture video frames. So, publish a new LocalVideoTrack.

        // todo: need to test on mobile
        console.log('onvisibilitychange: shown')
        localVideoTrack = await createLocalVideoTrack({
          facingMode: 'user',
          deviceId:
            videoInputId.value.length > 0 ? videoInputId.value : undefined
        })
        await room?.localParticipant.publishTrack(localVideoTrack)
      }
    }
  }
}

function remoteCallerConnected(participant: RemoteParticipant) {
  if (waitingRoomTimerId) {
    clearTimeout(waitingRoomTimerId)
  }

  startCallTimer()

  // if caller, callee should already have tracks published
  participant.tracks.forEach((publication: RemoteTrackPublication) => {
    console.log('remote participant track ' + publication)
    if (publication.isSubscribed) {
      attachCoParentTrack(publication.track)
    }
  })

  // If callee, the caller's tracks have not been published yet so listen for them
  participant.on('trackSubscribed', (track) => {
    console.log('remote participant track is subscribed: ' + track)
    attachCoParentTrack(track)
    console.log('remote participant track is attached ')
  })

  participant.on('trackUnsubscribed', (track) => {
    console.log('track unsubscribed: ' + track)
  })

  participant.on('networkQualityLevelChanged', setRemoteNetworkQualityStats)

  // publish the local tracks once the co-parent has connected
  if (!isActiveCallIncoming.value) {
    publishLocalVideoTrack()
  }
  // both parties are connected, so change the status to MidCall and start timer
  state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.MidCall
  // if caller update the call status and start timer
  if (!isActiveCallIncoming.value == true && activeCall.value) {
    updateVideoCallStatus({
      videoCallId: activeCall.value.itemID,
      newStatus: constants.VIDEO_CALL_STATUS_ENUM.MidCall
    })
  }
}

async function publishLocalVideoTrack() {
  console.log('publishing local tracks')
  if (localAudioTrack && localVideoTrack) {
    // when updating audio source while muted, stay muted but don't log
    if (state.localAudioMuted) {
      localAudioTrack.disable()
    }

    // when updating video source while disabled, stay disabled but don't log
    if (!state.localVideoOn) {
      localVideoTrack.disable()
    }

    room?.localParticipant.publishTrack(localAudioTrack)
    room?.localParticipant.publishTrack(localVideoTrack)
    room?.localParticipant.setNetworkQualityConfiguration({
      local: 2,
      remote: 1
    })
    room?.localParticipant.on(
      'networkQualityLevelChanged',
      setLocalNetworkQualityStats
    )
  } else {
    //TODO: show user error with local tracks
    handleVideoError
  }
}

function attachCoParentTrack(track: RemoteTrack | null) {
  console.log('Attaching coparent track:', track)

  if (!track) {
    console.log('Track is null, skipping.')
    return
  }

  if (track.kind == 'video') {
    state.localVideoOn = true
    const existingVideoElement = document.getElementById(
      'remote-video'
    ) as HTMLVideoElement
    if (existingVideoElement) {
      // If a video element exists, we want to replace its source with the new track
      // First, detach any existing track from the existing video element
      existingVideoElement.srcObject = null
      // Reattach the track, which effectively replaces the previous one
      track.attach(existingVideoElement)
    } else {
      const trackElement = track.attach()
      trackElement.id = 'remote-video' // Use track SID for unique identification
      trackElement.classList.add('remote-video-video')
      remoteVideoDiv.value.appendChild(trackElement)
    }

    // fix the local video to the upper right-hand corner
    state.remoteVideoOn = track.isEnabled
    localVideoDiv.value.classList.add('local-fixed')
  }

  if (track.kind == 'audio') {
    remoteVideoDiv.value
      .appendChild(track.attach())
      .classList.add('remote-video-video')
    console.log('Attached track:', track.kind)
    // check if track is initially muted/unmuted
    state.remoteAudioMuted = !track.isEnabled
  }
  // set speaker device Id to video element that was just attached
  setAudioOutput()

  track.on('disabled', function () {
    if (track.kind === 'video') {
      turnRemoteVideoOff(track)
    } else {
      muteRemoteAudio()
    }
  })
  track.on('enabled', function () {
    if (track.kind === 'video') {
      turnRemoteVideoOn(track)
    } else {
      unMuteRemoteAudio()
    }
  })
}

function setLocalNetworkQualityStats(
  networkQualityLevel: number | null,
  networkQualityStats?: NetworkQualityStats | null
): void {
  // Print in console the networkQualityLevel using bars
  console.log(
    {
      1: '▃',
      2: '▃▄',
      3: '▃▄▅',
      4: '▃▄▅▆',
      5: '▃▄▅▆▇'
    }[networkQualityLevel || 1] || ''
  )

  state.localNetworkQuality = networkQualityLevel
  if (networkQualityStats) {
    // Print in console the networkQualityStats, which is non-null only if Network Quality
    // verbosity is 2 (moderate) or greater
    console.log('Network Quality statistics:', networkQualityStats)
  }
}

function setRemoteNetworkQualityStats(
  networkQualityLevel: number | null,
  networkQualityStats?: NetworkQualityStats | null
): void {
  // Print in console the networkQualityLevel using bars
  console.log(
    {
      1: '▃',
      2: '▃▄',
      3: '▃▄▅',
      4: '▃▄▅▆',
      5: '▃▄▅▆▇'
    }[networkQualityLevel || 1] || ''
  )
  state.remoteNetworkQuality = networkQualityLevel
  if (networkQualityStats) {
    // Print in console the networkQualityStats, which is non-null only if Network Quality
    // verbosity is 2 (moderate) or greater
    console.log('Network Quality statistics:', networkQualityStats)
  }
}

// User controls
const videoToggle = ref<HTMLElement>()
const onClickOutsideVideoToggle: [() => void, OnClickOutsideOptions] = [
  () => {
    state.showVideoPopper = state.showVideoPopper
      ? !state.showVideoPopper
      : state.showVideoPopper
    videoOptionsOpen.value = videoOptionsOpen.value
      ? !videoOptionsOpen.value
      : videoOptionsOpen.value
  },
  { ignore: [videoToggle] }
]

const audioToggle = ref<HTMLElement>()
const onClickOutsideAudioToggle: [() => void, OnClickOutsideOptions] = [
  () => {
    state.showMutePopper = state.showMutePopper
      ? !state.showMutePopper
      : state.showMutePopper
    audioOptionsOpen.value = audioOptionsOpen.value
      ? !audioOptionsOpen.value
      : audioOptionsOpen.value
  },
  { ignore: [audioToggle] }
]

function muteLocalAudio() {
  if (room) {
    room?.localParticipant.audioTracks.forEach((publication) => {
      publication.track.disable()
      state.localAudioMuted = true
    })
    logActionLocal(constants.VIDEO_CALL_ACTION_ENUM.AudioMuted, 'AudioMuted')
  } else {
    state.showMutePopper = !state.showMutePopper
  }
}

function unMuteLocalAudio() {
  room?.localParticipant.audioTracks.forEach((publication) => {
    publication.track.enable()
    state.localAudioMuted = false
  })
  logActionLocal(constants.VIDEO_CALL_ACTION_ENUM.AudioUnmuted, 'AudioUnmuted')
}

async function turnLocalVideoOn() {
  room?.localParticipant.videoTracks.forEach((publication) => {
    publication.track.enable()
    state.localVideoOn = true
  })
  logActionLocal(constants.VIDEO_CALL_ACTION_ENUM.VideoEnabled, 'VideoEnabled')
}

async function turnLocalVideoOff() {
  if (room) {
    room?.localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable()
      state.localVideoOn = false
    })
    logActionLocal(
      constants.VIDEO_CALL_ACTION_ENUM.VideoDisabled,
      'VideoDisabled'
    )
  } else {
    state.showVideoPopper = !state.showVideoPopper
  }
}

function turnRemoteVideoOn(track: RemoteVideoTrack) {
  console.log('turning remote video on:')
  const attachedElement = track.attach()
  if (attachedElement instanceof HTMLElement) {
    remoteVideoDiv.value
      .appendChild(attachedElement)
      .classList.add('remote-video-video')
  }
  state.remoteVideoOn = true
}

function turnRemoteVideoOff(track: RemoteVideoTrack) {
  console.log('turning remote video off:')
  track.detach().forEach((element: HTMLMediaElement) => {
    element.srcObject = null
    element.remove()
  })
  state.remoteVideoOn = false
}

function swapCameras() {
  if (localVideoTrack) {
    // Switch to the back facing camera or back to user camera.
    state.facingMode =
      state.facingMode == FACING_MODES.user
        ? FACING_MODES.environment
        : FACING_MODES.user
    localVideoTrack.restart({
      facingMode: state.facingMode
    })
    logActionLocal(
      state.facingMode == FACING_MODES.environment
        ? constants.VIDEO_CALL_ACTION_ENUM.FlipToBackCamera
        : constants.VIDEO_CALL_ACTION_ENUM.FlipToFrontCamera,
      state.facingMode == FACING_MODES.environment
        ? 'FlipToBackCamera'
        : 'FlipToFrontCamera'
    )
  }
}

function muteRemoteAudio() {
  // show muted icon
  console.log('remote user muted')
  state.remoteAudioMuted = true
}

function unMuteRemoteAudio() {
  // show unmuted icon
  console.log('remote user unmuted')
  state.remoteAudioMuted = false
}

function disconnectRemoteCaller() {
  // Remove the Participant's media container.
  remoteVideoDiv.value.remove()
  cleanUpAndClose()
}

function removeLocalVideo() {
  // Remove the local user's media container.
  localVideoTrack?.stop()
  localAudioTrack?.stop()
  localVideoTrack?.detach().forEach((element: HTMLMediaElement) => {
    element.srcObject = null
    element.remove()
  })
  localAudioTrack?.detach().forEach((element: HTMLMediaElement) => {
    element.srcObject = null
    element.remove()
  })
}

//End call
function disconnectCall() {
  if (room) {
    room.disconnect()
  }
}

async function endCall() {
  if (
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom ||
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.PreCheck ||
    state.errorMsg == 'Video Call already in progress.'
  ) {
    cancelCall()
    return
  }

  removeLocalVideo()
  disconnectCall()
  state.videoCallStatus = constants.VIDEO_CALL_ACTION_ENUM.CompleteCall
  if (activeCall.value?.itemID) {
    await completeRoom({
      type: constants.CALL_TYPE_ENUM.video,
      itemID: activeCall.value?.itemID
    })
    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.CompleteCall,
      constants.VIDEO_CALL_STATUS_ENUM.CreatorCompleted,
      'CompleteCall'
    )
  }

  disconnectCall()
  cleanUpAndClose()
}

async function endVoiceMail() {
  removeLocalVideo()
  disconnectCall()
  if (roomResult.value?.itemID) {
    state.videoCallStatus = constants.VIDEO_CALL_ACTION_ENUM.VoicemailCreated

    completeRoom({
      type: constants.CALL_TYPE_ENUM.voicemail,
      itemID: roomResult.value?.itemID
    })
  }

  logActionLocal(
    constants.VIDEO_CALL_ACTION_ENUM.VoicemailCreated,
    'VoicemailCreated'
  )
  cleanUpAndClose()
}

function cancelCall() {
  if (room && activeCall.value && activeCall.value?.itemID) {
    room.disconnect()
    state.videoCallStatus =
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomAbandoned
    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.VideoCallAbandoned,
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomAbandoned,
      'VideoCallAbandoned'
    )
    disconnectCall()
    cleanUpAndClose()
  } else {
    cleanUpAndClose()
  }
}

function cancelVideoCall() {
  if (room) {
    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.VideoCallAbandoned,
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomAbandoned,
      'VideoCallAbandoned'
    )
  }
  cleanUpAndClose()
}

// Error handling
function handleError(error: any) {
  // Handle connection error here.
  console.log('handleError: ' + error)
  // const errorMsg = callHelpers.getUserFriendlyError(error)
  //  console.error('Twilio error:', errorMsg)
  //TODO: Handle the different errors properly
}

function handleVideoError() {
  state.errorMsg = 'There is an issue with your camera.'
  state.errorType = 'video'
}

function handleAudioError(error: any) {
  console.log('Twilio audio error: ' + error)
  state.errorType = 'audio'
}

// helper functions
function updateCallLocal(
  action: number,
  newStatus: number,
  actionData: string
) {
  if (activeCall.value) {
    updateCall({
      itemID: activeCall.value.itemID,
      callType: constants.CALL_TYPE_ENUM.video,
      newStatus: newStatus,
      action: action,
      actionData: actionData
    })
  }
}

// function updateVideoCallStatusLocal(payload: IUpdateVideoCallStatusRequest) {
//   UpdateVideoCallStatus(payload)
// }

function logActionLocal(action: number, data: string) {
  if (room && activeCall.value) {
    logAction({
      itemID: activeCall.value?.itemID,
      callType: constants.CALL_TYPE_ENUM.video,
      action: action,
      data: data
    })
  }
}

function getCallMaxTimeInSeconds(): number {
  const twoHoursInSeconds = 120 * 60
  const callingBalanceInSeconds: number =
    callingBalance.value == null ? 0 : callingBalance.value * 60
  return callingBalanceInSeconds < twoHoursInSeconds
    ? callingBalanceInSeconds
    : twoHoursInSeconds
}

async function selectVideoSource(input: string) {
  if (input == videoInputId.value) return

  setMediatId('videoinput', input)
  await getLocalVideo()
  publishLocalVideoTrack()
  state.isBlurred = false
}

async function selectAudioSource(input: string) {
  if (input == audioInputId.value) return

  setMediatId('audioinput', input)
  await getLocalAudio()
  publishLocalVideoTrack()
}

async function selectAudioOutput(input: string) {
  if (input == audioOutputId.value) return
  console.log('output')
  setMediatId('audiooutput', input)
  // const audioElement = new Audio()
  // await audioElement.setSinkId(input)
  setAudioOutput()
}

function isWebGL2Supported() {
  try {
    const canvas = document.createElement('canvas')
    const gl =
      canvas.getContext('webgl2') || canvas.getContext('experimental-webgl2')
    if (!gl) {
      console.error('WebGL2 context not created.')
    } else {
      console.log('WebGL2 context created successfully.')
    }
    return !!gl
  } catch (e) {
    console.error('Error checking WebGL2 support:', e)
    return false
  }
}

async function toggleBlur() {
  if (localVideoTrack) {
    if (state.isBlurred) {
      unBlurVideo()
    } else {
      blurVideo()
    }
  }
}

async function blurVideo() {
  if (isWebGL2Supported()) {
    // Create a WebGL2 context explicitly
    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('webgl2')
    if (!gl) {
      console.error('Failed to create WebGL2 context.')
      return
    }
    console.log('WebGL2 context created:', gl)

    checkWebGLErrors(gl)

    await blurBackground.loadModel().then(() => {
      localVideoTrack?.addProcessor(blurBackground, {
        inputFrameBufferType: 'video',
        outputFrameBufferContextType: 'webgl2'
      })
      state.isBlurred = true
    })
    checkWebGLErrors(gl)
  }
}

function unBlurVideo() {
  if (localVideoTrack) {
    localVideoTrack.removeProcessor(blurBackground)
    state.isBlurred = false
  }
}

// Drag and Drop and resize
const playerWidth = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue('--player-width')
)
const playerHeight = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue('--player-height')
)

const leftOffset = window.innerWidth * 0.5 - playerHeight / 2
const topOffset = window.innerHeight * 0.5 - playerWidth / 2

const { style } = useDraggable(videoCallPlayer, {
  initialValue: { x: leftOffset, y: topOffset },
  preventDefault: true,
  handle: dragHandle
})

const isResizing = ref(false)
let startX = 0
let startY = 0
let startWidth = 0
let startHeight = 0

const startResize = (event: MouseEvent) => {
  event.stopPropagation()
  event.preventDefault()
  isResizing.value = true
  startWidth = parseInt(
    document.defaultView?.getComputedStyle(videoCallPlayer.value!).width ?? '0',
    10
  )
  startHeight = parseInt(
    document.defaultView?.getComputedStyle(videoCallPlayer.value!).height ??
      '0',
    10
  )
  startX = event.clientX
  startY = event.clientY
  document.addEventListener('mousemove', doResize)
  document.addEventListener('mouseup', stopResize)
}

const doResize = (event: MouseEvent) => {
  if (isResizing.value && videoCallPlayer.value) {
    videoCallPlayer.value.style.width = `${
      startWidth + event.clientX - startX
    }px`
    videoCallPlayer.value.style.height = `${
      startHeight + event.clientY - startY
    }px`
  }
}

const stopResize = () => {
  isResizing.value = false
  document.removeEventListener('mousemove', doResize)
  document.removeEventListener('mouseup', stopResize)
}

const cameraControlsOpen = ref(true)
const toggleCameraControls = () => {
  cameraControlsOpen.value = !cameraControlsOpen.value
}

const videoOptionsOpen = ref(false)
const toggleVideoOptions = () => {
  videoOptionsOpen.value = !videoOptionsOpen.value
  audioOptionsOpen.value = false
  state.showVideoPopper = false
}

const audioOptionsOpen = ref(false)
const toggleAudioOptions = () => {
  audioOptionsOpen.value = !audioOptionsOpen.value
  videoOptionsOpen.value = false
  state.showMutePopper = false
}
</script>
<style scoped lang="scss">
.info {
  > :deep(.popper) {
    width: 132px;
    text-wrap: pretty;
  }
}

.controls-relative {
  position: static;

  @media (width >= 48em) {
    position: relative;
  }
}

.btn.send,
.btn.cancel {
  aspect-ratio: 1;
  min-width: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  color: white;
}

.btn.start {
  background-color: var(--medium-green);
}

.intro-text {
  max-width: 45ch;
  text-align: center;
  line-height: 1.2;
  order: 2;
}
.check-settings {
  width: fit-content;
  margin: 0.5rem auto 0;
  align-items: center;
  padding: 0.75rem;
  border-radius: 3rem;
  font-size: var(--font-size-1);
  max-width: 45ch;
  text-align: center;
}
.check-settings button {
  white-space: none;
  width: fit-content;
  background-color: transparent;
  border-radius: 2.625rem;
  padding: 0;
  color: var(--text-1);
  text-decoration: none;
  color: var(--purple);
  box-shadow: none !important;
  padding: 0;
  border: none;
}

.video-call-player {
  --player-width: 60%;
  --player-height: 720px;

  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;
  z-index: 2000;
  user-select: none;
  background-color: var(--surface-1);

  box-shadow: var(--shadow-3);

  @media (width >= 48em) {
    min-width: 21.375rem;
    min-height: 21.375rem;
    width: var(--player-width);
    height: var(--player-height);
    left: calc(50vw - var(--player-width) / 2); // left: 0;
    top: calc(50vh - var(--player-height) / 2); // top: 0;
    right: unset;
    bottom: unset;
    border-radius: 0.5rem;
  }
}
.remote-video {
  width: 100%;
  height: 100%;
  z-index: 2001;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.remote-video :deep(video) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
:deep(.remote-video-video) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
  transform: translate(-50%, -50%); /* Center it */
}
.local-video {
  z-index: 2000;
  display: grid;
  gap: 1.5rem;
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: black;

  @media (width >= 48em) {
    width: 100%;
    height: 100%;
  }
}
.local-video.local-fixed {
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  width: 8em;
  height: 10em;
  border-radius: 0.75rem;
  overflow: clip;
}

.remote-audio {
  position: absolute;
  bottom: 0;
  left: 0.5rem;
  z-index: 100;
  // background-color: var(--surface-2);
  width: 2.1875rem;
  height: 1.5rem;
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, -0.5rem, 0);
  transition: transform 0.2s ease-in-out;
}
@media (width <= 48em) {
  .remote-audio {
    transform: translate3d(0, -7rem, 0);
  }
  .is-minimized .remote-audio {
    transform: translate3d(0, -3rem, 0);
  }
}

:where(svg.muted, svg.unmuted) {
  display: none;
}
.remote-audio.unmuted svg.unmuted {
  display: inline-block;
}
.remote-audio.muted svg.muted {
  display: inline-block;
  color: #f24e4e;
}

:deep(.local-video-video) {
  width: 100%;
  height: 100%;
  border-radius: 0;
  object-fit: cover;
  background-color: var(--surface-2);
  margin-left: auto;
  margin-right: auto;
  // transform: scaleX(-1);

  @media (width >= 48em) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.resize-handle {
  display: none;

  @media (width >= 48em) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    background-color: #13a19c;
    cursor: se-resize;
    z-index: 2011;
    border-radius: 0.5em 0 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.drag-handle {
  display: none;

  @media (width >= 48em) {
    width: 100%;
    height: 2.125rem;
    background-color: var(--surface-2);
    cursor: grab;
    z-index: 2001;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.drag-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8625rem;
  height: 1.8625rem;
  border-radius: 50%;
  padding: 0rem;
}

.drag-icon-wrap svg {
  width: 24px;
  height: 24px;
  color: var(--brand);
}

:is(button.send, button.cancel) {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  border: none;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:is(button.send, button.cancel) .label {
  position: absolute;
  bottom: -1.75rem;
}

:is(button.send, button.cancel) svg {
  width: 25px;
  height: 25px;
}

button.send {
  background-color: #13a19c;
}
button.cancel {
  background-color: #f24e4e;
  color: white;
}

.camera-control-toggle {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 0.5rem;
}

.camera-controls.is-minimized {
  transform: translate3d(0, 4.5rem, 0);
}
.camera-controls.is-minimized .camera-control-toggle svg {
  rotate: 180deg;
}

.video-control {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #747776;
  color: white;
}

.send-cancel {
  display: flex;
  width: fit-content;
  gap: 2rem;
  z-index: 2005;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  transform: translate3d(0, -8rem, 0);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-inline: auto;
  inset-inline: 0;
  transition: transform 0.2s ease-in-out;
}

.is-minimized .send-cancel {
  transform: translate3d(0, -4rem, 0);
}

// .is-minimized :deep(.calling-modal-meta) { transform: translate3d(0,-9rem,0);  }

.camera-controls {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: transform 0.125s ease-in-out;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0.25rem 0.5rem 1rem;
  z-index: 5000;
  border-radius: 1.5rem 1.5rem 0 0;

  align-items: center;
  background-color: rgba(32, 30, 30, 0.9);

  @media (width >= 48em) {
    padding: 0.25rem 2.5rem 1rem;
    width: fit-content;
  }
}

.controls-div {
  display: flex;
  gap: 1.5rem;

  @media (width >= 48em) {
    gap: 2rem;
  }
}

.dropdown-control-options {
  position: absolute;
  bottom: calc(100% + 1rem);
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: var(--surface-1);
  padding: 1rem 0;
  border-radius: 0.75rem;
  box-shadow: var(--shadow-2);
  max-width: 90%;
  width: 100%;

  @media (width >= 48em) {
    max-width: 20rem;
    left: Calc(100% - 3rem);
    right: unset;
    width: unset;
  }

  li {
    position: relative;
  }

  .is-selected button {
    background-color: var(--surface-2);
  }

  button {
    background-color: var(--surface-1);
    border: none;
    padding: 0.25rem 2rem;
    white-space: nowrap;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: var(--surface-2);
    }
  }

  svg {
    position: absolute;
    left: 0.7rem;
    top: 0.45rem;
  }
}

.dropdown-control-label {
  padding: 0.125rem 2rem;
  white-space: nowrap;
}

// .dropdown-control-divider {
//   margin-inline: 1rem;
//   border: 1px solid var(--surface-3);
// }

li.dropdown-control-divider:not(:first-child) {
  margin-top: 0.75rem;
}

.voicemail-prompt {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: unset;
  flex-wrap: wrap;
  text-align: center;
  margin-inline: auto;
  z-index: 25000;
  position: absolute;
  transform: translate3d(0, -18rem, 0);
  background-color: var(--surface-1);
  padding: 1.5rem;
  border-radius: 0.75rem;
  left: 0;
  right: 0;
  width: fit-content;
  transition: all 0.2s var(--ease-1);
}
.is-minimized .voicemail-prompt {
  transform: translate3d(0, -13rem, 0);
}

.in-call-alerts-div {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--text-1);
  transform: translate3d(0px, -7rem, 0);
  transition: transform 0.2s ease-in-out;
}
.in-call-alerts-div.is-minimized {
  transform: translate3d(0, -2.5rem, 0);
}
.call-alert {
  display: inline-flex;
  align-items: center;
  background-color: var(--gray-cool-5);
  border-radius: 3rem;
  padding: 0 1em;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  align-self: flex-start;
  margin: auto auto;
  width: fit-content;
}
.call-alert span {
  flex-shrink: 0;
}
.draggable {
  justify-content: center;
  align-items: center;
  cursor: move; /* Gives a visual cue that the element is movable */
}
.connecting-text {
  display: flex;
  justify-content: center;
  padding: var(--space-3xs) var(--space-md) var(--space-3xs) var(--space-xs);
  margin-bottom: 0.5rem;
  background: var(--brand-2);
  color: var(--brand-9);
  z-index: 2500;
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  border-radius: 3rem;
}
.error-text {
  color: red;
  width: fit-content;
  margin-inline: auto;
  position: absolute;
  z-index: 2500;
  inset-inline: 0;
  transform: translate3d(0, 50%, 0);
}

.video-section-main {
  height: calc(100%);
  // overflow: hidden;
  // display: grid;

  @media (width >= 48em) {
    height: calc(100% - 2.125rem);
  }
}

.place-center {
  display: grid;
  place-content: center;
  margin: auto;
}
.margin-auto {
  margin: auto;
  padding: 1rem 0;
}

.pill {
  padding: var(--space-3xs) var(--space-xs);
  margin-bottom: 0.5rem;
  background: var(--brand-2);
  color: var(--brand-9);
  position: absolute;
  left: 0;
  right: 0;
  margin-block-start: 1rem;
  margin-inline: auto;
  z-index: 2500;
}
</style>
