<template>
  <div ref="addContainer" v-observe-visibility="viewHandler">
    <ins
      :class="props.insclass"
      :style="props.insstyle"
      :data-ad-client="state.clientCode"
      :data-ad-slot="props.slotcode"
      :data-ad-format="props.format"
      data-full-width-responsive="false"
      :data-adtest="props.adtest"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { ref, reactive, computed, watch, onBeforeUnmount } from 'vue'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import constants from '@/exports/constants'

const accountSettingsStore = useAccountSettingsStore()
const { subscriptionT0 } = storeToRefs(accountSettingsStore)
const addContainer = ref<HTMLInputElement | null>(null)

const props = defineProps({
  insclass: {
    type: String,
    default: 'adsbygoogle'
  },
  insstyle: {
    type: String,
    default: 'display:block'
  },
  slotcode: {
    type: String,
    required: true
  },
  format: {
    type: String,
    default: constants.adFormat.auto
  },
  adtest: {
    type: String,
    default: constants.adTest.off
  }
})

const state = reactive({
  scriptLoaded: false,
  clientCode: import.meta.env.VITE_APP_ADSENSE_CLIENT_CODE,
  googleAdSenseUrl: import.meta.env.VITE_APP_GOOGLE_ADSEMSE_URL,
  adSenseDivIsVisible: false,
  adsAreLoaded: false,
  observer: null as any
})

const canPushAds = computed(() => {
  return (
    subscriptionT0.value &&
    state.adSenseDivIsVisible &&
    state.scriptLoaded &&
    addContainer.value &&
    addContainer.value?.clientWidth > 0 &&
    !state.adsAreLoaded
  )
})

watch(
  () => state.adSenseDivIsVisible,
  async () => {
    let scriptFound = false
    const scripts = document.getElementsByTagName('script')
    for (let i = scripts.length; i--; ) {
      if (scripts[i].src === state.googleAdSenseUrl) scriptFound = true
    }
    if (!scriptFound) {
      const head = document.head
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = state.googleAdSenseUrl
      s.setAttribute('data-ad-client', state.clientCode)
      s.onload = () => {
        scriptFound = true
        state.scriptLoaded = true
        pushGoogleAds()
      }
      head.appendChild(s)
    } else {
      state.scriptLoaded = true
      if (state.adSenseDivIsVisible) {
        pushGoogleAds()
      }
    }
  }
)

function pushGoogleAds() {
  let adsbygoogle: any

  if (canPushAds.value) {
    // eslint-disable-next-line no-undef
    if (
      typeof (window as any).adsbygoogle !== 'undefined'
    ) {
      // eslint-disable-next-line no-undef
      (adsbygoogle = (window as any).adsbygoogle || []).push({})
      state.adsAreLoaded = true
    }
  }
}

function viewHandler(isVisible: boolean) {
  if (isVisible) {
    state.adSenseDivIsVisible =
      (addContainer.value && addContainer.value?.clientWidth > 0) ?? false
  }
}

onBeforeUnmount(async () => {
  if (state.observer) state.observer.disconnect()
})
</script>
