import emptyModal from './emptyModalComponent.vue'
import { h, render, warn, getCurrentInstance } from 'vue'
import type { Component, ComponentInternalInstance, VNode, Ref, } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

// Styled modal
// will get overwritten if slot is used
export interface IDefaultSlotInputs {
  headerText?: string // header slot
  contentText?: string // content slot
  footerButtonLabel?: string // footer slot
}

export type SlotNames = 'modal-replacement' | 'header' | 'content' | 'footer';
export type SlotInput = Partial<Record<SlotNames, ISlotComponent>>;

export interface ISlotComponent {
  component: Component
  props?: any
  innerSlots?: any
}

export interface IComponentEventCallback {
  confirmFn?(): void,
  cancelFn?(): void,
  closeFn?(): void,
  secondaryFn?(): void,
  tertiaryFn?(): void
}

export interface IModalOptions {
  showHeader?: boolean
  showBody?: boolean
  showFooter?: boolean
  showCloseButton?: boolean
  blurBackground?: boolean
  closeOnOutsideClick?: boolean
  closeOnConfirm?: boolean
  closeOnLoadEnd?: boolean
  showBorderHeader?: boolean
  addContentPadding?: boolean
  showSecondaryCTA?: boolean
  showTertiaryCTA?: boolean
  footerButtonLoading?: any
  secondaryButtonLoading?: any
  tertiaryButtonLoading?: any
  footerStyle?: string
  secondaryCTALabel?: string
  tertiaryCTALabel?: string
}

export interface IModalNode {
  vNode: VNode,
  el: HTMLElement
}

export interface IUniversalModal {
  modalName?: string,
  default?: IDefaultSlotInputs,
  slot?: SlotInput,
  config?: IModalOptions,
  callback?: IComponentEventCallback
}

export interface IModal{
  showReplacementModal(replacement: ISlotComponent, onDestroy?: () => void): void,
  createModal(modalName: string | null, defaultInputs?: IDefaultSlotInputs | null, slots?: SlotInput | null, config?: IModalOptions | null, componentCallback?: IComponentEventCallback): void,
  generateModal(modal: IUniversalModal): HTMLElement,
  showSelectedModal(modalName: string): void,
  createSlot(slotName: SlotNames, slotComponent: Component, slotProps?: any, innerSlots?: any): Partial<Record<SlotNames, ISlotComponent>>,
  HTMLtoComponent(HTMLString: string): Component,
  closeModal(el: HTMLElement): void,
  closeActiveModal(): void
}

// useModal composable
export function useModals(): IModal {
  // to use for each vNode
  const app = getCurrentInstance()

  return _useModals(app as unknown as ComponentInternalInstance);
}

function _useModals(appInstance?: ComponentInternalInstance): IModal {
  // active modal
  let activeModal:IModalNode = null as unknown as IModalNode

  // map of modals
  let modalList = new Map<string, IModalNode>()

  // onBeforeRouteLeave(() => {
  //   if (activeModal) render(null, activeModal.el)
  //   activeModal = null as unknown as IModalNode
  // })
  
  // create slot
  function createSlot(slotName: SlotNames, slotComponent: Component, slotProps?: any, innerSlots?: any): Partial<Record<SlotNames, ISlotComponent>> {
    const slot: Partial<Record<SlotNames, ISlotComponent>> = {
      [slotName]: {
        component: slotComponent,
        props: {
          ...slotProps,
        },
        innerSlots
      }
    }
    return slot
  }

  // create modal
  function createModal(modalName?: string | null, defaultInputs?: IDefaultSlotInputs | null, slots?: SlotInput | null, config?: IModalOptions | null, componentCallback?: IComponentEventCallback) {
    let destroyVNode = { 'onClose': () => { destroy() } }

    let el = document.createElement('div')

    const destroy = () => {
      if (componentCallback?.closeFn) componentCallback.closeFn()
      render(null, el)
    }
    let vNode = h(emptyModal, {
      ...config,
      ...defaultInputs,
      ...destroyVNode,
      ...{ 'onConfirm': () => {  if (componentCallback?.confirmFn) componentCallback.confirmFn() } },
      ...{ 'onSecondary': () => {  if (componentCallback?.secondaryFn) componentCallback.secondaryFn() } },
      ...{ 'onTertiary': () => {  if (componentCallback?.tertiaryFn) componentCallback.tertiaryFn() } },
      ...{ 'onCancel': () => {  if(componentCallback?.cancelFn) componentCallback.cancelFn() } }
    }, {
      'modal-replacement': () => {
        if (slots?.['modal-replacement']?.component) {
          return h(slots?.['modal-replacement'].component,
            {
              ...slots?.['modal-replacement']?.props,
              ...destroyVNode,
              ...{ ref: 'modalRef' }
            },
            slots['modal-replacement'].innerSlots
            )
        }
      },
      'header': () => {
        if (slots?.['header']?.component) {
          return h(slots?.['header'].component,
            {
              ...slots?.header?.props,
              ...destroyVNode
            },
            slots.header.innerSlots
            )
        }
      },
      'content': () => {
        if (slots?.['content']?.component) {
          return h(slots?.['content'].component,
            {
              ...slots?.content?.props,
              ...destroyVNode
            },
            slots.content.innerSlots
            )
        }
      },
      'footer': () => {
        if (slots?.['footer']?.component) {
          return h(slots?.['footer'].component,
            {
              ...slots?.footer?.props,
              ...destroyVNode
            },
            slots.footer.innerSlots
            )
        }
      }
    })

    if (appInstance) {
      vNode.appContext = appInstance.appContext
    }

    if (modalName) {
      modalList.set(modalName, { vNode, el } )
    } else {
      activeModal = { vNode, el }
      render(vNode, el)
    }
  }

  // create modal object
  function generateModal(modal: IUniversalModal) {
    let destroyVNode = { 'onClose': () => { destroy() } }

    let el = document.createElement('div')

    const destroy = () => {
      // run 
      if (modal.callback?.closeFn) modal.callback.closeFn()
      render(null, el)
    }

    let vNode = h(emptyModal, {
      ...modal.config,
      ...modal.default,
      ...destroyVNode,
      ...{ 'onConfirm': () => {  if (modal.callback?.confirmFn) modal.callback.confirmFn() } },
      ...{ 'onSecondary': () => {  if (modal.callback?.secondaryFn) modal.callback.secondaryFn() } },
      ...{ 'onTertiary': () => {  if (modal.callback?.tertiaryFn) modal.callback.tertiaryFn() } },
      ...{ 'onCancel': () => {  if(modal.callback?.cancelFn) modal.callback.cancelFn() } }
    }, {
      'modal-replacement': () => {
        if (modal.slot?.['modal-replacement']?.component) {
          return h(modal.slot?.['modal-replacement'].component,
            {
              ...modal.slot?.['modal-replacement']?.props,
              ...destroyVNode,
              ...{ ref: 'modalRef' }
            },
            modal.slot['modal-replacement'].innerSlots
            )
        }
      },
      'header': () => {
        if (modal.slot?.['header']?.component) {
          return h(modal.slot?.['header'].component,
            {
              ...modal.slot?.header?.props,
              ...destroyVNode
            },
            modal.slot.header.innerSlots
            )
        }
      },
      'content': () => {
        if (modal.slot?.['content']?.component) {
          return h(modal.slot?.['content'].component,
            {
              ...modal.slot?.content?.props,
              ...destroyVNode
            },
            modal.slot.content.innerSlots
            )
        }
      },
      'footer': () => {
        if (modal.slot?.['footer']?.component) {
          return h(modal.slot?.['footer'].component,
            {
              ...modal.slot?.footer?.props,
              ...destroyVNode
            },
            modal.slot.footer.innerSlots
            )
        }
      }
    })

    if (appInstance) {
      vNode.appContext = appInstance.appContext
    }

    if (modal.modalName) {
      modalList.set(modal.modalName, { vNode, el } )
    } else {
      activeModal = { vNode, el }
      render(vNode, el)
    }

    return el
  }
  // todo: create delete
  function showSelectedModal(modalName: string) {
    if (modalList.has(modalName)) {
      const modalVNode = modalList.get(modalName)?.vNode
      const htmlEL = modalList.get(modalName)?.el

      if (modalVNode) {
        activeModal = { vNode: modalVNode, el: htmlEL ?? document.createElement('div')}
      }

      render(modalVNode ?? null, htmlEL ?? document.createElement('div'))
    } else {
      warn(
        `Could not find the specified modal named '${modalName}'.`,
      );
    }
  }
  // todo: not finished
  function showReplacementModal(replacement: ISlotComponent, onDestroy?: () => void) {
    let destroyVNode = { 'onClose': () => { destroy() } }
  
    if (onDestroy) {
      destroyVNode = { 'onClose': () => { onDestroy() } }
    }

    let vNode = h(emptyModal, {
      ...destroyVNode
    }, {
      'modal-replacement': () => {
          return h(replacement.component, { ref: 'modalRef' })
      },
    })
  
    let el: HTMLElement | null
    
    render(vNode, el = document.createElement('div'))
  
    const destroy = () => {
      if (el) render(null, el)
    }
  }

  function HTMLtoComponent(HTMLString: string) {
    return h('div', { innerHTML: HTMLString })
  }

  function closeModal(el: HTMLElement) {
    render(null,el)
  }

  function closeActiveModal() {
    render(null, activeModal.el)
  }

  return {
    createSlot,
    createModal,
    generateModal,
    showSelectedModal,
    showReplacementModal,
    HTMLtoComponent,
    closeModal,
    closeActiveModal
  }
}
