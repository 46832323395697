﻿<template>
  <div
    class="wistia_responsive_padding ap-video"
    :class="{ placeholder: state.fetchingVideo }"
    style="position: relative"
    v-skeleton="{
      loading: state.fetchingVideo,
      minWidth: 38,
      maxWidth: 38
    }
      "
  >
    <div
      class="wistia_responsive_wrapper"
      style="height: auto; left: 0; position: relative; top: 0; width: 100%"
    >
      <iframe
        :src="props.videoUrl"
        :title="props.videoTitle"
        allow="autoplay; fullscreen"
        allowtransparency="true"
        frameborder="0"
        scrolling="no"
        class="wistia_embed"
        name="wistia_embed"
        allowfullscreen
        msallowfullscreen
        width="100%"
        height="100%"
        :onload="loaded()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive } from 'vue'
const props = defineProps<{
  videoUrl: string
  videoTitle: string
}>()

onMounted(() => {
  state.fetchingVideo = true
  addScript('https://fast.wistia.net/embed/iframe/jprltdenbz?videoFoam=true')
  addScript('https://fast.wistia.net/assets/external/E-v1.js')
})

const state = reactive({
  fetchingVideo: true
})

function loaded() {
  setTimeout(() => {
    state.fetchingVideo = false
  }, 1000)
}

function addScript(url: string) {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)

  let scriptFound = false
  const scripts = document.getElementsByTagName('script')
  for (let i = scripts.length; i--; ) {
    if (scripts[i].src === url) scriptFound = true
  }
  if (!scriptFound) {
    const head = document.head
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = url
    s.async = true
    s.onload = () => {
      scriptFound = true
    }
    head.appendChild(s)
  } else {
    scriptFound = true
  }
}
</script>
<style lang="scss" scoped>
.placeholder {
  width: auto;
  background-color: var(--gray-2);
  aspect-ratio: 16 / 9;
  overflow: hidden;
  // @media (min-width: 48em) {
  //   aspect-ratio: 16 / 9;
  //   background-color: var(--gray-2);
  // }
}
</style>
