<template>
  <div class="main-wrapper">
    <div class="content-wrapper">
      <div class="title-wrapper">
        <h2
          v-html="item.conversation?.title"
          :class="[
            { conversation: item.type == GlobalSearchResultType.Conversation },
            { 'is-new': item.conversation?.isNew }
          ]"
          v-skeleton="item.skeletonLoading ?? loading"
        />

        <time
          v-if="item.conversation?.lastMessageDate"
          v-skeleton="item.skeletonLoading ?? loading"
        >
          {{
            isToday(item.conversation.lastMessageDate)
              ? formatFromUtcTime(item.conversation.lastMessageDate)
              : formatDate(item.conversation.lastMessageDate, false)
          }}
        </time>
      </div>

      <div
        class="message-wrapper"
        :class="[
          { bubble: item.type == GlobalSearchResultType.Message },
          bubbleClass
        ]"
        v-if="showMessageContent"
      >
        <h3
          v-html="messageContent"
          v-skeleton="item.skeletonLoading ?? loading"
        />
      </div>

      <div v-else class="attachment-wrapper" @click.stop="emit('download')">
        <Loading :loading="downloading" />

        <div class="filename-wrapper">
          <FontAwesomeIcon
            icon="fa-light fa-download"
            class="icon-size-2"
            v-skeleton="item.skeletonLoading ?? loading"
          />

          <h3
            v-html="item.attachment?.fileName"
            v-skeleton="item.skeletonLoading ?? loading"
          />
        </div>

        <time v-skeleton="item.skeletonLoading ?? loading">
          {{ formatTimeStamp(moment(item.attachment?.uploadDateTimeUtc)) }}
        </time>
      </div>
    </div>

    <FontAwesomeIcon
      icon="fa-light fa-chevron-right"
      class="chevron"
      v-skeleton="item.skeletonLoading ?? loading"
    />
  </div>
</template>
<script setup lang="ts">
import type { IGlobalSearchResult } from '@/models/interfaces.ts'
import { GlobalSearchResultType } from '@/models/enums.ts'
import { computed, toRef } from 'vue'
import { useCommonStore } from '@/stores/CommonStore.ts'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import Loading from '@/components/library/Loading.vue'
import { useI18n } from 'vue-i18n'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const { t } = useI18n({ useScope: 'global' })
//notes
//if return of type 0 (conversation), item's message prop is the most recent sent message
//if return of type 2 (attachment), message does not exist, just conversation

const commonStore = useCommonStore()
const { fullUserInfo } = storeToRefs(commonStore)
const { formatTimeStamp, isToday, formatFromUtcTime, formatDate } = commonStore

const props = withDefaults(
  defineProps<{
    item: IGlobalSearchResult
    loading: boolean
    downloading: boolean
  }>(),
  {
    loading: false,
    downloading: false
  }
)
const item = toRef(props, 'item')
const loading = toRef(props, 'loading')

const emit = defineEmits<{
  (e: 'download'): void
}>()

const showMessageContent = computed(
  () =>
    item.value.type == GlobalSearchResultType.Conversation ||
    item.value.type == GlobalSearchResultType.Message
)

const messageContent = computed(() =>
  item.value.type == GlobalSearchResultType.Conversation &&
  item.value.conversation?.isNew
    ? t('messages.isNewSubtitle', {
        name:
          item.value.message?.creatorID == fullUserInfo.value.userId
            ? fullUserInfo.value.firstName
            : fullUserInfo.value.coparentFirstName
      })
    : item.value.type == GlobalSearchResultType.Conversation
      ? t('messages.conversationSubtitle', {
          name:
            item.value.message?.creatorID == fullUserInfo.value.userId
              ? fullUserInfo.value.firstName
              : fullUserInfo.value.coparentFirstName,
          content: item.value.message?.content
        })
      : item.value.message?.content
)

const bubbleClass = computed(() =>
  item.value.message?.creatorID == fullUserInfo.value.userId
    ? 'parent'
    : 'coparent'
)
</script>
<style scoped>
.main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-1);
  padding-inline-end: var(--size-1);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-00);
  position: relative;
  flex: 1;

  svg {
    flex: 0 0 fit-content;
  }
}

.message-wrapper {
  padding-inline-end: var(--size-2);

  @media (width >= 48em) {
    padding-inline-end: var(--size-5);
  }

  :deep(& > h3) {
    color: var(--text-2);
    font-size: var(--font-size-0);
    word-break: break-all;
    width: fit-content;
  }

  &.bubble {
    :deep(& > h3) {
      font-size: var(--font-size-1);
      padding: var(--size-00) var(--size-0);
      border-radius: 0.8625rem;
    }

    &.parent {
      :deep(& > h3) {
        background: var(--teal-gradient-2);
        color: var(--text0-dark);
        border-bottom-right-radius: 0;
      }
    }

    &.coparent {
      :deep(& > h3) {
        background: var(--surface-2);
        color: var(--text-0);
        border-bottom-left-radius: 0;
      }
    }
  }
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  gap: var(--size-1);
  position: relative;
  flex: 1 1 auto;

  :deep(h2) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    font-size: var(--font-size-0);
    color: var(--text-0);
  }

  :deep(h2.conversation) {
    font-size: var(--font-size-2);
    font-variation-settings: 'wght' 500;

    @media (width >= 48em) {
      font-size: var(--font-size-3);
    }
  }

  :deep(h2.is-new) {
    font-variation-settings: 'wght' 700;
  }

  :deep(h2.is-new::before) {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    background-color: var(--focus-blue);
    position: absolute;
    inset-inline-start: -1.2rem;
    inset-block-start: 0.4rem;

    @media (min-width: 48em) {
      width: 14px;
      height: 14px;
      inset-inline-start: -1.75rem;
      inset-block-start: 0.25em;
    }
  }

  time {
    font-size: var(--font-size-0);
    align-self: flex-start;
    flex: 0 0 fit-content;
  }
}

.chevron {
  width: 1.25rem;
  height: 1.25rem;
}

.attachment-wrapper {
  background: var(--surface-1);
  border: solid 1px var(--surface-3);
  border-radius: 0.5rem;
  padding: var(--size-00) var(--size-5) var(--size-00) var(--size-1);
  display: flex;
  flex-direction: column;
  gap: var(--size-0);
  width: fit-content;

  time {
    font-size: var(--size-0);
    color: var(--text-2);
    align-self: flex-end;
    margin-inline-end: calc(var(--size-3) * -1);
  }
}

.filename-wrapper {
  display: flex;
  gap: var(--size-0);
  color: var(--brand-9);

  :deep(h3) {
    font-size: var(--font-size-1);
    color: var(--brand-9);
    font-variation-settings: 'wght' 500;
    min-width: 0;
    width: 0 0 fit-content;
  }
}

:deep(em) {
  font-style: normal;
  font-variation-settings: 'wght' 700;
}
</style>
