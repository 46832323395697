import signalrHub from '@/services/signalrHub'
import type { App } from 'vue'
export default {
  install: (app: App) => {
    function signalrHubStart(token: string) {
      signalrHub.start(token)
    }

    app.config.globalProperties.$signalrHub = signalrHubStart
    app.provide('signalrHubStart', signalrHubStart)
  }
}
