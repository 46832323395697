
{
    "layout": {
        "offline": "You are currently offline."
    },
    "footer": {
        "copyright": "Copyright"
    },
    "nav": {
        "messaging": "Mensajería segura",
        "attachments": "Biblioteca adjunta",
        "calendar": "Calendario compartido",
        "payments": "Pagos Responsables",
        "info": "Biblioteca de información",
        "records": "Registros inalterables",
        "journal": "Diario personal",
        "settings": "Configuraciones de la cuenta",
        "help": "Centro de ayuda",
        "contact": "Contacta con nosotras",
        "signout": "Desconectar",
        "tryNewLook": "Try the New Look!",
        "tryNewLook_sm": "New Look!",
        "feedback": "Give us your feedback",
        "vault": "Vault",
        "calling": "Accountable Calling"
    },
    "titles": {
        "messaging": "Mensajería con ",
        "messagingMobile": "Mensajería segura",
        "attachments": "Biblioteca adjunta",
        "calendar": "Calendario compartido",
        "payments": "Pagos Responsables",
        "info": "Biblioteca de información",
        "records": "Registros inalterables",
        "journal": "Diario personal",
        "settings": "Configuraciones de la cuenta",
        "help": "Centro de ayuda",
        "contact": "Contacta con nosotras",
        "signout": "Desconectar",
        "vault": "Vault",
        "calling": "Accountable Calling"
    },
    "messages": {
        "newMessageBtn": "New message",
        "search": "Search messages",
        "searchMobile": "Search",
        "total": "total"
    },
    "banners": {
        "upgradeBannerFreeText": "Upgrade to a Standard or Premium Plan",
        "upgradeBannerStandardPlan": "Upgrade to a Premium Plan",
        "highlightWords": "Standard, Premium Plan",
        "upgradeBannerMessageTier0": "for unlimited access to all attachments.",
        "upgradeBannerRecordsTier0": "for unlimited PDF access, plus discounted rates on Printed Records.",
        "upgradeBannerRecordsTier1": "to save an additional $10 on every Printed Record."
    }
  }