<template>
  <div ref="lottieContainer" :style="{ width, height }"></div>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, onUnmounted, ref, watch } from 'vue'
import { LottiePlayer } from '@lottiefiles/lottie-player'

type Props = {
  src: string
  autoplay?: boolean
  loop?: boolean
  speed?: number
  background?: string
  width?: string
  height?: string
}

const props = defineProps<Props>()

const lottieContainer = ref<HTMLElement | null>(null)
let player: LottiePlayer | null = null

onMounted(() => {
  if (!customElements.get('lottie-player')) {
    customElements.define('lottie-player', LottiePlayer)
  }

  player = document.createElement('lottie-player') as LottiePlayer
  if (lottieContainer.value) {
    lottieContainer.value.appendChild(player)
  }
  updatePlayerProps()

  watch(
    () => props.src,
    (newSrc) => {
      if (player) {
        player.src = newSrc
      }
    }
  )

  function updatePlayerProps() {
    if (player) {
      player.src = props.src
      player.background = props.background || 'transparent'
      player.speed = props.speed || 1
      player.loop = props.loop ?? false
      player.autoplay = props.autoplay ?? false
      player.style.width = props.width || '100%'
      player.style.height = props.height || '100%'
    }
  }
})

onUnmounted(() => {
  if (player && lottieContainer.value) {
    lottieContainer.value.removeChild(player)
    player = null
  }
})
</script>
