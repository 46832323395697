﻿<template>
  <div id="divNoSearchAttachments" class="alert alert-info">
    <div class="stack align-middle">
      <slot name="header" />
      <slot name="main" />
    </div>
  </div>
</template>
<style scoped lang="scss">
#divNoSearchAttachments {
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    margin-bottom: 14rem;
  }

  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
  }

  :deep(.stack > * + *) {
    margin-top: var(--space, 1rem);
    margin-top: var(--space, 1rem);
  }
}

:deep(.nocontent-header) {
  font-size: var(--font-size-2);
  color: var(--text-1);
  @media (max-width: 768px) {
    font-size: var(--font-size-2);
  }
}

:deep(.no-content-icon-container) {
  max-width: 311px;
  max-height: 311px;
  width: 100%;
  aspect-ratio: 1;
  background-color: #ededfe;
  border-radius: 50%;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -7rem;
  margin-top: -7rem;

  > svg {
    width: 80% !important;
    height: 80%;
    color: #9394a0;
  }
  @media (max-width: 768px) {
    max-width: 191px;
    max-height: 191px;
    padding: 2rem;
    margin-top: 0;
    margin-top: 0;
  }
}
</style>
