﻿<template>
  <div>
    <header v-if="isDesktopWidth" class="border-bottom pb-1">
      <h1 class="dark-green text-center">Sign In<span class="sr-only"> to your account</span></h1>
    </header>
    <form class="flex-column" :class="isDesktopWidth ? 'mt-3 gap-4' : 'gap-5'" @submit.prevent="submitLogin">
      <div v-if="!isOnline" class="tp-offline-container">
        You are currently offline.
      </div>
      <div class="stack" :class=" isDesktopWidth ? 'size-1' : 'size-2'">
        <text-input
          v-model="state.localEmail"
          name="email"
          type="text"
          label="Email"
          placeholder=""
          success-message=""
          aria-describedby="NameTip"
          :focus="true"
          :required="true"
          :disabled="showMfaModal"
          @input="resetLoginErrorMsg"

        />
        <text-input
          v-model="state.localPassword"
          name="password"
          type="password"
          label="Password"
          placeholder=""
          success-message=""
          aria-describedby="NameTip"
          :required="true"
          :disabled="showMfaModal"
          :password-visibility-option="true"
          autocomplete="off"
          @input="resetLoginErrorMsg"

        />
      </div>
      <div class="stack" :class="isDesktopWidth ? 'size-4' : 'login-ctas'">
        <div class="stack size-1">
          <router-link
            to="/forgotEmailOrPassword"
            class="fit-content mx-auto purple underline"
            :class="isDesktopWidth ? 'font-size-1' : 'font-size-0'"
            >Forgot email or password?
          </router-link>
          <a
            v-if="!isDesktopWidth"
            :class="{ 'fit-content mx-auto pb-1 purple font-size-0' : !isDesktopWidth }"
            target="_blank"
            href="https://support.talkingparents.com/hc/en-us/requests/new"
          >
            <font-awesome-icon
              class="icon-size-1 "
              icon="fa-light fa-envelope"
            />
            Contact us
          </a>
        </div>
        <div class="stack size-1" :class="{ 'mx-1' : isDesktopWidth }">
          <button
            type="submit"
            class="btn primary w-100"
            :class="{ disabled: !meta.dirty || !meta.valid || isSubmitting }"
            :disabled="!meta.dirty || !meta.valid || isSubmitting"
          >
            {{ state.signinBtnText }}
          </button>
          <button
            type="button"
            class="btn secondary w-100"
            :class="{ disabled: isSubmitting }"
            :disabled="isSubmitting"
            @click="router.push({ name: 'signup'})"
          >
            Create an account
          </button>
        </div>
      </div>
    </form>
    <MFA
      @close="close"
      :modalActive="showMfaModal"
      :email="state.email"
      :password="state.password"
      @submit-login="tryLogin"
    ></MFA>
  </div>
</template>

<script lang="ts" setup>
import { inject, reactive } from 'vue'
import ErrorHelper from '../../exports/error'
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'
import { useLoginStore } from '@/stores/LoginStore'
import type { ILoginRequest } from '@/models/interfaces'
import MFA from './components/MfaModalComponent.vue'
import { useRoute, useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import TextInput from '@/components/library/TextInput.vue'
const trackingIdentify: any = inject('$trackingIdentifyWithId')

const route = useRoute()
const router = useRouter()
const state = reactive({
  localEmail: '',
  localPassword: '',
  email: '',
  password: '',
  submitError: false,
  submitErrorMessage: '',
  signinBtnText: 'Sign In',
  submitting: false
})

const loginStore = useLoginStore()
const { showMfaModal, showLoginErrorMsg, loginError, loggingOut } = storeToRefs(loginStore)
const { login, setShowMfaModal } = loginStore

const commonStore = useCommonStore()
const { isOnline, isDesktopWidth, isUserMatched } =  storeToRefs(commonStore)
const { fetchFullUserInfo } = commonStore

const formValidationSchema = {
  email: 'required|email',
  password: 'required'
}

const { meta, isSubmitting, validate, setErrors } = useForm({
  validationSchema: formValidationSchema
})

async function resetLoginErrorMsg() {
  loginError.value = ''
}

async function submitLogin() {
  const { valid } = await validate()
  if (valid) {
    state.submitting = true
    state.email = state.localEmail
    state.password = state.localPassword
    state.submitError = false
    state.signinBtnText = 'Logging In...'
    try {
      const payload: ILoginRequest = {
        username: state.localEmail,
        password: state.localPassword,
        returnUrl: route.query?.returnTo?.toString()
      }

      tryLogin(payload)
    } catch (e) {
      ErrorHelper.handleError(e, 'submitLogin')

      state.signinBtnText = 'Sign In'
      return
    }
  }
}

function tryLogin(payload: ILoginRequest) {
  login(payload).then((response: string) => {
    console.log('login response: ', response)
    if (response == 'success') {
      trackingIdentify({ email: payload.username || '' })

      fetchFullUserInfo().then((success) => {
        console.log('fetchFullUserInfo success: ', success)
        console.log('isUserMatched.value: ', isUserMatched.value)
        if (!success) {
          // there was an error
          router.push({ name: 'logout' })
          return
        } else {
          //SHOULD have fullUserInfo now to check for case id
          //case id will be 0 if unmatched
          if (isUserMatched.value) {
            if (payload.returnUrl) {
              router.push(payload.returnUrl)
            } else {
              router.push({
                name: 'messages'
              })
            }
          } else if (!loggingOut.value) {
            router.push({ name: 'signupMatch' })
          }
        }
      })
    } else {
      if (showLoginErrorMsg.value) {
        setErrors({password: loginError.value})
      }
      state.signinBtnText = 'Sign In'
      state.submitting = false
    }
  })
}
  
function close() {
  state.signinBtnText = 'Sign In'
  setShowMfaModal(false)
}
</script>

<style lang="scss" scoped>
.tp-offline-container {
  border: 3px solid red;
  padding: .5rem;
  margin-bottom: 1rem;
  border-radius: var(--radius-4);
  text-align: center;
}
:deep(label){
  color: var(--gray-10)
}
:deep(input){
  color: var(--text-0)
}
.login-ctas {
  --space: 6rem;
}
</style>
