<template>
  <div
    class="call-container" :class="isMobileWidth ? 'mobile' : 'desktop'"
  >
    <div>
      <header>
        <div
          class="desktop-header"
          v-if="!isMobileWidth"
        >
          Starting a new phone call
        </div>
        <div
          v-else
          class="flex-column gap-0 mt-0 w-100 phone"
        >
          <font-awesome-icon
            icon="fa-light fa-phone" 
            class="icon-size-5 mr-auto ml-auto"
            v-skeleton="state.loading"
          />
          <span 
            class="mr-auto ml-auto"
            v-skeleton="state.loading"
          >
            Phone call to {{ fullUserInfo.coparentFullName }}
          </span>
        </div>
      </header>
      <phone-pre-call-check
        v-if="!canCall && !state.loading"
      />
      <div class="flex-column gap-1 align-middle">
        <div 
          v-if="!isMobileWidth && canCall"
          class="pill transcripts mt-0"
          v-skeleton="state.loading"
        >
          <font-awesome-icon 
            icon="fa-light fa-circle-exclamation"
            class="icon-size-1"
          />
          Premium accounts can request calling transcripts
        </div>
        <!-- minutes -->
        <div 
          v-if="subscriptionT2 || state.loading"
          class="text-center mt-1 minutes flex-column gap-1"
          v-skeleton="state.loading"
        >
          <span>Available minutes: <strong> {{ callingBalance }}</strong></span>
          <button
            type="button"
            class="btn tertiary minutes"
            @click="addMinutesClick"
          >
            <font-awesome-icon 
              icon="fa-light fa-plus"
              class="icon-size-1"
            />
            <strong class="purple">Add minutes</strong>
          </button>
        </div>
        <div
          v-if="isMobileWidth && canCall"
          class="pill transcripts mobile"
          v-skeleton="{
            loading: state.loading,
            minWidth: 30,
            maxwidth: 30,
          }"
        >
          <font-awesome-icon 
            icon="fa-light fa-circle-exclamation"
            class="icon-size-0"
          />
          <strong>All Accountable Calls are recorded.</strong>
        </div>
        <!-- todo: idk if this is right but i got it straight from figma -->
        <svg 
          v-if="!isMobileWidth"
          class="john-parity" :class="{ 'svg-loading' : state.loading }" xmlns="http://www.w3.org/2000/svg" width="159" height="147" viewBox="0 0 159 147" fill="none"
        >
          <path d="M84.0351 146.258H79.3115C78.7111 146.258 78.1355 146.015 77.7109 145.584C77.2864 145.153 77.0476 144.568 77.0469 143.958V103.976C77.0476 103.366 77.2864 102.781 77.7109 102.35C78.1355 101.919 78.7111 101.677 79.3115 101.676H84.0351C84.6356 101.677 85.2112 101.919 85.6358 102.35C86.0603 102.781 86.2991 103.366 86.2998 103.976V143.958C86.2991 144.568 86.0603 145.153 85.6358 145.584C85.2112 146.015 84.6356 146.258 84.0351 146.258Z" fill="#28B99A"/>
          <path d="M72.2588 146.258H67.5352C66.9347 146.258 66.3591 146.015 65.9346 145.584C65.51 145.153 65.2712 144.568 65.2705 143.958V103.976C65.2712 103.366 65.51 102.781 65.9346 102.35C66.3591 101.919 66.9347 101.677 67.5352 101.676H72.2588C72.8592 101.677 73.4349 101.919 73.8594 102.35C74.284 102.781 74.5228 103.366 74.5234 103.976V143.958C74.5228 144.568 74.284 145.153 73.8594 145.584C73.4349 146.015 72.8592 146.258 72.2588 146.258Z" fill="#28B99A"/>
          <path d="M77.4675 51.2053C87.9203 51.2053 96.394 42.7317 96.394 32.2789C96.394 21.8261 87.9203 13.3524 77.4675 13.3524C67.0147 13.3524 58.541 21.8261 58.541 32.2789C58.541 42.7317 67.0147 51.2053 77.4675 51.2053Z" fill="#28B99A"/>
          <path d="M88.2281 38.5877C89.2971 38.3218 90.6275 37.9907 91.5093 36.9407C91.7701 36.6179 91.9634 36.2364 92.0758 35.8229C92.1881 35.4095 92.2168 34.974 92.1597 34.5471C92.1243 34.2426 92.0253 33.9513 91.8703 33.6958C91.7153 33.4403 91.5086 33.2276 91.2663 33.0744C90.6382 32.6917 89.8312 32.745 89.0333 33.202L88.6441 25.97L87.9824 26.0128L88.44 34.5149L88.9292 34.1272C89.4964 33.6779 90.3238 33.3323 90.9447 33.7106C91.0985 33.8113 91.2292 33.9496 91.3264 34.1148C91.4237 34.28 91.4849 34.4676 91.5054 34.6629C91.5462 34.981 91.5242 35.305 91.4408 35.6129C91.3573 35.9208 91.2145 36.2054 91.022 36.4472C90.3357 37.2643 89.2843 37.5786 88.0816 37.878L88.2281 38.5877Z" fill="#2F2E41"/>
          <path d="M96.2295 24.8704L92.2764 25.6512L92.4213 26.3852L96.3744 25.6044L96.2295 24.8704Z" fill="#2F2E41"/>
          <path d="M83.7529 27.3357L79.7998 28.1165L79.9448 28.8505L83.8979 28.0697L83.7529 27.3357Z" fill="#2F2E41"/>
          <path d="M81.8864 110.929H59.4809C58.7856 110.928 58.119 110.657 57.6273 110.175C57.1357 109.692 56.8592 109.038 56.8584 108.356V75.3741C56.8643 70.3045 58.9199 65.4441 62.5743 61.8594C66.2287 58.2746 71.1834 56.2581 76.3515 56.2524C81.6652 56.2583 86.7595 58.3316 90.5168 62.0174C94.2742 65.7032 96.3877 70.7004 96.3937 75.9129V96.6983C96.3893 100.471 94.8594 104.088 92.1397 106.756C89.42 109.424 85.7326 110.925 81.8864 110.929Z" fill="#2F2E41"/>
          <path d="M70.3084 24.2157C73.7268 22.0933 78.2682 22.8767 82.0897 23.1472C86.1729 23.4361 90.414 23.1029 93.9971 21.1235C96.9791 19.4762 99.1696 16.6634 99.1274 13.7415C99.088 11.0102 96.8743 8.35608 93.5849 7.71764C90.2336 7.06719 86.2815 8.90141 85.4205 11.7275L86.4082 11.5036C82.0513 7.83948 76.7784 4.15016 70.0256 4.133C66.9959 4.1253 63.8463 4.82862 61.4569 6.51277C59.2174 8.09134 57.9125 10.2751 57.261 12.5622C56.5052 15.2158 56.5488 17.9566 56.6704 20.628C56.6966 21.2034 57.791 21.0723 57.7648 20.4989C57.5738 16.3039 57.4683 11.2293 61.3199 7.82847C63.3193 6.06316 66.1647 5.19507 68.9932 5.04631C71.9977 4.88828 74.8804 5.56442 77.4444 6.69962C80.517 8.06002 83.106 10.0361 85.516 12.0629C85.5813 12.1288 85.6693 12.1771 85.7696 12.2023C85.8698 12.2274 85.9782 12.2282 86.082 12.2047C86.1858 12.1812 86.2806 12.1342 86.3553 12.0694C86.43 12.0046 86.4815 11.9247 86.5037 11.839C87.1948 9.57047 90.394 8.17313 93.0563 8.55042C96.0671 8.9771 97.9984 11.4716 98.033 13.8706C98.0726 16.6178 95.78 19.2028 92.9462 20.6385C89.5322 22.3683 85.5849 22.5095 81.8071 22.2223C77.7943 21.9173 73.2301 21.3015 69.6656 23.5145C69.5528 23.5902 69.4783 23.6972 69.4578 23.813C69.4374 23.9288 69.4725 24.0444 69.5559 24.1354C69.6393 24.2263 69.7644 24.2855 69.9047 24.3005C70.0451 24.3155 70.1898 24.2851 70.3084 24.2157Z" fill="#2F2E41"/>
          <path d="M82.9302 40.5943C82.9274 40.647 82.9237 40.6998 82.9183 40.7527C82.8756 41.1994 82.7456 41.6331 82.536 42.0288C82.3265 42.4244 82.0414 42.774 81.6974 43.0575L77.8705 46.228C77.177 46.801 76.2873 47.0723 75.3964 46.9826C74.5055 46.8929 73.6861 46.4494 73.118 45.7493L63.5093 33.8802C62.9431 33.1786 62.675 32.2785 62.7637 31.3773C62.8525 30.4761 63.2908 29.6472 63.9827 29.0724L67.8097 25.9021C68.1527 25.6179 68.5477 25.4047 68.9722 25.2749C69.3967 25.1452 69.8422 25.1012 70.2835 25.1457C70.7247 25.1901 71.153 25.3221 71.5439 25.534C71.9347 25.7459 72.2804 26.0336 72.5613 26.3808L82.1699 38.2499C82.7052 38.9072 82.9766 39.744 82.9302 40.5943Z" fill="#2F2E41"/>
          <path d="M70.7383 34.3818C71.4352 34.3818 72.0001 33.8169 72.0001 33.12C72.0001 32.4232 71.4352 31.8583 70.7383 31.8583C70.0415 31.8583 69.4766 32.4232 69.4766 33.12C69.4766 33.8169 70.0415 34.3818 70.7383 34.3818Z" fill="white"/>
          <path d="M79.4853 34.957L79.4842 34.9649C80.0299 35.0761 80.5123 35.3789 80.8366 35.8138C81.1609 36.2486 81.3036 36.784 81.2366 37.3146L76.8467 71.7747C76.7057 72.88 76.1279 73.8916 75.2322 74.6012C74.3364 75.3108 73.1909 75.6645 72.0313 75.5896C70.8717 75.5146 69.7862 75.0167 68.998 74.1983C68.2099 73.3798 67.7791 72.3031 67.7943 71.1896C67.7968 71.0326 67.8081 70.8759 67.828 70.72L72.2179 36.26C72.2896 35.6974 72.5916 35.1851 73.0574 34.8358C73.5233 34.4865 74.1149 34.3289 74.7021 34.3975L79.4853 34.957Z" fill="#28B99A"/>
          <path d="M158.233 146.258H0.90845C0.800014 146.258 0.696128 146.214 0.619563 146.135C0.542998 146.056 0.5 145.949 0.5 145.838C0.5 145.726 0.542998 145.62 0.619563 145.541C0.696128 145.462 0.800014 145.417 0.90845 145.417H158.233C158.341 145.417 158.445 145.462 158.522 145.541C158.598 145.62 158.641 145.726 158.641 145.838C158.641 145.949 158.598 146.056 158.522 146.135C158.445 146.214 158.341 146.258 158.233 146.258Z" fill="#3F3D56"/>
        </svg>
        <!-- info -->
        <div
          v-if="!state.loading"
          class="info" 
        >
          You will receive a call from a TalkingParents toll-free phone number to connect you to your co-parent.
        </div>
        <span 
          v-if="proxyPhoneNumber && !state.loading"
          class="pill proxy-number"
        >
          <font-awesome-icon 
            icon="fa-light fa-phone"
            class="icon-size-0"
          />
          {{ proxyPhoneNumber }}
        </span>
      </div>
      <!-- cta's -->
      <footer class="dialog-footer">
        <button
          @click="setShowAudioCall(false)"
          type="button"
          class="btn secondary"
          v-skeleton="state.loading"
        >
          {{ closeText }}
        </button>
        <button
          v-if="canCall && !proxyPhoneNumber"
          type="button"
          class="btn primary"
          @click="startCall"
          v-skeleton="state.loading"
        >
          Start phone call
        </button>
      </footer>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onBeforeUnmount, onBeforeMount, reactive, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { useCallingStore } from '@/stores/CallingStore'
import { useCommonStore } from '@/stores/CommonStore'
import PhonePreCallCheck from './components/PhonePreCallCheck.vue'
import UpgradeMinutes from '@/components/UpgradeMinutes.vue'
import UpgradeDialog from '@/pages/infoLibrary/components/upgradeDialogComponent.vue'
import { useModals } from '@/composables/useModal/useModal'
import constants from '@/exports/constants'

const commonStore = useCommonStore()
const { isMobileWidth, fullUserInfo } = storeToRefs(commonStore)
const { setToast } = commonStore

const accountSettingStore = useAccountSettingsStore()
const { callingBalance, subscriptionT2, showAddMinutesSuccessToast } = storeToRefs(accountSettingStore)
const { fetchCallingBalance, setShowAddMinutesSuccessToast } = accountSettingStore

const callingStore = useCallingStore()
const { proxyPhoneNumber, callThreadId, canCallStatus } = storeToRefs(callingStore)
const { setShowAudioCall, sessionPost, bothParentsPassPrecheck, setProxyPhoneNumber, setCallThreadId } = callingStore

const { createSlot, generateModal } = useModals()

const state = reactive({
  loading: true
})

onBeforeMount(async () => {
  state.loading = true
  await fetchCallingBalance()
  await bothParentsPassPrecheck()
  setProxyPhoneNumber(0)
  state.loading = false

  if (showAddMinutesSuccessToast.value) {
    setToast({
      showToast: true,
      text: 'Success! Calling minutes added.',
      type: 'success',
      showCloseButton: true
    })
    setShowAddMinutesSuccessToast(false)
  }
})

onBeforeUnmount(() => {
  setProxyPhoneNumber(0)
  setCallThreadId(0)
  setShowAudioCall(false)
})

function startCall() {
  sessionPost({ threadID: callThreadId.value ? callThreadId.value : undefined })
}

function addMinutesClick() {
  if (subscriptionT2.value) {
    showCallingMinutesModal()
  } else {
    showUpgradeModal()
  }
}

function showCallingMinutesModal() {
  generateModal({
    slot: {
      content: createSlot('content', UpgradeMinutes).content
    },
    default: {
      headerText: 'Select minutes package'
    },
    config: {
      showHeader: true,
      showFooter: false,
      showCloseButton: true,
      closeOnOutsideClick: true
    }
  })
}

// todo: wording
function showUpgradeModal() {
  generateModal({
      slot: createSlot(
        'content',
        UpgradeDialog,
        {
          'header-text': 'Unable to add minutes',
          'upgrade-button-text': 'Upgrade'
        },
        {
          default: () =>
            'You must be a premium user to make phone or video calls.'
        }
      ),
      config: {
        showHeader: false,
        showFooter: false,
        addContentPadding: false
      }
  })
}

const canCall = computed(() => canCallStatus.value.get(constants.CAN_CALL_STATUS.allowed))

const closeText = computed(() => 
  proxyPhoneNumber.value 
    ? isMobileWidth.value
      ? 'Go back'
      : 'Close window'
    : 'Cancel call'
)
</script>
<style scoped>
.call-container {
}
.call-container.desktop {
  display: flex;
  position: fixed;
  width: 450px;
  right: 0;
  margin-right: var(--size-1);
  z-index: 3000;
  margin-bottom: var(--size-1);
  height: fit-content;
  border: 2px solid var(--surface-3);
  border-radius: var(--radius-3);
  background-color: var(--surface-1);
  bottom: 0;
}

.call-container > * {
  width: 100%;
}

.call-container.mobile > * {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

[color-scheme='dark'] {
  .call-container.desktop {
    background-color: var(--surface-2);
  }
}

.call-container.mobile {
  background-color: var(--surface-1);
  height: 100%;
  padding-top: 6rem;
  position: fixed;
  bottom: 0;
  z-index: 500;
  width: 100%;
}

.desktop-header {
  border-top-left-radius: var(--radius-2);
  border-top-right-radius: var(--radius-2);
  background-color: var(--surface-3);
  padding: var(--size-000);
  text-align: center;
}

.john-parity {
  width: 50%;
  margin-top: var(--size-0);
  margin-bottom: var(--size-0);
}

.svg-loading {
  opacity: .2;
  fill: var(--surface-2);
}

.content {
  padding:var(--size-1);
  width: 100%;
}

.info {
  font-size: var(--font-size-0);
  text-align: center;
  margin-left: var(--size-0);
  margin-right: var(--size-0);
}
.pill {
  padding: var(--size-00) var(--size-1);
}
.pill.mobile {
  padding: var(--size-000) var(--size-1);
}
.transcripts {
  color: var(--brand-8);
  background: var(--brand-1);
  strong {
    color: var(--brand-8);
  }
}

.transcripts.mobile {
  margin: var(--space-xs)
}

.proxy-number {
  color: var(--blue-9);
  background: var(--blue-1);
}

footer {
  padding: var(--size-0);
  display: flex;
  gap: var(--size-1);
  margin-top: auto;
  box-shadow: var(--box-shadow-3);
  background: var(--surface-1);
  position: sticky;
  bottom: 0;
  @media (min-width: 48em) {
    box-shadow: none;
    background: none;
  }
}

[color-scheme="dark"] footer {
  box-shadow: var(--shadow-4);

  @media (min-width: 48em) {
    box-shadow: none;
    background: none;
  }
}

strong {
  --base-weight: 600;
}
.phone svg{
  color: var(--brand-5)
}
</style>