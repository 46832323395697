<template>
  <div class="stack">
    <div>You have an incoming video call from your co-parent</div>
    <div class="flex gap-1">
      <button class="btn primary" @click="acceptVideoCall()">Accept call</button>
      <button class="btn secondary" @click="declineVideoCall()">Decline call</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCallingStore } from '@/stores/CallingStore'
import constants from '@/exports/constants'
import { storeToRefs } from 'pinia'
import { useEventBus } from '@vueuse/core'

const callingStore = useCallingStore()
const { updateCall, setShowVideoCall, fetchCallingItems, getActiveVideoCall } = callingStore
const { activeCall, } = storeToRefs(callingStore)

const callRequest = useEventBus<boolean>('callRequest')

const emit = defineEmits<{
  (e: 'success'): void
  (e: 'cancel'): void
}>()

function acceptVideoCall() {
  setShowVideoCall(true)
  callRequest.emit(true)
  emit('success')
}

// todo: solution for timeout ending on the caller side
async function declineVideoCall() {
  if (activeCall.value) {
    callRequest.emit(false)
    await updateCall({
      itemID: activeCall.value.itemID,
      callType: constants.CALL_TYPE_ENUM.video,
      newStatus: constants.VIDEO_CALL_STATUS_ENUM.CoparentRejected,
      action: constants.VIDEO_CALL_ACTION_ENUM.CallStatusUpdated,
      actionData: ''
    })
    fetchCallingItems()
    await getActiveVideoCall()
    emit('cancel')
  }
}
</script>
