import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import rg4js from 'raygun4js'

import App from './App.vue'
import router from './router'

import moment from 'moment-timezone'

import { registerSW } from 'virtual:pwa-register'
import { useCommonStore } from '@/stores/CommonStore'

// service worker
const intervalMS = 5 * 60 * 1000
let initialLoad = true

registerSW({
  onRegisteredSW(swUrl: string, r: any) {
    r &&
      setInterval(async () => {
        if (!(!r.installing && navigator)) return

        if ('connection' in navigator && !navigator.onLine) return
        console.log('checking for new service worker')
        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache'
          }
        })

        if (resp?.status === 200) {
          console.log('checking for new service worker')
          await r.update()
        }
      }, intervalMS)
  },
  onNeedRefresh() {
    // set a flag to update the service worker on a navigation change
    if (!initialLoad) {
      console.log('new service worker ready from main.ts')
      localStorage.setItem('newVersionAvailable', 'true')
      // const lcommonStore = useCommonStore()
      // lcommonStore.setUpdateServiceWorker(true)
    } else {
      initialLoad = false
    }
  }
})

//create app
const app = createApp(App)
if (import.meta.env.VITE_NODE_ENV) {
  app.config.performance = true
}

// localization
// import messages from '@intlify/unplugin-vue-i18n/messages'
// import enUS from './i18n/locales/en.json'
// import es from './i18n/locales/es.json'
// type MessageSchema = typeof enUS
// import { createI18n, type I18nOptions } from 'vue-i18n'

// const options: I18nOptions = {
//   legacy: false,
//   locale: 'es',
//   fallbackLocale: 'en',
//   messages: {
//     en: enUS,
//     es: es
//   }
// }

// const i18n = createI18n<false, typeof options>(options)

// const i18n = createI18n<[MessageSchema], 'en'>({
//   legacy: false,
//   globalInjection: true,
//   locale: 'en',
//   fallbackLocale: 'en',
//   messages: {
//     en: enUS
//   }
// })

// const i18n =
//   createI18n <
//   [MessageSchema]({
//     legacy: false,
//     globalInjection: true,
//     locale: 'es',
//     fallbackLocale: 'en',
//     availableLocales: ['en', 'es'],
//     messages: messages
//   })
import i18n from './i18n'
app.use(i18n)

// moment.js
app.config.globalProperties.$moment = moment

// TODO: Raygun Add Route tracking in router and setUser
//https://raygun.com/documentation/language-guides/javascript/vuejs/

app.config.errorHandler = function (err, vm, info) {
  rg4js('apiKey', import.meta.env.VITE_APP_RAYGUN_KEY)
  rg4js('enableCrashReporting', true)
  rg4js('saveIfOffline', true)
  rg4js('trackEvent', {
    type: 'pageView',
    path: '/' + window.location.pathname
  })
  rg4js('options', {
    allowInsecureSubmissions: true,
    ignore3rdPartyErrors: true,
    debugMode: false
  })
  rg4js('whitelistCrossOriginDomains', ['webpack-internal', '[native code]'])

  rg4js('send', {
    error: err,
    customData: [{ info: info }]
  })
}

//TODO: New way to check if element is in view in DOM
// vue2 app uses vue-check-view

import { ObserveVisibility } from 'vue-observe-visibility'

app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    ;(vnode as any).context = binding.instance
    ObserveVisibility.bind(el, binding, vnode)
  },
  updated: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind
})

import signalrPlugin from './plugins/signalrPlugin'
app.use(signalrPlugin)

import trackerPlugin from './plugins/trackerPlugin'
app.use(trackerPlugin)

// Form validation global validator
import { defineRule } from 'vee-validate'
import emailHelper from '@/exports/emailHelper'
import pwHelper from '@/exports/passwordHelper'
defineRule('required', (value: any) => {
  if (!value || !value.length) {
    return 'This field is required.'
  }
  return true
})
defineRule('email', (value: any) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  // Check if email
  if (value.match(emailHelper.getEmailRegex()) === null) {
    return 'Please provide a valid email address.'
  }
  return true
})
defineRule('password', (value: any) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  // Check if passwoed rules match
  if (value.match(pwHelper.getPasswordRegexRequirement()) === null) {
    return 'Please provide a password with at least 10 characters, one letter, one number, and one special character.'
  }
  return true
})
defineRule('phone', (value: any) => {
  if (!value || !value.length) {
    return true
  }
  if (value.match(phoneHelper.getPhoneRegex()) === null) {
    return 'This phone number is invalid.'
  }
  return true
})

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', Datepicker)

import phoneHelper from './exports/phoneHelper'

app.directive('inline', (element) => {
  element.replaceWith(...element.children)
})

app.directive('highlight-text', {
  beforeMount(el, binding) {
    const searchTerm = binding.value.searchTerm
    const _class = binding.value.class
    const _style = binding.value.style
    const text = el.textContent
    if (searchTerm && text) {
      const regex = new RegExp(escapeRegExp(searchTerm), 'gi')
      const replacement = `<span class="${_class}" style="${_style}">$&</span>`
      el.innerHTML = text.replace(regex, replacement)
    } else if (!searchTerm && text) {
      el.innerHTML = text
    }
  },
  beforeUpdate(el, binding) {
    const searchTerm = binding.value.searchTerm
    const _class = binding.value.class
    const _style = binding.value.style
    const text = el.textContent
    if (searchTerm && text) {
      const regex = new RegExp(escapeRegExp(searchTerm), 'gi')
      const replacement = `<span class="${_class}" style="${_style}">$&</span>`
      el.innerHTML = text.replace(regex, replacement)
    } else if (!searchTerm && text) {
      el.innerHTML = text
    }
  }
})

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

app.directive('skeleton', (el, binding) => {
  //can provide one of several things:
  //1. A boolean
  //2. An object with a 'loading' variable of type boolean
  //3. An object with a 'loading' variable and a 'minWidth' variable of type double
  //4. An object with a 'loading' variable and a 'maxWidth' variable of type double
  //5. An object with 'loading', 'minWidth', and 'maxWidth'

  const minWidth =
    (binding.value.minWidth ?? false) && 'minWidth' in binding.value
      ? binding.value.minWidth
      : 4.5
  const maxWidth =
    (binding.value.maxWidth ?? false) && 'maxWidth' in binding.value
      ? binding.value.maxWidth
      : 10
  if (
    ((binding.value.loading ?? false) && 'loading' in binding.value) ||
    (typeof binding.value === 'boolean' && binding.value)
  ) {
    el.classList.add('skeleton')

    //direct is called for each element in list when list is updated
    //don't need to change the width if it's already been set
    if (!el.style.getPropertyValue('--skeleton-width').length) {
      //at most 10 rem, at least 4.5
      //might change ranges if need be?
      const finalWidth = Math.random() * (maxWidth - minWidth) + minWidth
      el.style.setProperty('--skeleton-width', `${finalWidth}rem`)
    }
  } else el.classList.remove('skeleton')
})

/* import the fontawesome core */
import { library, type IconDefinition } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { all } from '@awesome.me/kit-4ad9ec01d5/icons'

/* add icons to the library */
library.add(...(all as IconDefinition[]))

// body scroll lock
import V3ScrollLock from 'v3-scroll-lock'
app.use(V3ScrollLock, {})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

app.use(pinia)
app.use(router)

app.component('font-awesome-icon', FontAwesomeIcon)

//----------------------------------Begin AI----------------------------------
// code in vue-appinsights.js is copied from https://github.com/latelierco/vue-application-insights and modified
import VueAppInsights from './vue-appinsights'
const connection: string =
  import.meta.env.VITE_APP_APP_INSIGHTS_CONNECTION_STRING ||
  'InstrumentationKey=44268c94-5787-4788-a69e-659c4e81122e;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/'
app.provide('$appInsights', VueAppInsights)
app.use(VueAppInsights, {
  connectionString: connection,
  router
})
//----------------------------------End AI----------------------------------
import loading from '@/components/library/Loading.vue'
// eslint-disable-next-line vue/multi-word-component-names
app.component('loading', loading)
app.mount('#app')
