﻿import { defineStore } from 'pinia'
import ErrorHelper from '@/exports/error'
import { Card, EmptyCard, Tab, type IInfoLibraryState } from '@/models/stores/infoLibrary'
import { useCommonStore } from './CommonStore'
import httpClient from '@/httpClient'
import type { ICard, IField, IGroupedCards, ITab, ITemplateCard } from '@/models/interfaces'
import helpers from '../exports/helper'
import type { ICreateCardRequest, IEditCardRequest } from '@/models/models'
import { InfoLibraryCard } from '@/models/models'

export const useInfoLibraryStore = defineStore('infoLibrary', {
  state: (): IInfoLibraryState => ({
    tabs: [] as ITab[],
    selectedTabID: 0,
    showAddTab: false,
    addTabErrorMessage: null,
    showEditTab: false,
    tabForEdit: Tab,
    editTabErrorMessage: null,
    showDeleteTab: false,
    deleteTabErrorMessage: null,
    selectedCardID: null,
    showAddCard: false,
    addCardErrorMessage: null,
    showEditCard: false,
    cardForEdit: Card,
    editCardErrorMessage: null,
    showDeleteCard: false,
    showUpgradePrompt: false,
    deleteCardErrorMessage: null,
    cards: [new InfoLibraryCard()] as ICard[],
    groupedCards: [] as IGroupedCards[],
    searchTerm: null,
    filterCardsBy: '0',
    sortCardsBy: '0',
    newCardAlert: { newCard: false, cardId: 0 },
    tabCards: [] as ICard[],
    placeholderCards: [
      new InfoLibraryCard({ viewedWhen: new Date() }),
      new InfoLibraryCard({ viewedWhen: new Date() }),
      new InfoLibraryCard({ viewedWhen: new Date() }),
      new InfoLibraryCard({ viewedWhen: new Date() }),
      new InfoLibraryCard({ viewedWhen: new Date() })
    ] as ICard[],
    currentTemplate: EmptyCard
  }),
  getters: {
    canEditTab(state): boolean {
      const common = useCommonStore()
      return (
        !common.loading &&
        state.selectedTabID != null &&
        state.selectedTabID != 0
      )
    },
    canDeleteTab(state): boolean {
      const common = useCommonStore()
      return (
        !common.loading &&
        state.selectedTabID != null &&
        state.selectedTabID != 0
      )
    },
    canEditCard(state): boolean {
      const common = useCommonStore()
      return (
        !common.loading &&
        state.selectedCardID != null &&
        state.selectedCardID != 0
      )
    },
    canDeleteCard(state): boolean {
      const common = useCommonStore()
      return (
        !common.loading &&
        state.selectedCardID != null &&
        state.selectedCardID != 0
      )
    },
    getSelectedTab: (state) => {
      return state.tabs.find((t) => t.itemID == state.selectedTabID)
    },
    getSelectedCard: (state) => {
      return state.cards.find((c) => c.itemID == state.selectedCardID)
    },
    getTotalCardLength: (state) => {
      return state.cards.length
    },
    getPersonalCardsLength: (state) => {
      const commonStore = useCommonStore()
      return state.cards.filter(
        (c) => c.userID == commonStore.fullUserInfo.userId
      ).length
    }
  },
  actions: {
    getTabName(tabName: string) {
      const commonStore = useCommonStore()
      return tabName ?? commonStore.fullUserInfo.coparentFirstName
    },
    getTabId(tabName: string) {
      return 'tab' + this.getTabName(tabName).replace(' ', '')
    },
    async fetchTabs() {
      try {
        const url = '/web/api/InfoLibrary/GetTabs'

        const result = await httpClient.get(url)

        if (!result?.data?.success) {
          throw new Error(result?.data?.errorMessage)
        } else {
          this.tabs = result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchTabs')
        return
      }
    },
    async addTab(tabName: string) {
      try {
        const url = '/web/api/InfoLibrary/CreateTab'

        const result = await httpClient.post(url, {
          Name: tabName
        })

        if (!result?.data?.success) {
          if (result?.data?.errorCode == 0) {
            throw new Error(result?.data?.errorMessage)
          } else {
            this.addTabErrorMessage = result.data.errorMessage
          }
        } else {
          await this.fetchTabs()
          // bacause we are not returning the id of the new tab,
          // let's find it by name and then set the selcted tab to the new tab
          const _tab = this.tabs.find((t) => t.name == tabName)
          if (_tab) {
            this.setSelectedTabID(_tab?.itemID)
          }
        }
        return result?.data?.success || false
      } catch (e) {
        ErrorHelper.handleError(e, 'addTab')
        return false
      }
    },
    async fetchTab() {
      try {
        const url = '/web/api/InfoLibrary/GetTab'

        const result = await httpClient.get(url, {
          params: {
            TabID: this.selectedTabID
          }
        })

        if (!result?.data?.success) {
          throw new Error(result?.data?.errorMessage)
        } else {
          this.tabForEdit = result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchTab')
        return
      }
    },
    async editTab(tab: ITab) {
      try {
        const url = '/web/api/InfoLibrary/EditTab'

        const result = await httpClient.post(url, {
          itemID: tab.itemID,
          name: tab.name
        })

        if (!result?.data?.success) {
          if (result?.data?.errorCode == 0) {
            throw new Error(result?.data?.errorMessage)
          } else {
            this.editTabErrorMessage = result.data.errorMessage
          }
        } else {
          this.fetchTabs()
        }
        return result?.data?.success || false
      } catch (e) {
        ErrorHelper.handleError(e, 'editTab')
        return false
      }
    },
    async deleteTab() {
      try {
        const url = '/web/api/InfoLibrary/DeleteTab'

        const result = await httpClient.post(url, {
          TabID: this.selectedTabID
        })

        if (!result?.data?.success) {
          if (result?.data?.errorCode == 0) {
            throw new Error(result?.data?.errorMessage)
          } else {
            this.deleteTabErrorMessage = result.data.errorMessage
          }
        } else {
          this.fetchTabs()
          this.selectedTabID = 0
          this.showDeleteTab = false
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'deleteTab')
        return
      }
    },
    async fetchCards(fetchAll?: boolean) {
      try {
        const url = '/web/api/InfoLibrary/GetAllCards'
        const params = {
          TabID: fetchAll ? 0 : this.selectedTabID,
          SearchTerm: fetchAll ? '' : this.searchTerm,
          FilterBy: fetchAll ? '0' : this.filterCardsBy,
          SortBy: fetchAll ? '0' : this.sortCardsBy
        }

        const result = await httpClient.get(url, {
          params: params
        })

        if (!result?.data?.success) {
          throw new Error(result?.data?.errorMessage)
        } else {
          const _cards: ICard[] = result.data.value

          this.groupedCards = []
          this.cards = _cards
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCards')
        return
      }
    },
    async addCard(card: ICreateCardRequest): Promise<boolean> {
      try {
        const url = '/web/api/InfoLibrary/CreateCard'

        const result = await httpClient.post(url, card)

        if (!result?.data?.success) {
          if (result.data.errorCode == 0) {
            throw new Error(result.data.errorMessage)
          } else {
            this.addCardErrorMessage = result.data.errorMessage
          }
        } else {
          await this.fetchCards(true)
          this.setTabCards(this.selectedTabID ?? 0)
          this.searchFilterTabCards()
        }
        return result?.data?.success || false
      } catch (e) {
        ErrorHelper.handleError(e, 'addCard')
        return false
      }
    },
    async fetchCard() {
      try {
        const url = '/web/api/InfoLibrary/GetCard'

        const result = await httpClient.get(url, {
          params: {
            CardID: this.selectedCardID
          }
        })

        if (!result?.data?.success) {
          throw new Error(result?.data?.errorMessage)
        } else {
          this.cardForEdit = result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCard')
        return
      }
    },
    async editCard(card: IEditCardRequest): Promise<boolean> {
      try {
        const url = '/web/api/InfoLibrary/EditCard'

        const result = await httpClient.post(url, card)

        if (!result?.data?.success) {
          if (result?.data?.errorCode == 0) {
            throw new Error(result?.data?.errorMessage)
          } else {
            this.editCardErrorMessage = result.data.errorMessage
          }
        } else {
          await this.fetchCards(true)
          this.setTabCards(this.selectedTabID ?? 0)
          this.searchFilterTabCards()
          this.showEditCard = false
        }
        return result?.data?.success
      } catch (e) {
        ErrorHelper.handleError(e, 'editCard')
        return false
      }
    },
    async deleteCard() {
      try {
        const url = '/web/api/InfoLibrary/DeleteCard'

        const result = await httpClient.post(url, {
          CardID: this.selectedCardID
        })

        if (!result?.data?.success) {
          if (result?.data?.errorCode == 0) {
            throw new Error(result?.data?.errorMessage)
          } else {
            this.deleteCardErrorMessage = result.data.errorMessage
          }
        } else {
          await this.fetchCards(true)
          this.setTabCards(this.selectedTabID ?? 0)
          this.searchFilterTabCards()
          this.showDeleteCard = false
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'deleteCard')
        return
      }
    },
    async viewCard() {
      try {
        const url = '/web/api/InfoLibrary/ViewCard'

        const result = await httpClient.post(url, {
          CardID: this.selectedCardID
        })

        if (!result?.data?.success) {
          this.fetchCards(true)
          ErrorHelper.handleError(
            result?.data?.errorMessage,
            'viewCard',
            false,
            result?.data?.errorMessage
          )
        } else {
          await this.fetchCards(true)
          this.setTabCards(this.selectedTabID ?? 0)
          this.searchFilterTabCards()
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'viewCard')
        return
      }
    },
    async checkTitleForDuplicate(title: string, itemID: number) {
      const duplicate = this.cards.find(
        (x) => x.shared == false && x.itemID != itemID && x.title === title
      )
      if (duplicate) {
        return true
      } else {
        return false
      }
    },
    async checkForDuplicate(text: string, itemID: number) {
      const duplicate = this.tabs.find(
        (x) => x.itemID != itemID && x.name === text
      )
      if (duplicate) {
        return true
      } else {
        return false
      }
    },
    setTabCards(selectedTabID: number) {
      const allCards = JSON.parse(JSON.stringify(this.cards))

      if (selectedTabID) {
        this.tabCards =
          allCards.filter((c: ICard) => c.tabID == selectedTabID) ?? []
      } else {
        this.tabCards = allCards
      }
    },
    searchFilterTabCards() {
      const commonStore = useCommonStore()
      const currentUserId = commonStore.fullUserInfo.userId

      const toBeFilteredCards = JSON.parse(JSON.stringify(this.tabCards))

      switch (this.filterCardsBy) {
        // No filter
        case '0':
          this.tabCards =
            toBeFilteredCards.filter((c: ICard) =>
              this.searchDescAndFields(c.title, c.description, c.fields)
            ) ?? []
          break
        // My shared cards
        case '1':
          this.tabCards =
            toBeFilteredCards.filter(
              (c: ICard) =>
                c.userID == currentUserId &&
                c.shared == true &&
                this.searchDescAndFields(c.title, c.description, c.fields)
            ) ?? []
          break
        // Private cards
        case '2':
          this.tabCards =
            toBeFilteredCards.filter(
              (c: ICard) =>
                c.shared == false &&
                this.searchDescAndFields(c.title, c.description, c.fields)
            ) ?? []
          break
        // No coparent cards
        case '3':
          this.tabCards =
            toBeFilteredCards.filter(
              (c: ICard) =>
                c.userID == currentUserId &&
                this.searchDescAndFields(c.title, c.description, c.fields)
            ) ?? []
          break
        // Coparent's cards
        case '4':
          this.tabCards =
            toBeFilteredCards.filter(
              (c: ICard) =>
                c.shared == true &&
                c.userID != currentUserId &&
                this.searchDescAndFields(c.title, c.description, c.fields)
            ) ?? []

          break
        default:
          this.tabCards =
            toBeFilteredCards.filter((c: ICard) =>
              this.searchDescAndFields(c.title, c.description, c.fields)
            ) ?? []
      }

      this.sortTabCards()
    },
    searchDescAndFields(
      title: string | null,
      description: string | null,
      cardFields: IField[]
    ): boolean {
      const lowercaseSearchTerm = this.searchTerm?.toLowerCase()

      if (lowercaseSearchTerm != null && lowercaseSearchTerm != '') {
        // Check Title
        if (title?.toLowerCase() && title.toLowerCase().includes(lowercaseSearchTerm)) {
          return true
        }
        // Check Description
        if (description?.toLowerCase() && description?.toLowerCase().includes(lowercaseSearchTerm)) {
          return true
        }
        // Check Fields
        if (cardFields) {
          if (
            cardFields.find((f) => f.content?.toLowerCase().includes(lowercaseSearchTerm ?? ''))
          ) {
            return true
          }
        }
        // No match in title, description, and fields
        return false
      } else {
        // No search term
        return true
      }
    },
    sortTabCards() {
      if (this.sortCardsBy != null && this.sortCardsBy != '') {
        const sortedCards = JSON.parse(JSON.stringify(this.tabCards))

        switch (this.sortCardsBy) {
          // Date Descending
          case '0':
          case '2':
            sortedCards.sort((a: ICard, b: ICard) => {
              const dateA = a.updatedWhen ?? a.createdWhen ?? new Date()
              const dateB = b.updatedWhen ?? b.createdWhen ?? new Date()
              return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
            })
            break
          // Date Ascending
          case '1':
            sortedCards.sort((a: ICard, b: ICard) => {
              const dateA = a.updatedWhen ?? a.createdWhen ?? new Date()
              const dateB = b.updatedWhen ?? b.createdWhen ?? new Date()
              return dateA < dateB ? -1 : dateA > dateB ? 1 : 0
            })
            break
          // Title A-Z
          case '3':
            sortedCards.sort((a: ICard, b: ICard) => {
              const titleA = a.title.toUpperCase()
              const titleB = b.title.toUpperCase()
              return titleA < titleB ? -1 : titleA > titleB ? 1 : 0
            })
            break
          // Title Z-A
          case '4':
            sortedCards.sort((a: ICard, b: ICard) => {
              const titleA = a.title.toUpperCase()
              const titleB = b.title.toUpperCase()
              return titleA > titleB ? -1 : titleA < titleB ? 1 : 0
            })
            break
          default:
            sortedCards.sort((a: ICard, b: ICard) => {
              const dateA = a.updatedWhen ?? a.createdWhen ?? new Date()
              const dateB = b.updatedWhen ?? b.createdWhen ?? new Date()
              return dateA < dateB ? -1 : dateA > dateB ? 1 : 0
            })
        }

        this.tabCards = sortedCards
      }
    },
    handleInfoLibraryNotifications(cardId: number) {
      this.fetchCards(true)

      const common = useCommonStore()
      common.fetchBadgeCounts()
      this.newCardAlert = { newCard: true, cardId: cardId }
    },
    setShowAddTab(show: boolean) {
      this.showAddTab = show
    },
    setShowAddCard(show: boolean) {
      this.showAddCard = show
    },
    setSearchTerm(term: string) {
      this.searchTerm = term
    },
    setFilterCardsBy(filter: string) {
      this.filterCardsBy = filter
    },
    setSortCardsBy(order: string) {
      this.sortCardsBy = order
    },
    setAddCardErrorMessage(message: string | null) {
      this.addCardErrorMessage = message
    },
    setShowEditCard(show: boolean) {
      this.showEditCard = show
    },
    setCardForEdit(card: ICard) {
      this.cardForEdit = card
    },
    setEditCardErrorMessage(message: string | null) {
      this.editCardErrorMessage = message
    },
    setAddTabErrorMessage(message: string | null) {
      this.addTabErrorMessage = message
    },
    setTabForEdit(tab: ITab) {
      this.tabForEdit = tab
    },
    setEditTabErrorMessage(message: string | null) {
      this.editTabErrorMessage = message
    },
    setSelectedCardID(cardID: number) {
      this.selectedCardID = cardID
    },
    setShowDeleteCard(show: boolean) {
      this.showDeleteCard = show
    },
    setDeleteCardErrorMessage(message: string | null) {
      this.deleteCardErrorMessage = message
    },
    setSelectedTabID(tabID: number) {
      this.selectedTabID = tabID
    },
    setShowDeleteTab(show: boolean) {
      this.showDeleteTab = show
    },
    setDeleteTabErrorMessage(message: string | null) {
      this.deleteTabErrorMessage = message
    },
    setShowUpgradePrompt(showUpgradePrompt: boolean) {
      this.showUpgradePrompt = showUpgradePrompt
    },
    setPlaceholderCards() {
      this.tabCards = this.placeholderCards
    },
    setCurrentTemplate(template: ITemplateCard | null) {
      this.currentTemplate = template
    }
  }
})
