<template>
  <div class="pre-check">
    <!-- wrong tier -->
    <div
      v-if="canCallStatus.get(constants.CAN_CALL_STATUS.notPremium)"
      class="call-error"
    >
      <font-awesome-icon 
        icon="fa-light fa-circle-exclamation"
        class="icon-size-1 flex-shrink-0"
      />
      <div class="flex-column align-left">
        <span>You must upgrade to a Premium Plan to make a recorded phone or video call</span>
        <button
          type="button"
          class="btn tertiary mr-auto"
          @click="goToPaywall"
        >   
          <strong class="purple">Upgrade your account</strong>
          <font-awesome-icon 
            icon="fa-light fa-arrow-right-long"
            class="icon-size-1 flex-shrink-0 purple"
          />
        </button>
      </div>
    </div>
    <!-- disabled phone calling -->
    <div
      v-if="canCallStatus.get(constants.CAN_CALL_STATUS.phoneCallingDisabled)"
      class="call-error"
    >
      <font-awesome-icon 
        icon="fa-light fa-circle-exclamation"
        class="icon-size-1 flex-shrink-0"
      />
      <div class="flex-column">
        <strong>Recorded phone calls are disabled</strong>
        <span>Enable it in your Accountable Calling Settings</span>
        <button
          type="button"
          class="btn tertiary mr-auto"
          @click="goToSettings"
        >       
          <strong class="purple"> Go to settings </strong>
          <font-awesome-icon 
            icon="fa-light fa-arrow-right-long"
            class="icon-size-1 flex-shrink-0 purple"
          />
        </button>
      </div>
    </div>
    <!-- coparent disabled calling -->
    <div
      v-if="canCallStatus.get(constants.CAN_CALL_STATUS.coParentIssue)"
      class="call-error"
    >
      <font-awesome-icon 
        icon="fa-light fa-circle-exclamation"
        class="icon-size-1 flex-shrink-0"
      />
      <span>Your co-parent has disabled phone calling</span>
    </div>
    <!-- need more minutes -->
    <div 
      v-if="canCallStatus.get(constants.CAN_CALL_STATUS.noMinutes)"
      class="call-error"
    >
      <font-awesome-icon 
        icon="fa-light fa-circle-exclamation"
        class="icon-size-1 flex-shrink-0"
      />
      <span>Not enough minutes to start a call</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject } from 'vue'
import { useRouter } from 'vue-router'
import { useCallingStore } from '@/stores/CallingStore'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { storeToRefs } from 'pinia'
import { br } from '@/plugins/trackerPlugin'
import constants from '@/exports/constants'

const track: any = inject('$trackingTrack')

const router = useRouter()
const callingStore = useCallingStore()
const { canCallStatus } = storeToRefs(callingStore)
const { setShowAudioCall } = callingStore

const accountSettingsStore = useAccountSettingsStore()
const { setPaywallPreviousPage } = accountSettingsStore

function goToPaywall() {
  track(br.eventTypes.appAction, { 
    feature: br.appActionFeature.calling,
    name: br.appActionEventNames.CTAUpgrade })

  setPaywallPreviousPage({ name: 'calling' })
  setShowAudioCall(false)
  router.push({ name: 'accountTypeStep1' })
}

function goToSettings() {
  setShowAudioCall(false)
  router.push({name: 'accountableCalling'})
}
</script>
<style scoped>
.pre-check {
  margin-top: var(--size-0);
  margin-bottom: var(--size-0);
}

.call-error {
  margin: var(--size-000) var(--size-1);
  padding: var(--size-00);
  border-radius: var(--radius-2);
  display: flex;
  gap: var(--size-00);
  align-items: baseline;
  box-shadow: var(--shadow-2);
}
.call-error button strong {
  --base-weight: 600;
  color: var(--purple)
}
[color-scheme='light'] {
  .call-error {
    background-color: var(--yellow-2);
  }
}

[color-scheme='dark'] {
  .call-error {
    background-color: var(--warning-light);
  }

  .purple {
    color: var(--purple-light)
  }

  .call-error button strong {
    --base-weight: 600;
  }
}
</style>