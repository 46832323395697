<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <label :for="name" class="inline-label">
    <input
      class="switch"
      type="checkbox"
      :name="name"
      :id="name"
      :value="value"
      v-model="value"
      :disabled="disabled"
    />
    <span class="font-weight-2 font-size-1">{{ label }}</span>
    <loading :loading="loading" class="spinner" />
  </label>
</template>
<script lang="ts" setup>
import { useField } from 'vee-validate'
import { toRef } from 'vue'
import Loading from '@/components/library/Loading.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String
  },
  successMessage: {
    type: String,
    default: ''
  },
  ariaDescribedby: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
})

// make sure to convert the name prop to a ref to maintain its reactivity
// this way vee-validate can react to the field name changing
const nameRef = toRef(props, 'name')
const modelValue = toRef(props, 'modelValue')
const loading = toRef(props, 'loading')
const disabled = toRef(props, 'disabled')
const { value, errorMessage, meta, handleBlur, handleChange } = useField(
  nameRef,
  undefined,
  {
    initialValue: modelValue,
    checkedValue: modelValue,
    syncVModel: true
  }
)
</script>
<style lang="scss" scoped>
.spinner {
  margin-right: unset !important;
  margin-right: unset !important;

  margin-top: unset !important;
  margin-top: unset !important;

  margin-bottom: unset !important;
  margin-bottom: unset !important;

  margin-left: -0.5rem !important;
  margin-left: -0.5rem !important;
}
</style>
