import { ColumnLayoutColumnState } from '@/models/enums.ts'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

export const useLayoutStore = defineStore('layout', () => {
  const numOfColumns = ref<number>(3)
  const columns = ref<ColumnLayoutColumnState[]>([
    ColumnLayoutColumnState.Visible,
    ColumnLayoutColumnState.Visible,
    ColumnLayoutColumnState.Visible
  ])
  const dividerWidthInPixels = ref<number>(2)

  function getDividerWidthInPixelsForColumn(column: number) {
    return visibleColumnIndicesIncludes(column) &&
      visibleColumnToRightOf(column)
      ? dividerWidthInPixels.value
      : 0
  }

  const visibleColumnIndices = computed(() => {
    const indices = [] as number[]

    columns.value.forEach((c, i) => {
      if (c != ColumnLayoutColumnState.Hidden) indices.push(i)
    })

    return indices
  })

  function visibleColumnIndicesIncludes(index: number) {
    return visibleColumnIndices.value.includes(index)
  }

  function visibleColumnToRightOf(column: number) {
    return columns.value.slice(column + 1).some((c) => c)
  }

  function setNumOfColumns(value: number) {
    numOfColumns.value = value
  }

  watch(numOfColumns, async (newVal, oldVal) => {
    if (newVal > oldVal) {
      columns.value.length = newVal
      columns.value.fill(ColumnLayoutColumnState.Visible, oldVal)

      return
    }

    columns.value.splice(newVal)
  })

  function setColumnState(column: number, value: ColumnLayoutColumnState) {
    columns.value[column] = value
  }

  function collapsibleColumnsContains(column: number) {
    return columns.value[column] == ColumnLayoutColumnState.Collapsible
    //return collapsibleColumns.value.includes(column)
  }

  function staticColumnsContains(column: number) {
    return columns.value[column] == ColumnLayoutColumnState.Static
    //return staticColumns.value.includes(column)
  }

  function isColumnStaticOrCollapsible(column: number) {
    return collapsibleColumnsContains(column) || staticColumnsContains(column)
  }

  function resetColumnProperties() {
    columns.value = Array(numOfColumns.value).fill(
      ColumnLayoutColumnState.Visible
    )
  }

  function isColumnVisible(column: number) {
    return visibleColumnIndices.value.includes(column)
  }

  function setDividerWidthInPixels(width: number) {
    dividerWidthInPixels.value = width
  }

  function hideColumn(column: number) {
    setColumnState(column, ColumnLayoutColumnState.Hidden)
  }

  function showColumn(column: number) {
    setColumnState(column, ColumnLayoutColumnState.Visible)
  }

  //only reset columns that are hidden
  function softResetColumns() {
    columns.value.forEach((c, i) => {
      if (!c) setColumnState(i, ColumnLayoutColumnState.Visible)
    })
  }

  function setVaultDesktopView() {
    softResetColumns()
    setColumnState(0, ColumnLayoutColumnState.Static)
  }

  function setVaultTabletView() {
    softResetColumns()
    setColumnState(0, ColumnLayoutColumnState.Static)
    setColumnState(2, ColumnLayoutColumnState.Hidden)
  }

  function setVaultMobileView() {
    softResetColumns()
    resetColumnProperties()

    setColumnState(0, ColumnLayoutColumnState.Hidden)
    setColumnState(2, ColumnLayoutColumnState.Hidden)
  }

  function setVaultItemSelectedView() {
    if (numOfColumns.value == 3) {
      // in vault page
      //item was selected in mobile or tablet view - hide second column, show third
      hideColumn(1)
      showColumn(2)
    } else if (numOfColumns.value == 2) {
      // in vaultpicker
      hideColumn(0)
      showColumn(1)
    }
  }

  function setVaultQuickAccessView() {
    hideColumn(1)
    showColumn(0)
  }

  function setVaultModalViewDesktop() {
    resetColumnProperties()
    setNumOfColumns(2)
  }

  async function setVaultModalViewMobile() {
    resetColumnProperties()
    setNumOfColumns(2)
    // in vaultpicker
    showColumn(0)
    hideColumn(1)
  }

  async function setVaultModalViewDetailsMobile() {
    resetColumnProperties()
    setNumOfColumns(2)
    // in vaultpicker
    showColumn(1)
    hideColumn(0)
  }

  function setCallingDetailViewMobile() {
    hideColumn(1)
    showColumn(2)
  }

  function setCallingDetailViewTablet() {
    hideColumn(1)
    showColumn(2)
  }

  return {
    numOfColumns,
    columns,
    visibleColumnIndices,
    dividerWidthInPixels,
    setNumOfColumns,
    setColumnState,
    collapsibleColumnsContains,
    isColumnVisible,
    setDividerWidthInPixels,
    hideColumn,
    showColumn,
    setVaultDesktopView,
    setVaultTabletView,
    setVaultMobileView,
    setVaultItemSelectedView,
    visibleColumnIndicesIncludes,
    getDividerWidthInPixelsForColumn,
    visibleColumnToRightOf,
    setVaultQuickAccessView,
    setVaultModalViewDesktop,
    setVaultModalViewMobile,
    setVaultModalViewDetailsMobile,
    setCallingDetailViewMobile,
    isColumnStaticOrCollapsible,
    setCallingDetailViewTablet,
    staticColumnsContains,
    resetColumnProperties,
    softResetColumns
  }
})
