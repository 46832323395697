import { defineStore } from 'pinia'
import { computed, toRef, watch } from 'vue'

import httpClient from '@/httpClient'

import ErrorHelper from '@/exports/error'

import {
  GetSharedVaultItemResponse,
  type IGetSharedVaultItemResponse,
  type IGetSharedVaultItemResult,
  type IFileParam,
  type INewFolderParam,
  type IVaultAttachmentParam,
  type IVaultJournalAttachmentParam,
  type IShareEmailParam,
  FileParam
} from '@/models/models'
import { ref } from 'vue'
import type {
  IVaultItem,
  IGetVaultItemsParam,
  IGetVaultShareLinkParam,
  INewFolderResponse,
  IUploadTokenResponse,
  IVaultUpload,
  IVaultFolder,
  IBreadcrumbEvent,
  IVaultDownload,
  WorkerRequest,
  IVaultQuotaInfo,
  IThumbnailFetch,
  IGetRecentVaultItemsParam,
  IMoveVaultItemsParam,
  ISearchFilterSortVaultItemsParam,
  ITrashOrRestoreVaultItemsParam,
  IStarVaultItemsParam,
  ITrackingTrackFunction
} from '@/models/interfaces'
import { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios'
import axios from 'axios'
import {
  HttpStatusCodes,
  VaultColumnFilter,
  VaultListViewMode,
  VaultSortBy,
  VaultThumbnailStatus
} from '@/models/enums'
import constants from '@/exports/constants'
import moment from 'moment'
import { useCommonStore } from './CommonStore'
import { watchIgnorable } from '@vueuse/core'

import WorkerService from '@/services/webWorkerService'
import { useLoginStore } from '@/stores/LoginStore'
import { useModals } from '@/composables/useModal/useModal'
import fileDownloaderWorker from '../workers/fileDownloader?worker&url'
import thumbnailFetcherWorker from '../workers/thumbnailFetcher?worker&url'
import { useI18n } from 'vue-i18n'

import { br } from '@/plugins/trackerPlugin'
import { inject } from 'vue'

const baseUrl = '/mobile/api/vault'

const downloadControllerBaseUrl = `${baseUrl}/download`
const fileControllerBaseUrl = `${baseUrl}/file`
const folderControllerBaseUrl = `${baseUrl}/folder`
const uploadTokenControllerBaseUrl = `${baseUrl}/uploadtoken`
const vaultAccountControllerBaseUrl = 'mobile/api/vaultaccount'
const vaultAttachmentControllerBaseUrl = `${baseUrl}/attachment`
const vaultItemControllerBaseUrl = `${baseUrl}/item`
const vaultJournalAttachmentControllerBaseUrl = `${baseUrl}/journalattachment`
const vaultShareControllerBaseUrl = `${baseUrl}/share`

export const useVaultStore = defineStore('vault', () => {
  //#region share vault

  const { t } = useI18n({ useScope: 'global' })

  const apiError = ref<string | null>(null)
  const sharedVaultItem = ref<IGetSharedVaultItemResponse | null>(null)

  async function setApiError(payload: string | null) {
    apiError.value = payload
  }

  async function setSharedVaultItem(payload: IGetSharedVaultItemResponse) {
    sharedVaultItem.value = payload
  }

  async function fetchSharedVaultItem(itemGuid: string) {
    setApiError(null)

    let response: IGetSharedVaultItemResult = {
      success: false,
      errorCode: undefined,
      errorMessage: undefined,
      value: new GetSharedVaultItemResponse()
    }

    try {
      response = (
        await httpClient.get(
          `/web/api/Vault/GetSharedVaultItem?itemGuid=${itemGuid}`
        )
      ).data

      if (!response.success) {
        throw new Error(response.errorMessage || '')
      }
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchSharedVaultItem')
    }

    setSharedVaultItem(response.value || new GetSharedVaultItemResponse())
  }
  //#endregion share vault

  //#region vault feature
  const track = inject<ITrackingTrackFunction>(
    '$trackingTrack'
  ) as ITrackingTrackFunction

  const vaultItems = ref<IVaultItem[]>([
    {
      itemID: 0,
      userID: 0,
      blobName: '',
      name: '',
      description: '',
      fileSize: 0,
      isFolder: false,
      createdDate: new Date(),
      isStarred: false,
      isTrashed: false,
      mimeType: '',
      hasThumbnail: false,
      isDeleted: false,
      isSubTrashed: false,
      skeletonLoading: true
    },
    {
      itemID: 0,
      userID: 0,
      blobName: '',
      name: '',
      description: '',
      fileSize: 0,
      isFolder: false,
      createdDate: new Date(),
      isStarred: false,
      isTrashed: false,
      mimeType: '',
      hasThumbnail: false,
      isDeleted: false,
      isSubTrashed: false,
      skeletonLoading: true
    },
    {
      itemID: 0,
      userID: 0,
      blobName: '',
      name: '',
      description: '',
      fileSize: 0,
      isFolder: false,
      createdDate: new Date(),
      isStarred: false,
      isTrashed: false,
      mimeType: '',
      hasThumbnail: false,
      isDeleted: false,
      isSubTrashed: false,
      skeletonLoading: true
    }
  ])
  const pageSize = ref<number>(25)
  const loadMoreVaultItems = ref<boolean>(false)
  const vaultItemAttachments = ref<IVaultItem[]>([])
  const selectedItemsList = ref<IVaultItem[] | null>(null)
  const draggingVaultItem = ref<IVaultItem | null>(null)
  const hoveringOverVaultItem = ref<IVaultItem | null>(null)
  const currentFolderLevel = ref<number | null>(null)
  const multiSelectMode = ref<boolean>(false)

  const currentFolder = ref<IVaultItem | null>(null)

  const selectedVaultSortBy = ref<VaultSortBy>(VaultSortBy.CreatedDateDesc)
  const vaultListViewMode = ref<VaultListViewMode>(VaultListViewMode.List)
  const searchTerm = ref<string>('')
  const treeData = ref<Array<IVaultFolder>>()
  const downloads = ref<IVaultDownload[]>([])
  const fetchedThumbnails = ref<IThumbnailFetch[]>([])
  const editingItem = ref<boolean>(false)
  const selectedVaultColumnFilter = ref<VaultColumnFilter>(
    VaultColumnFilter.All
  )
  const quotaAccountInfo = ref<IVaultQuotaInfo | null>(null)
  const uploads = ref<IVaultUpload[]>([])
  const upload = ref<IVaultUpload | null>(null)
  const undoItems = ref<IVaultItem[] | null>(null)
  const thumbnailWorkerIds = ref<string[]>([])

  function reset() {
    apiError.value = null
    sharedVaultItem.value = null
    vaultItems.value = [
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      }
    ]
    pageSize.value = 25
    loadMoreVaultItems.value = false
    vaultItemAttachments.value = []
    selectedItemsList.value = null
    draggingVaultItem.value = null
    hoveringOverVaultItem.value = null
    currentFolderLevel.value = null
    multiSelectMode.value = false
    currentFolder.value = null
    selectedVaultSortBy.value = VaultSortBy.CreatedDateDesc
    vaultListViewMode.value = VaultListViewMode.List
    searchTerm.value = ''
    treeData.value = []
    downloads.value = []
    fetchedThumbnails.value = []
    editingItem.value = false
    selectedVaultColumnFilter.value = VaultColumnFilter.All
    quotaAccountInfo.value = null
    uploads.value = []
    upload.value = null
    vaultItems.value = [
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      }
    ]
    selectedVaultColumnFilter.value = VaultColumnFilter.All
  }

  watch(
    () => vaultItems.value,
    async (val) => {
      if (Array.isArray(val) && val.length > 0) {
        const commonStore = useCommonStore()
        commonStore.setShowingNoCount(false)
      } else if (
        selectedVaultColumnFilter.value == VaultColumnFilter.All &&
        !searchTerm.value.length &&
        currentFolderLevel.value == null
      ) {
        const commonStore = useCommonStore()
        commonStore.setShowingNoCount(true)
      }
    }
  )

  const getCurrentFolderId = computed(() => {
    if (!selectedItemsList.value) {
      return
    }

    const selectedItem = selectedItemsList.value[0]

    if (!selectedItem) {
      return
    }

    return selectedItem.isFolder
      ? selectedItemsList.value[0].itemID
      : selectedItemsList.value[0]?.parentItemID
  })

  const selectedVaultSortByColumn = computed(() => {
    switch (selectedVaultSortBy.value) {
      case VaultSortBy.NameAsc:
        return 'Name'
      case VaultSortBy.NameDesc:
        return 'Name'
      case VaultSortBy.CreatedDateAsc:
        return 'CreatedDate'
      case VaultSortBy.CreatedDateDesc:
        return 'CreatedDate'
      case VaultSortBy.FileSizeAsc:
        return 'FileSize'
      case VaultSortBy.FileSizeDesc:
        return 'FileSize'
      default:
        return 'CreatedDate'
    }
  })

  const selectedVaultSortByDirection = computed(() => {
    switch (selectedVaultSortBy.value) {
      case VaultSortBy.NameAsc:
        return 'ASC'
      case VaultSortBy.NameDesc:
        return 'DESC'
      case VaultSortBy.CreatedDateAsc:
        return 'ASC'
      case VaultSortBy.CreatedDateDesc:
        return 'DESC'
      case VaultSortBy.FileSizeAsc:
        return 'ASC'
      case VaultSortBy.FileSizeDesc:
        return 'DESC'
      default:
        return 'ASC'
    }
  })

  const skeletonLoading = computed(
    () => !!vaultItems.value.find((vi) => vi.skeletonLoading)
  )

  function resetVaultItems() {
    vaultItems.value = [
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      }
    ]
    fetchedThumbnails.value = []
  }

  function setLoadMoreVaultItems(loadMore: boolean) {
    loadMoreVaultItems.value = loadMore
  }

  function setSelectedVaultSortBy(sortBy: VaultSortBy) {
    selectedVaultSortBy.value = sortBy
  }

  function setVaultListViewMode(viewMode: VaultListViewMode) {
    vaultListViewMode.value = viewMode
  }

  function setSearchTerm(term: string) {
    searchTerm.value = term
  }

  async function setTreeData(payload: Array<IVaultFolder>) {
    treeData.value = { ...payload }
  }

  async function setVaultColumnFilter(payload: VaultColumnFilter) {
    selectedVaultColumnFilter.value = payload
  }

  function setvaultItemAttachments(attachmentList: IVaultItem[]) {
    vaultItemAttachments.value = attachmentList
  }

  function setSelectedItemsList(selectedList: IVaultItem[] | null) {
    selectedItemsList.value = selectedList
  }

  function setUndoItems(items: IVaultItem[] | null) {
    undoItems.value = items
  }

  function setCurrentFolderLevel(id: number | null) {
    currentFolderLevel.value = id
  }

  function setMultiSelectMode(mode: boolean) {
    multiSelectMode.value = mode
  }

  function setCurrentFolder(folder: IVaultItem | null) {
    currentFolder.value = folder
  }

  function pushVaultItemToAttachments(item: IVaultItem) {
    if (vaultItemAttachments.value) {
      if (vaultItemAttachments.value.find((f) => f.itemID === item.itemID)) {
        return false
      }
      vaultItemAttachments.value?.push(item)
    } else {
      vaultItemAttachments.value = new Array(item)
    }

    return true
  }

  function removeVaultItemFromAttachments(itemID: number) {
    if (vaultItemAttachments.value) {
      const index = getVaultItemAttachmentIndex(itemID)
      if (index != undefined) {
        vaultItemAttachments.value.splice(index, 1)
      }
    }
  }

  function getVaultItemAttachmentIndex(itemID: number) {
    if (vaultItemAttachments.value) {
      const index = vaultItemAttachments.value.findIndex(
        (f) => f.itemID === itemID
      )
      return index
    } else {
      return -1
    }
  }

  function pushVaultItemToSelectedList(item: IVaultItem) {
    if (selectedItemsList.value) {
      if (selectedItemsList.value.find((f) => f.itemID === item.itemID)) {
        return false
      }
      selectedItemsList.value?.push(item)
      return true
    }

    selectedItemsList.value = new Array(item)
    return true
  }

  function removeVaultItemFromSelectedList(itemID: number) {
    if (selectedItemsList.value) {
      const index = getVaultItemSelectedListIndex(itemID)
      if (index != undefined) {
        selectedItemsList.value.splice(index, 1)
      }
    }
  }

  function getVaultItemSelectedListIndex(itemID: number) {
    if (selectedItemsList.value) {
      const index = selectedItemsList.value.findIndex(
        (f) => f.itemID === itemID
      )
      return index
    }

    return -1
  }

  async function navigateIntoFolder(folderItem?: IVaultItem) {
    setLoadMoreVaultItems(false)
    terminateAllThumbnailWorkers()
    setCurrentFolder(folderItem ?? null)
    resetVaultItems()
    setSearchTerm('')
    setSelectedItemsList(null)
    ignoreSelectedVaultColumnFilterUpdates(() =>
      setVaultColumnFilter(VaultColumnFilter.All)
    )
    setCurrentFolderLevel(folderItem?.itemID ?? null)

    if (treeData.value) {
      updateBreadcrumbs()
    } else {
      await updateFolderTree()
      updateBreadcrumbs()
    }
    const fetchedItems = (
      await searchFilterSortDirectory({
        parentItemID: folderItem?.itemID ? folderItem?.itemID : null,
        pageNumber: 1,
        pageSize: 25,
        sortColumn: selectedVaultSortByColumn.value,
        sortDirection: selectedVaultSortByDirection.value,
        prioritizeStarred: selectedVaultSortBy.value == VaultSortBy.Starred
      })
    )?.data as IVaultItem[]

    setVaultItems(fetchedItems)
    setLoadMoreVaultItems(fetchedItems.length == pageSize.value)
  }

  // assumes we're in the correct folder
  async function updateCurrentFolder(
    parentItemID: number,
    pageNumber?: number
  ) {
    setLoadMoreVaultItems(false)

    if (!pageNumber || pageNumber == 1) {
      terminateAllThumbnailWorkers()
      resetVaultItems()
    }

    const fetchedItems = (
      await searchFilterSortDirectory({
        parentItemID: parentItemID ? parentItemID : null,
        pageNumber: pageNumber ? pageNumber : 1,
        pageSize: 25,
        sortColumn: selectedVaultSortByColumn.value,
        sortDirection: selectedVaultSortByDirection.value,
        prioritizeStarred: selectedVaultSortBy.value == VaultSortBy.Starred
      })
    )?.data as IVaultItem[]

    setLoadMoreVaultItems(fetchedItems.length == pageSize.value)

    if (pageNumber && pageNumber > 1) {
      setVaultItems([...vaultItems.value, ...fetchedItems])
    } else {
      setVaultItems(fetchedItems)
    }
  }

  async function fetchSearchResults(pageNumber?: number) {
    setLoadMoreVaultItems(false)

    if (!pageNumber || pageNumber == 1) {
      terminateAllThumbnailWorkers()
      resetVaultItems()
    }

    const fetchedItems = (
      await searchFilterSortAll({
        pageNumber: pageNumber ? pageNumber : 1,
        pageSize: 25,
        sortColumn: selectedVaultSortByColumn.value,
        sortDirection: selectedVaultSortByDirection.value,
        prioritizeStarred: selectedVaultSortBy.value == VaultSortBy.Starred,
        searchTerm: searchTerm.value
      })
    )?.data as IVaultItem[]

    setLoadMoreVaultItems(fetchedItems.length == pageSize.value)

    if (pageNumber && pageNumber > 1) {
      setVaultItems([...vaultItems.value, ...fetchedItems])
    } else {
      setVaultItems(fetchedItems)
    }
  }

  const { ignoreUpdates: ignoreSelectedVaultSortByUpdates } = watchIgnorable(
    () => selectedVaultSortBy.value,
    async () => {
      if (selectedVaultColumnFilter.value == VaultColumnFilter.All) {
        await updateCurrentFolder(currentFolderLevel.value ?? 0)
        return
      }

      setCurrentFolder(null)
      setCurrentFolderLevel(null)
      setSelectedItemsList(null)

      // refetch search term new sort
      if (searchTerm.value.length) {
        await fetchSearchResults(1)
        return
      }

      // refetch list with new sort
      await fetchFilteredItems(selectedVaultColumnFilter.value)
    }
  )

  const { ignoreUpdates: ignoreSelectedVaultColumnFilterUpdates } =
    watchIgnorable(
      () => selectedVaultColumnFilter.value,
      async (val) => {
        if (
          (val == VaultColumnFilter.Starred ||
            val == VaultColumnFilter.Trashed) &&
          selectedVaultSortBy.value == VaultSortBy.Starred
        ) {
          ignoreSelectedVaultSortByUpdates(() =>
            setSelectedVaultSortBy(VaultSortBy.CreatedDateDesc)
          )
        } else if (
          val == VaultColumnFilter.Folders &&
          (selectedVaultSortBy.value == VaultSortBy.FileSizeAsc ||
            selectedVaultSortBy.value == VaultSortBy.FileSizeDesc)
        ) {
          ignoreSelectedVaultSortByUpdates(() =>
            setSelectedVaultSortBy(VaultSortBy.CreatedDateDesc)
          )
        }

        if (val == VaultColumnFilter.All) {
          await navigateIntoFolder(undefined)
          return
        }

        setCurrentFolder(null)
        setCurrentFolderLevel(null)
        setSelectedItemsList(null)
        updateBreadcrumbs()
        await fetchFilteredItems(val)
      }
    )

  watch(selectedItemsList, (val) => {
    if (!val?.length) {
      setMultiSelectMode(false)
    }
  })

  async function fetchFilteredItems(
    filter: VaultColumnFilter,
    pageNumber?: number
  ) {
    let fetchedItems: IVaultItem[] = []

    if (!pageNumber || pageNumber == 1) {
      terminateAllThumbnailWorkers()
      resetVaultItems()
    }

    switch (filter) {
      case VaultColumnFilter.Starred: {
        const starred = (
          await searchFilterSortAll({
            pageNumber: pageNumber ?? 1,
            pageSize: pageSize.value,
            sortColumn: selectedVaultSortByColumn.value,
            sortDirection: selectedVaultSortByDirection.value,
            starredOnly: true
          })
        )?.data as IVaultItem[]

        fetchedItems = starred
        break
      }
      case VaultColumnFilter.Trashed: {
        const trashed = (
          await searchFilterSortAll({
            pageNumber: pageNumber ?? 1,
            pageSize: pageSize.value,
            sortColumn: selectedVaultSortByColumn.value,
            sortDirection: selectedVaultSortByDirection.value,
            inTrash: true
          })
        )?.data as IVaultItem[]

        setSelectedItemsList(null)
        fetchedItems = trashed
        setCurrentFolder(null)
        break
      }
      case VaultColumnFilter.Files: {
        const files = (
          await searchFilterSortAll({
            pageNumber: pageNumber ?? 1,
            pageSize: pageSize.value,
            sortColumn: selectedVaultSortByColumn.value,
            sortDirection: selectedVaultSortByDirection.value,
            foldersOnly: false,
            prioritizeStarred: selectedVaultSortBy.value == VaultSortBy.Starred
          })
        )?.data as IVaultItem[]

        fetchedItems = files
        break
      }
      case VaultColumnFilter.Folders: {
        const folders = (
          await searchFilterSortAll({
            pageNumber: pageNumber ?? 1,
            pageSize: pageSize.value,
            sortColumn: selectedVaultSortByColumn.value,
            sortDirection: selectedVaultSortByDirection.value,
            foldersOnly: true,
            prioritizeStarred: selectedVaultSortBy.value == VaultSortBy.Starred
          })
        )?.data as IVaultItem[]

        fetchedItems = folders
        break
      }
      case VaultColumnFilter.Recent: {
        const files = (
          await searchFilterSortAll({
            pageNumber: pageNumber ?? 1,
            pageSize: pageSize.value,
            sortColumn: selectedVaultSortByColumn.value,
            sortDirection: selectedVaultSortByDirection.value,
            prioritizeStarred: selectedVaultSortBy.value == VaultSortBy.Starred,
            rangeStart: moment
              .utc()
              .subtract(60, 'days')
              .format('YYYY-MM-DDTHH:mm:ss')
          })
        )?.data as IVaultItem[]

        fetchedItems = files
        break
      }
      case VaultColumnFilter.Images: {
        const images = (
          await searchFilterSortAll({
            pageNumber: pageNumber ?? 1,
            pageSize: pageSize.value,
            sortColumn: selectedVaultSortByColumn.value,
            sortDirection: selectedVaultSortByDirection.value,
            prioritizeStarred: selectedVaultSortBy.value == VaultSortBy.Starred,
            mimeTypeSearch: 'image'
          })
        )?.data as IVaultItem[]

        fetchedItems = images
        break
      }
      case VaultColumnFilter.Videos: {
        const videos = (
          await searchFilterSortAll({
            pageNumber: pageNumber ?? 1,
            pageSize: pageSize.value,
            sortColumn: selectedVaultSortByColumn.value,
            sortDirection: selectedVaultSortByDirection.value,
            prioritizeStarred: selectedVaultSortBy.value == VaultSortBy.Starred,
            mimeTypeSearch: 'video'
          })
        )?.data as IVaultItem[]

        fetchedItems = videos
        break
      }
    }

    setLoadMoreVaultItems(fetchedItems.length == pageSize.value)

    if (pageNumber && pageNumber > 1) {
      setVaultItems([...vaultItems.value, ...fetchedItems])
    } else {
      setVaultItems(fetchedItems)
    }
  }

  async function handleSearchTermUpdate(term: string) {
    if (!term.length) {
      navigateIntoFolder(undefined)
      return
    } else {
      setSearchTerm(term)
      terminateAllThumbnailWorkers()
      setSelectedItemsList(null)
      setCurrentFolder(null)
      ignoreSelectedVaultColumnFilterUpdates(() =>
        setVaultColumnFilter(VaultColumnFilter.All)
      )
      updateBreadcrumbs()
      resetVaultItems()
    }

    await fetchSearchResults(1)
  }

  async function handleTrashFileOrfolder(item: IVaultItem) {
    let trashResult = false
    // trash item / folder
    if (item.isFolder) {
      trashResult =
        (await folderTrash(item.itemID))?.status == HttpStatusCodes.OK
      if (trashResult) {
        await updateFolderTree()
      }
    } else {
      trashResult = (await fileTrash(item.itemID))?.status == HttpStatusCodes.OK
    }

    // todo: add undo
    if (trashResult) {
      setSelectedItemsList(null)
      if (currentFolderLevel.value == item.itemID && treeData.value) {
        const folder = findFolderPath(treeData.value, item)?.pop()
        await navigateIntoFolder(folder as IVaultItem)
      } else {
        await removeVaultItemFromList(item.itemID)
      }
    }
    // todo: error message??
  }

  function isThumbableType(extension: string) {
    return constants.thumbableTypes.some((t) => t == extension)
  }

  function setDraggingVaultItem(item: IVaultItem | null) {
    draggingVaultItem.value = item
  }

  function setHoveringOverVaultItem(item: IVaultItem | null) {
    hoveringOverVaultItem.value = item
  }

  function setVaultItems(items: IVaultItem[]) {
    vaultItems.value = items
  }

  function getVaultItemFromListByItemID(itemID: number) {
    return vaultItems.value.find((v) => v.itemID == itemID)
  }

  function removeVaultItemFromList(itemID: number) {
    vaultItems.value = vaultItems.value.filter((i) => i.itemID != itemID)
  }

  function removeVaultItemsFromList(itemIds: number[]) {
    vaultItems.value = vaultItems.value.filter(
      (i) => !itemIds.includes(i.itemID)
    )
  }

  function setEditingItem(editing: boolean) {
    editingItem.value = editing
  }

  function getCurrentExpireTimeInTicks(linkExpireInDays: number): number {
    // moment object returns current date in milliseconds
    const TICKS_PER_MILLISECOND = 10000
    // js ticks start at 01/01/1970, c# ticks start at 01/01/0001 -- this offset is added to get accurate utc timestamp in c#
    const JS_DATE_TO_C_SHARP_TICKS_OFFSET = 621355968000000000

    // when never is selected on the mobile app the expire time is actually 5 years (see: TPXF/Pages/Vault)
    const expireTimeInUTCMoment =
      linkExpireInDays > 0
        ? moment().utc().add(linkExpireInDays, 'd')
        : moment().utc().add(5, 'y')

    // this works !! but, beatodo: look into why i had to convert to JS Date cuz i forgor the reason
    return (
      expireTimeInUTCMoment.toDate().getTime() * TICKS_PER_MILLISECOND +
      JS_DATE_TO_C_SHARP_TICKS_OFFSET
    )
  }

  function handleThumbnailGeneratedNotification(itemId: number) {
    updateThumbnailStatusForItem(itemId)
    updateThumbnailStatusForItem(itemId, VaultThumbnailStatus.Generated)
  }

  function fileNameIncludesRestrictedChars(
    fileName: string,
    showError?: boolean
  ) {
    const commonStore = useCommonStore()

    const _char = commonStore.appSettings?.attachmentRestrictedFilenameChars
      ? [...commonStore.appSettings.attachmentRestrictedFilenameChars]
      : []
    for (let i = 0; i < _char.length; i++) {
      if (fileName.includes(_char[i].trim())) {
        if (showError) {
          showUploadErrorModal(
            `One or more of your files has an invalid file name. File names should not contain any of the following characters: ${commonStore.appSettings?.attachmentRestrictedFilenameChars}`
          )
        }
        return true
      }
    }

    return false
  }

  function isFileExtensionInvalid(fileName: string) {
    const commonStore = useCommonStore()

    const restrictedExtensions = commonStore.appSettings
      ?.attachmentRestrictedFileExtensions
      ? commonStore.appSettings.attachmentRestrictedFileExtensions.split(',')
      : []

    for (let i = 0; i < restrictedExtensions.length; i++) {
      if (fileName.includes(restrictedExtensions[i].trim())) {
        return true
      }
    }
    return false
  }

  // confirm delete modal
  function confirmDeleteAll() {
    const { closeModal, generateModal } = useModals()
    const uploadString = t('vault.emptyTrashModal.content')
    const loading = ref<boolean>(false)

    console.log('uploadString: ', uploadString)

    const el = generateModal({
      default: {
        headerText: t('vault.emptyTrashModal.header'),
        contentText: uploadString,
        footerButtonLabel: t('vault.emptyTrashModal.emptyButton')
      },
      config: {
        footerButtonLoading: loading,
        showHeader: true,
        showFooter: true,
        showCloseButton: true,
        showSecondaryCTA: true,
        secondaryCTALabel: t('vault.emptyTrashModal.cancelButton'),
        footerStyle: 'flex-reverse gap-3',
        closeOnLoadEnd: true,
        closeOnConfirm: false
      },
      callback: {
        confirmFn: async () => {
          loading.value = true
          await deleteAll()
          terminateAllThumbnailWorkers()
          await fetchFilteredItems(VaultColumnFilter.Trashed)
          await updateVaultQuota()
          loading.value = false
        },
        secondaryFn: () => {
          closeModal(el)
        }
      }
    })
  }
  //#endregion vault feature

  //#region DownloadController
  async function fetchDownloadUrl(
    vaultItemID: number
  ): Promise<string | undefined> {
    const endpoint = `${downloadControllerBaseUrl}/getdownloadurl`

    try {
      const result = await httpClient.get(endpoint, {
        params: { vaultItemID }
      })
      return result.data
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchDownloadUrl')
    }
  }
  async function fetchDownloadUrls(
    vaultItemIds: number[]
  ): Promise<string[] | undefined> {
    const endpoint = `${downloadControllerBaseUrl}/getdownloadurls`

    try {
      const result = await httpClient.post(endpoint, vaultItemIds)

      return result.data
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchDownloadUrls')
    }
  }
  //#endregion DownloadController

  //#region FileController
  async function filePost(p: IFileParam) {
    const endpoint = fileControllerBaseUrl

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status == 400) {
        ErrorHelper.handleError(e, 'filePost', true, e.response.data.Message)
      } else {
        ErrorHelper.handleError(e, 'filePost')
      }
    }
  }

  async function fetchFileItems(p: IGetVaultItemsParam) {
    let endpoint = `${fileControllerBaseUrl}/getFileItems?PageNumber=${p.pageNumber}&PageSize=${p.pageSize}`
    if (p.parentItemID) endpoint += `&ParentItemID=${p.parentItemID}`
    if (p.sortColumn) endpoint += `&SortColumn=${p.sortColumn}`
    if (p.sortDirection) endpoint += `&SortDirection=${p.sortDirection}`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchFileItems')
    }
  }

  async function fetchAllFileItems(p: IGetVaultItemsParam) {
    let endpoint = `${fileControllerBaseUrl}/getAllFileItems?PageNumber=${p.pageNumber}&PageSize=${p.pageSize}`
    if (p.sortColumn) endpoint += `&SortColumn=${p.sortColumn}`
    if (p.sortDirection) endpoint += `&SortDirection=${p.sortDirection}`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchAllFileItems')
    }
  }

  async function fetchRecentItems(p: IGetRecentVaultItemsParam) {
    let endpoint = `${fileControllerBaseUrl}/getRecentItems?PageNumber=${p.pageNumber}&PageSize=${p.pageSize}`

    if (p.parentItemID) {
      endpoint += `&ParentItemID=${p.parentItemID}`
    }
    if (p.sortColumn) {
      endpoint += `&SortColumn=${p.sortColumn}`
    }
    if (p.sortDirection) {
      endpoint += `&SortDirection=${p.sortDirection}`
    }

    endpoint += `&RangeStart=${p.rangeStart.format('YYYY-MM-DDTHH:mm:ss')}`

    if (p.rangeEnd) {
      endpoint += `&RangeEnd=${p.rangeEnd.format('YYYY-MM-DDTHH:mm:ss')}`
    }

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchAllFileItems')
    }
  }

  async function fetchImageItems(p: IGetVaultItemsParam) {
    let endpoint = `${fileControllerBaseUrl}/getImageItems?PageNumber=${p.pageNumber}&PageSize=${p.pageSize}`

    if (p.parentItemID) {
      endpoint += `&ParentItemID=${p.parentItemID}`
    }
    if (p.sortColumn) {
      endpoint += `&SortColumn=${p.sortColumn}`
    }
    if (p.sortDirection) {
      endpoint += `&SortDirection=${p.sortDirection}`
    }

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchAllFileItems')
    }
  }

  async function fetchVideoItems(p: IGetVaultItemsParam) {
    let endpoint = `${fileControllerBaseUrl}/getVideoItems?PageNumber=${p.pageNumber}&PageSize=${p.pageSize}`

    if (p.parentItemID) {
      endpoint += `&ParentItemID=${p.parentItemID}`
    }
    if (p.sortColumn) {
      endpoint += `&SortColumn=${p.sortColumn}`
    }
    if (p.sortDirection) {
      endpoint += `&SortDirection=${p.sortDirection}`
    }

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchAllFileItems')
    }
  }

  async function fileTrash(itemID: number) {
    const endpoint = `${fileControllerBaseUrl}/trash?itemID=${itemID}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fileTrash')
    }
  }

  async function fileRestore(itemID: number) {
    const endpoint = `${fileControllerBaseUrl}/restore?itemId=${itemID}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fileRestore')
    }
  }

  async function fileDelete(itemID: number) {
    const endpoint = `${fileControllerBaseUrl}/delete?itemId=${itemID}`

    try {
      return await httpClient.delete(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fileDelete')
    }
  }

  async function trashOrRestoreVaultItems(p: ITrashOrRestoreVaultItemsParam) {
    const endpoint = `${vaultItemControllerBaseUrl}/trashOrRestoreItems`

    try {
      return await httpClient.put(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'trashOrRestoreVaultItems')
    }
  }

  async function deleteItems(itemIds: number[]) {
    const endpoint = `${vaultItemControllerBaseUrl}/deleteItems`

    try {
      return await httpClient.post(endpoint, itemIds)
    } catch (e) {
      ErrorHelper.handleError(e, 'deleteItems')
    }
  }

  async function getOrCreateThumbnailSasUrl(
    itemID: number,
    width?: number,
    height?: number
  ) {
    let endpoint = `${fileControllerBaseUrl}/getOrCreateThumbnailSasUrl?itemId=${itemID}`
    if (width) endpoint += `&width=${width}`
    if (height) endpoint += `&height=${height}`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getOrCreateThumbnailSasUrl')
    }
  }
  //#endregion FileController

  //#region FolderController
  async function folderPost(
    p: INewFolderParam
  ): Promise<AxiosResponse<INewFolderResponse, unknown> | undefined> {
    const endpoint = folderControllerBaseUrl

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'folderPost')
    }
  }

  async function fetchSubFolders(itemId?: number, bIncludeTrashed = false) {
    const endpoint = `${folderControllerBaseUrl}/getsubfolders`

    try {
      return await httpClient.get(endpoint, {
        params: { itemId, bIncludeTrashed }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchSubFolders')
    }
  }

  async function getAllFolders() {
    const endpoint = `${folderControllerBaseUrl}/getAllFolders`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getAllFolders')
    }
  }

  async function folderTrash(itemId: number) {
    const endpoint = `${folderControllerBaseUrl}/trash?itemId=${itemId}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'folderTrash')
    }
  }

  async function folderRestore(itemId: number) {
    const endpoint = `${folderControllerBaseUrl}/restore?itemId=${itemId}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'folderRestore')
    }
  }

  async function folderDelete(itemId: number) {
    const endpoint = `${folderControllerBaseUrl}/delete?itemId=${itemId}`

    try {
      return await httpClient.delete(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'folderDelete')
    }
  }

  // FOLDER HELPERS
  async function updateFolderTree() {
    const result = await getAllFolders()
    if (result?.data) {
      const tree: IVaultFolder[] = []

      buildTree(tree, result.data, null)

      await setTreeData(tree)
    }
  }

  function buildTree(
    treeObject: IVaultFolder[],
    sourceArray: IVaultFolder[],
    level: number | undefined | null
  ) {
    const nodes = sourceArray.filter((item) => item.parentItemID == level)

    for (const node of nodes) {
      node.children = []

      buildTree(node.children, sourceArray, node.itemID)

      treeObject.push(node)
    }
  }

  function findFolderPath(
    sourceTree: IVaultFolder[],
    targetVaultItem: IVaultItem
  ): Array<IVaultFolder> | null {
    const targetID = targetVaultItem.parentItemID

    for (const node in sourceTree) {
      if (sourceTree[node].itemID === targetID) {
        return [sourceTree[node]]
      }

      if (sourceTree[node].children) {
        const leaf = findFolderPath(
          sourceTree[node].children as IVaultFolder[],
          targetVaultItem
        )
        if (leaf) return [sourceTree[node], ...leaf]
      }
    }

    return null
  }

  function updateBreadcrumbs() {
    const commonStore = useCommonStore()
    const { setBreadcrumbs } = commonStore
    if (treeData.value && currentFolder.value) {
      const breadcrumbs = findFolderPath(
        treeData.value,
        currentFolder.value
      )?.map((folder) => ({
        crumbName: folder.name,
        breadcrumbData: { ...folder, isFolder: true }
      }))
      if (breadcrumbs) {
        setBreadcrumbs([
          { crumbName: 'Vault File Storage' },
          ...(breadcrumbs as IBreadcrumbEvent[]),
          {
            crumbName: currentFolder.value.name,
            breadcrumbData: currentFolder.value
          }
        ])
      } else if (
        commonStore.isMobileWidth &&
        selectedItemsList.value?.length == 1
      ) {
        setBreadcrumbs([
          { crumbName: 'Vault File Storage' },
          {
            crumbName: currentFolder.value.name,
            breadcrumbData: currentFolder.value
          },
          {
            crumbName: selectedItemsList.value[0].name,
            breadcrumbData: selectedItemsList.value[0]
          }
        ])
      } else {
        setBreadcrumbs([
          { crumbName: 'Vault File Storage' },
          {
            crumbName: currentFolder.value.name,
            breadcrumbData: currentFolder.value
          }
        ])
      }
    } else {
      setBreadcrumbs([{ crumbName: 'Vault File Storage' }])
    }
  }
  //#endregion FolderController

  //#region Upload
  async function startUploadQueue(files: File[], parentItemId?: number) {
    let index =
      Array.isArray(uploads.value) && uploads.value.length > 0
        ? uploads.value[uploads.value.length - 1].priority + 1
        : 0
    const toBeUploaded: IVaultUpload[] = []
    let totalSize = 0

    await updateVaultQuota()

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size

      if (
        quotaAccountInfo.value?.quotaMaxBytes &&
        totalSize + quotaAccountInfo.value?.quotaBytesUsed >
          quotaAccountInfo.value?.quotaMaxBytes
      ) {
        showUploadErrorModal(
          "Your Vault doesn't have enough space to upload these files."
        )
        return
      }

      if (fileNameIncludesRestrictedChars(files[i].name, true)) {
        return
      }

      if (isFileExtensionInvalid(files[i].name)) {
        showUploadErrorModal(
          'The file could not be added because this file type is not accepted.'
        )
        return
      }

      // set file, parentItemId, status
      const queueFile: IVaultUpload = {
        file: files[i],
        parentItemID: parentItemId,
        status: constants.vaultUploadStatus.queued,
        priority: index
      }
      // add files to array
      toBeUploaded.push(queueFile)

      index++
    }

    if (Array.isArray(uploads.value)) {
      uploads.value = [...uploads.value, ...toBeUploaded]
    } else {
      uploads.value = []
      uploads.value = [...uploads.value, ...toBeUploaded]
    }

    // if there is no currently uploading item or item with highest priority
    if (!getCurrentUpload.value && getHighestPriorityUpload.value) {
      queueNextUpload()
    }
  }

  async function startUploadProcess(item: IVaultUpload) {
    if (item.file) {
      // 1. get Sas token for Azure Blob storage for the file
      const _tokenResponse = await uploadTokenPost(item.file)

      if (_tokenResponse == undefined) {
        // Fail upload
        item.status = constants.vaultUploadStatus.failed
        updateVaultUploadItemByPriority(item)
        return
      }
      if (
        quotaAccountInfo.value?.quotaMaxBytes &&
        item.file.size + quotaAccountInfo.value?.quotaBytesUsed >
          quotaAccountInfo.value?.quotaMaxBytes
      ) {
        showUploadErrorModal(
          "Your Vault doesn't have enough space to upload these files."
        )
      }

      let _fileUpload: IVaultUpload = {
        uploadSasToken: _tokenResponse?.tokenUri,
        blobName: _tokenResponse?.blobName,
        file: item.file,
        parentItemID: item.parentItemID,
        status: constants.vaultUploadStatus.converting,
        lastDataUploadedIndex: 0,
        blockData: [],
        blockIds: [],
        priority: item.priority
      }
      updateVaultUploadItemByPriority(_fileUpload)

      // 2. break file into block blobs
      _fileUpload = await convertFileToBlocks(_fileUpload)

      // 3. start uploading
      _fileUpload.status = constants.vaultUploadStatus.uploading
      updateVaultUploadItemByPriority(_fileUpload)
      await doBlockUpload(_fileUpload)
    }
  }

  async function doBlockUpload(item: IVaultUpload): Promise<IVaultUpload> {
    // start the block upload
    if (
      item.uploadSasToken &&
      item.file &&
      item.status === constants.vaultUploadStatus.uploading &&
      item.blockIds &&
      item?.blockIds?.length > 0
    ) {
      for (
        let i = item.lastDataUploadedIndex || 0;
        i < item.blockIds.length;
        i++
      ) {
        if (
          item.uploadSasToken &&
          item.blockData &&
          !hasUploadBeenPaused(item)
        ) {
          const result = await uploadBlock(
            item.uploadSasToken,
            item.blockIds[i],
            item.blockData[i]
          )
          if (result) {
            item.lastDataUploadedIndex = i
            updateVaultUploadItemLastDataUploadedIndex(item.priority, i)
          } else {
            // block upload failed, need to stop the upload and alert the user
            item.status = constants.vaultUploadStatus.failed
            updateVaultUploadItemByPriority(item)
            queueNextUpload()
            break
          }
        }
      }
      const response = await commitBlockList(
        item.uploadSasToken,
        item.blockIds,
        item.file
      )
      if (response) {
        const filePostResponse = await filePost(
          new FileParam({
            parentItemID: item.parentItemID,
            name: item.file.name,
            description: '',
            fileSize: item.file.size,
            mimeType: item.file.type,
            blobName: item.blobName
          })
        )
        if (filePostResponse == undefined) {
          item.status = constants.vaultUploadStatus.failed
        } else {
          item.status = constants.vaultUploadStatus.completed
          item.itemID = filePostResponse.data.itemID
        }
        updateVaultUploadItemByPriority(item)
        finishUpload(item)
      }
    }
    return item
  }

  async function resumeUpload(priority: number) {
    const _index = uploads.value.findIndex((u) => u.priority == priority)

    if (_index >= 0) {
      // if file has started uploading, resume
      if ((uploads.value[_index].uploadSasToken?.length ?? 0) > 0) {
        uploads.value[_index].status = constants.vaultUploadStatus.uploading
        const item = await doBlockUpload(uploads.value[_index])
        finishUpload(item)
        // if there is no current file uploading AND paused file is top of priority list
      } else if (
        !getCurrentUpload.value &&
        getQueuedUploads.value.findIndex((e) => {
          return (
            e.priority < priority &&
            e.status != constants.vaultUploadStatus.completed &&
            e.status != constants.vaultUploadStatus.failed
          )
        }) == -1
      ) {
        uploads.value[_index].status = constants.vaultUploadStatus.queued
        queueNextUpload()
      } else {
        // only update status if still in queue
        uploads.value[_index].status = constants.vaultUploadStatus.queued
      }
    }
  }

  // this will stop block upload from looping again
  async function pauseUpload(priority: number) {
    const _index = uploads.value.findIndex((u) => u.priority == priority)

    if (_index >= 0 && uploads.value[_index]) {
      uploads.value[_index].status = constants.vaultUploadStatus.paused
    }
  }

  // TODO: add method to send empty blocks to azure
  async function cancelUpload(priority: number, isUploading: boolean) {
    const _index = uploads.value.findIndex((u) => u.priority == priority)

    // if upload was paused befored cancelling and has started uploading, pause next
    if (
      isUploading &&
      uploads.value[_index].status == constants.vaultUploadStatus.paused
    ) {
      uploads.value[_index].status = constants.vaultUploadStatus.failed
      pauseNextUpload()
    } else {
      uploads.value[_index].status = constants.vaultUploadStatus.failed
      queueNextUpload()
    }

    // remove canceled from list
    if (_index >= 0) {
      uploads.value.splice(_index, 1)
    }
  }

  async function finishUpload(item: IVaultUpload) {
    track(br.eventTypes.appAction, {
      feature: br.appActionFeature.vault,
      name: br.appActionEventNames.itemUploaded
    })

    // update current list if ur on it
    if (
      item.parentItemID == currentFolder.value?.itemID ||
      (!item.parentItemID && !currentFolder.value)
    ) {
      await updateCurrentFolder(item.parentItemID ?? 0)
    }

    await updateVaultQuota()
    queueNextUpload()
  }

  function queueNextUpload() {
    // start next upload
    if (!getCurrentUpload.value && getQueuedUploads.value.length > 0) {
      startUploadProcess(getQueuedUploads.value[0])
    }
  }

  function pauseNextUpload() {
    // pause next upload
    if (
      !getCurrentUpload.value &&
      getQueuedUploads.value.length > 0 &&
      !getPausedUploads.value[0]
    ) {
      pauseUpload(getQueuedUploads.value[0].priority)
    }
  }

  function removeUploadFromList(priority: number) {
    const _index = uploads.value.findIndex((u) => u.priority == priority)

    // remove canceled from list
    if (_index >= 0) {
      uploads.value.splice(_index, 1)
    }
  }

  async function uploadTokenPost(file: File) {
    const endpoint = uploadTokenControllerBaseUrl
    const abortController = new AbortController()
    try {
      const result = await httpClient.post(endpoint, null, {
        headers: {
          FileSizeBytes: file.size
        },
        signal: abortController.signal
      })
      if (result) {
        const tokenResponse: IUploadTokenResponse = result.data
        return tokenResponse
      }
    } catch (e: any) {
      if (e.response.data.errorCode == 4) {
        ErrorHelper.handleError(
          e,
          'uploadTokenPost',
          true,
          'Not enough space in Vault.'
        )
      } else {
        ErrorHelper.handleError(e, 'uploadTokenPost')
      }
    }
  }

  async function uploadToAzure(
    tokenResponse: IUploadTokenResponse,
    file: File
  ) {
    if (!file) {
      return
    }
    try {
      //vaultUploads.value.push()
      const sasUrl = tokenResponse.tokenUri
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob'
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          // uploadProgress.value = parseInt(
          //   Math.round(
          //     (progressEvent.loaded * 100) / progressEvent.total
          //   ).toString()
          // )
        }
      }

      try {
        const response = await axios.put(sasUrl, file, config)
        console.log('File uploaded successfully: ' + response)
        // call API to database the vault item
        if (response) {
          filePost(
            new FileParam({
              parentItemID: getCurrentFolderId.value || undefined,
              blobName: tokenResponse.blobName,
              name: file.name,
              description: '',
              fileSize: file.size,
              mimeType: file.type
            })
          )
        }
      } catch (error) {
        console.error('Error during file upload:', error)
      }
    } catch (error) {
      console.error('Error uploading file', error)
    }
  }

  async function convertFileToBlocks(upload: IVaultUpload) {
    //TODO: make values constants and figure out a block size strategy for different sized files
    const blockSize = 1024 * 1024 // For example, 1 MB per block

    const _fileUpload: IVaultUpload = {
      ...upload,
      lastDataUploadedIndex: 0,
      blockData: [],
      blockIds: []
    }
    if (upload.file) {
      for (
        let start = 0, index = 0;
        start < upload.file.size;
        start += blockSize, index++
      ) {
        const end = Math.min(start + blockSize, upload.file.size)
        const _blockData = upload.file.slice(start, end)
        const _blockId = generateBlockId(index)
        _fileUpload.blockData?.push(_blockData)
        _fileUpload.blockIds?.push(_blockId)
      }
    }

    return _fileUpload
  }

  async function uploadToAzureInBLocks(
    tokenResponse: IUploadTokenResponse,
    file: File
  ) {
    const blockSize = 1024 * 1024 // For example, 1 MB per block
    const blockIds = []
    const blockData = []
    for (
      let start = 0, index = 0;
      start < file.size;
      start += blockSize, index++
    ) {
      const end = Math.min(start + blockSize, file.size)
      const _blockData = file.slice(start, end)
      const blockId = generateBlockId(index)
      blockData.push(_blockData)
      // await uploadBlock(tokenResponse.tokenUri, blockId, blockData)
      blockIds.push(blockId)
    }
    //commitBlockList(tokenResponse.tokenUri, blockIds, tokenResponse, file)
  }

  async function uploadBlock(sasUrl: string, blockId: string, data: Blob) {
    const blockSasUrl = `${sasUrl}&comp=block&blockid=${blockId}`

    try {
      await axios.put(blockSasUrl, data, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': 'application/octet-stream'
        }
      })

      return true
    } catch (error) {
      console.error('Error uploading block:', error)
      return false
    }
  }

  function generateBlockId(index: number): string {
    const padding = '000000000000' // Ensure the block ID string length is consistent
    const id = padStart(`${index}`, padding.length, '0')
    return btoa(id).replace(/=+$/, '') // Remove any trailing '=' characters
  }

  function padStart(
    str: string,
    targetLength: number,
    padString: string
  ): string {
    if (str.length >= targetLength) {
      return str
    }
    const padding = Array(targetLength - str.length + 1).join(padString)
    return padding + str
  }

  async function commitBlockList(
    sasUrl: string,
    blockIds: string[],
    file: File
  ) {
    const blockListXml = `<?xml version="1.0" encoding="utf-8"?><BlockList>${blockIds
      .map((id) => `<Latest>${id}</Latest>`)
      .join('')}</BlockList>`
    const commitSasUrl = `${sasUrl}&comp=blocklist`

    try {
      await axios.put(commitSasUrl, blockListXml, {
        headers: {
          'Content-Type': 'application/xml'
        }
      })
      return true
    } catch (error) {
      console.error('Error committing block list:', error)
      throw error
    }
  }

  function getVaultUpload(item: IVaultUpload) {
    if (Array.isArray(uploads.value)) {
      return uploads.value.find((v) => v.file?.name == item.file?.name)
    }
    return null
  }

  function getVaultUploadByPriority(item: IVaultUpload) {
    if (Array.isArray(uploads.value)) {
      return uploads.value.find((v) => v.priority == item.priority)
    }
    return null
  }

  function hasUploadBeenPaused(item: IVaultUpload) {
    const _upload = getVaultUploadByPriority(item)
    if (_upload) {
      return _upload.status == constants.vaultUploadStatus.paused
    } else {
      //TODO: What should we do if we don't find the item
      return true
    }
  }

  function updateVaultUploadItemLastDataUploadedIndex(
    priority: number,
    lastIndex: number
  ) {
    if (Array.isArray(uploads.value)) {
      const _index = uploads.value.findIndex((u) => u.priority == priority)
      if (_index !== -1) {
        uploads.value[_index] = {
          ...uploads.value[_index],
          lastDataUploadedIndex: lastIndex
        }
      }
    }
  }

  function updateVaultUploadItemByPriority(item: IVaultUpload) {
    if (Array.isArray(uploads.value)) {
      // index and length will be offset if the user has cancelled an upload in the queue
      const _index = uploads.value.findIndex((v) => v.priority == item.priority)
      if (_index !== -1) {
        uploads.value[_index] = { ...item }
      }
    }
  }

  const getCurrentUpload = computed(() => {
    return uploads.value.filter((u) => {
      return (
        u.uploadSasToken &&
        u.status != constants.vaultUploadStatus.completed &&
        u.status != constants.vaultUploadStatus.failed
      )
    })[0]
  })

  const getHighestPriorityUpload = computed(() => {
    return uploads.value.filter((u) => {
      return (
        u.status != constants.vaultUploadStatus.completed &&
        u.status != constants.vaultUploadStatus.failed
      )
    })[0]
  })

  const getQueuedUploads = computed(() => {
    return uploads.value.filter((f) => {
      return f.status == constants.vaultUploadStatus.queued
    })
  })

  const getPausedUploads = computed(() => {
    return uploads.value.filter((f) => {
      return f.status == constants.vaultUploadStatus.paused
    })
  })

  const getCompletedUploads = computed(() => {
    return uploads.value.filter((f) => {
      f.status == constants.vaultUploadStatus.completed
    })
  })

  // upload modals
  function cancelAllUploads() {
    const uploadString = `<span class="place-center">Are you sure you want to stop uploading?</span>`

    if (
      getCurrentUpload.value ||
      getQueuedUploads.value.length > 0 ||
      getPausedUploads.value.length > 0
    ) {
      const { createSlot, closeModal, generateModal, HTMLtoComponent } =
        useModals()
      const el = generateModal({
        default: {
          headerText: 'Cancel upload',
          footerButtonLabel: 'Confirm'
        },
        slot: {
          content: createSlot('content', HTMLtoComponent(uploadString)).content
        },
        config: {
          showHeader: true,
          showBody: true,
          showFooter: true,
          addContentPadding: true,
          closeOnConfirm: true,
          showCloseButton: true,
          secondaryCTALabel: 'Cancel'
        },
        callback: {
          confirmFn: () => {
            uploads.value = []
          },
          secondaryFn: () => {
            closeModal(el)
          }
        }
      })
    } else {
      uploads.value = []
    }
  }

  function showUploadErrorModal(errorText: string) {
    const errorString = `<span class="place-center">${errorText}</span>`

    const { createSlot, generateModal, HTMLtoComponent } = useModals()

    generateModal({
      default: {
        headerText: 'Upload Error',
        footerButtonLabel: 'Okay'
      },
      slot: {
        content: createSlot('content', HTMLtoComponent(errorString)).content
      },
      config: {
        showHeader: true,
        showBody: true,
        showFooter: true,
        addContentPadding: true,
        closeOnConfirm: true,
        showCloseButton: true
      }
    })
  }

  //#endregion UploadTokenController

  //#region VaultAccountController
  async function vaultAccountPost() {
    const endpoint = vaultAccountControllerBaseUrl

    try {
      return await httpClient.post(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'vaultAccountPost')
    }
  }

  async function fetchQuotaInfo() {
    const endpoint = `${vaultAccountControllerBaseUrl}/getQuotaInfo`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getQuotaInfo')
    }
  }

  async function updateVaultQuota() {
    const response = await fetchQuotaInfo()
    if (response?.status == HttpStatusCodes.OK) {
      quotaAccountInfo.value = response.data
    }
  }
  //#endregion VaultAccountController

  //#region VaultAttachmentController
  async function vaultAttachmentPost(p: IVaultAttachmentParam) {
    const endpoint = vaultAttachmentControllerBaseUrl

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'vaultAttachmentPost')
    }
  }
  //#endregion VaultAttachmentController

  //#region VaultItemController
  async function fetchFileProviderItems(parentItemID?: number) {
    const endpoint = `${vaultItemControllerBaseUrl}/getfileprovideritems`

    try {
      return await httpClient.get(endpoint, {
        params: { parentItemID }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchFileProviderItems')
    }
  }

  async function fetchTrashedItems() {
    const endpoint = `${vaultItemControllerBaseUrl}/gettrasheditems`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchTrashedItems')
    }
  }

  async function fetchStarredItems(parentItemID?: number) {
    const endpoint = `${vaultItemControllerBaseUrl}/getAllStarredItems`

    try {
      return await httpClient.get(endpoint, {
        params: { parentItemID }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchStarredItems')
    }
  }

  async function search(searchTerm: string, searchTrash: boolean) {
    const endpoint = `${vaultItemControllerBaseUrl}/search`

    try {
      return await httpClient.get(endpoint, {
        params: { searchTerm, searchTrash }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'search')
    }
  }

  async function searchFilterSortAll(p: ISearchFilterSortVaultItemsParam) {
    const endpoint = `${vaultItemControllerBaseUrl}/searchFilterSortAll`
    // set range end to today if not passed
    if (p.rangeStart && !p.rangeEnd) {
      p.rangeEnd = moment().utc().format('YYYY-MM-DDTHH:mm:ss')
    }

    try {
      return await httpClient.get(endpoint, {
        params: p
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'searchFilterSortAll')
    }
  }

  async function searchFilterSortDirectory(
    p: ISearchFilterSortVaultItemsParam
  ) {
    const endpoint = `${vaultItemControllerBaseUrl}/searchFilterSortDirectory`

    try {
      return await httpClient.get(endpoint, {
        params: p
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'searchFilterSortDirectory')
    }
  }

  async function fetchPreviewInfo(itemID: number) {
    const endpoint = `${vaultItemControllerBaseUrl}/getpreviewinfo`

    try {
      return await httpClient.get(endpoint, {
        params: { itemID }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchPreviewInfo')
    }
  }

  async function starVaultItem(itemID: number, isStarred: boolean) {
    const endpoint = `${vaultItemControllerBaseUrl}/star?itemID=${itemID}&isStarred=${isStarred}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'starVaultItem')
    }
  }

  async function starVaultItems(p: IStarVaultItemsParam) {
    const endpoint = `${vaultItemControllerBaseUrl}/starItems`

    try {
      return await httpClient.put(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'moveVaultItems')
    }
  }

  async function renameVaultItem(itemID: number, newName: string) {
    const endpoint = `${vaultItemControllerBaseUrl}/rename?itemID=${itemID}&newName=${newName}`
    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'renameVaultItem')
    }
  }

  async function editDescriptionVaultItem(
    itemID: number,
    newDescription: string
  ) {
    const endpoint = `${vaultItemControllerBaseUrl}/description?itemID=${itemID}&newDescription=${newDescription}`
    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'editDescriptionVaultItem')
    }
  }

  async function moveVaultItem(itemID: number, parentItemID?: number) {
    const undoItem = vaultItems.value.find((i) => i.itemID == itemID)

    let endpoint = `${vaultItemControllerBaseUrl}/move?itemID=${itemID}`
    if (parentItemID) endpoint += `&parentItemID=${parentItemID}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'moveVaultItem')
    } finally {
      if (undoItem) {
        undoItems.value = [...(undoItems.value ?? []), undoItem]
      }
    }
  }

  async function moveVaultItems(p: IMoveVaultItemsParam) {
    const undo = vaultItems.value.filter((vi) => p.itemIds.includes(vi.itemID))

    const endpoint = `${vaultItemControllerBaseUrl}/moveItems`

    try {
      return await httpClient.put(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'moveVaultItems')
    } finally {
      if (undo) {
        undoItems.value = [...(undoItems.value ?? []), ...undo]
      }
    }
  }

  async function undoMove() {
    if (!undoItems.value?.length) {
      return
    }

    const endpoint = `${vaultItemControllerBaseUrl}/moveItems`

    const param = {
      itemIds: undoItems.value.map((ui) => ui.itemID),
      parentItemId: undoItems.value?.[0]?.parentItemID
    } as IMoveVaultItemsParam

    try {
      return await httpClient.put(endpoint, param)
    } catch (e) {
      ErrorHelper.handleError(e, 'moveVaultItems')
    }
  }

  async function onDrop(
    dragEvent: DragEvent,
    destinationItem?: IVaultItem,
    trash = false
  ) {
    if (destinationItem && !destinationItem.isFolder) {
      return
    }

    if (!dragEvent.dataTransfer) {
      return
    }

    const types = dragEvent.dataTransfer.types

    //intended drops only have one entry in types:
    //items - 'text/plain'; action - move
    //files - 'Files'; action - upload
    if (
      types.length != 1 &&
      !(types.includes('application/x-moz-file') && types.length == 2)
    ) {
      return
    }

    //need to check if dataTransfer has text, or files
    if (types.includes('text/plain')) {
      //need to make sure item isn't dragged into its parent folder
      if (!destinationItem && !currentFolder.value && trash == false) {
        return
      }

      if (
        destinationItem &&
        destinationItem.itemID == currentFolder.value?.itemID
      ) {
        return
      }

      if (
        destinationItem &&
        selectedItemsList.value
          ?.map((si) => si.itemID)
          .includes(destinationItem.itemID)
      ) {
        return
      }

      //multi-selected item drop will contain a ; delimited list of ids
      const itemIds = dragEvent.dataTransfer
        .getData('text')
        .split(';') //array of strings split on semicolon
        .map((i) => +i) //+ unary operator to convert to number
        .filter((s) => !Number.isNaN(s)) //get rid of the non-number entries

      //if the destination is contained within the selection
      if (destinationItem && itemIds.includes(destinationItem?.itemID)) {
        return
      }

      if (trash) {
        const { closeModal, generateModal } = useModals()
        const loading = ref<boolean>(false)

        const contentText = `${
          itemIds.length > 1
            ? `${itemIds.length} items`
            : `"${
                selectedItemsList.value?.find((si) => si.itemID == itemIds[0])
                  ?.name
              }"`
        } ${t('vault.modalOptions.moveToTrashContent')}`

        const el = generateModal({
          default: {
            headerText: t('vault.modalOptions.moveToTrash'),
            contentText: contentText,
            footerButtonLabel: 'remove'
          },
          config: {
            footerButtonLoading: loading,
            showHeader: true,
            showFooter: true,
            showCloseButton: true,
            showSecondaryCTA: true,
            secondaryCTALabel: 'cancel',
            footerStyle: 'flex-reverse gap-3',
            closeOnLoadEnd: true,
            closeOnConfirm: false
          },
          callback: {
            confirmFn: async () => {
              //then a list of items was dropped onto the trash quick access
              loading.value = true
              const success =
                (
                  await trashOrRestoreVaultItems({
                    itemIds: itemIds,
                    trash: trash
                  })
                )?.status == HttpStatusCodes.OK

              if (success) {
                removeVaultItemsFromList(itemIds)
                setSelectedItemsList(null)
                updateFolderTree()
              }
              loading.value = false
            },
            secondaryFn: () => {
              closeModal(el)
            }
          }
        })

        return
      }

      // const firstItem = vaultItems.value.find((vi) => vi.itemID == itemIds[0])

      // if (firstItem?.isTrashed) {
      //   //then we know we're restoring a list of trashed items through a drop onto all items
      //   const success =
      //     (await trashOrRestoreVaultItems({ itemIds: itemIds, trash: false }))
      //       ?.status == HttpStatusCodes.OK

      //   if (success) {
      //     removeVaultItemsFromList(itemIds)
      //   }

      //   return
      // }

      //bulk move - can also be used for singular items
      const success =
        (
          await moveVaultItems({
            itemIds: itemIds,
            parentItemId: destinationItem?.itemID
          })
        )?.status == HttpStatusCodes.OK

      if (success) {
        removeVaultItemsFromList(itemIds)
      }
    } else if (types.includes('Files')) {
      //then a list of files was dropped, so upload
      const files = Array.from(dragEvent.dataTransfer.files)

      if (!files.length) {
        return
      }

      await startUploadQueue(files, destinationItem?.itemID)
    }
  }

  async function checkRestoreLocation(parentItemID: number) {
    const endpoint = `${vaultItemControllerBaseUrl}/checkrestorelocation`

    try {
      return await httpClient.get(endpoint, {
        params: { parentItemID }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'checkRestoreLocation')
    }
  }

  async function deleteAll() {
    const endpoint = `${vaultItemControllerBaseUrl}/deleteAll`

    try {
      return await httpClient.delete(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'deleteAll')
    }
  }
  //#endregion VaultItemController

  //#region VaultJournalAttachmentController
  async function vaultJournalAttachmentPost(p: IVaultJournalAttachmentParam) {
    const endpoint = vaultJournalAttachmentControllerBaseUrl

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'vaultJournalAttachmentPost')
    }
  }
  //#endregion VaultJournalAttachmentController

  //#region VaultShareController
  async function sendShareEmail(p: IShareEmailParam) {
    let endpoint = `${vaultShareControllerBaseUrl}/sendshareemail?Email=${p.email}&VaultItemID=${p.vaultItemID}&ExpiredWhenTicks=${p.expiresWhenTicks}`
    if (p.note) endpoint += `&Note=${p.note}`

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'sendShareEmail')
    }
  }

  async function getLink(p: IGetVaultShareLinkParam) {
    const endpoint = `${vaultShareControllerBaseUrl}/getlink?ItemID=${p.itemID}&ExpiresWhenTicks=${p.expiresWhenTicks}`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getLink')
    }
  }

  async function getSharedItems() {
    const endpoint = `${vaultShareControllerBaseUrl}/getshareditems`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getSharedItems')
    }
  }
  //#endregion VaultShareController

  //#region Downloads
  function startDownload(
    url: string,
    fileName: string,
    vaultItem?: IVaultItem
  ) {
    const id = Date.now().toString() // Generate a unique ID for the download
    //const workerUrl = new URL('./fileDownloader.js', import.meta.url)
    WorkerService.createWorker(id, fileDownloaderWorker)
    const options: RequestInit = {}
    const request: WorkerRequest = { url, options, id }

    WorkerService.postRequest(id, request, (response) => {
      if (response.success) {
        if (response.data.status === 'progress') {
          if (response.data.id === id) {
            const download = getDownloadFromList(id)
            if (download) {
              download.progress = response.data.progress
            }
          }
        } else if (response.data.status === 'completed') {
          const download = getDownloadFromList(id)
          if (download) {
            handleDownloadedFile(response.data.data, download.fileName)
            download.status = constants.vaultDownloadStatus.completed
          }
          WorkerService.terminateWorker(id)
        } else if (response.data.status === 'error') {
          const download = getDownloadFromList(id)
          if (download) {
            download.status = constants.vaultDownloadStatus.failed
          }
          WorkerService.terminateWorker(id)
        }
      } else {
        const download = getDownloadFromList(id)
        if (download) {
          download.status = constants.vaultDownloadStatus.failed
        }
      }
    })
    // const worker = new Worker(
    //   new URL('../workers/fileDownloader.ts', import.meta.url),
    //   { type: 'module' }
    // )

    // worker.postMessage({ action: 'download', url: url, id: id })
    // worker.onmessage = function (e) {
    //   if (e.data.status === 'progress') {
    //     if (e.data.id === id) {
    //       const download = getDownloadFromList(id)
    //       if (download) {
    //         download.progress = e.data.progress
    //       }
    //     }
    //   } else if (e.data.status === 'completed') {
    //     const download = getDownloadFromList(id)
    //     if (download) {
    //       handleDownloadedFile(e.data.data, download.fileName)
    //       download.status = constants.vaultDownloadStatus.completed
    //       download?.worker.terminate()
    //     }
    //   } else if (e.data.status === 'error') {
    //     const download = getDownloadFromList(id)
    //     if (download) {
    //       download.status = constants.vaultDownloadStatus.failed
    //       download?.worker.terminate()
    //     }
    //   }
    // }

    downloads.value.push({
      id,
      url,
      progress: 0,
      fileName,
      status: constants.vaultDownloadStatus.downloading,
      vaultItem: vaultItem
    })
  }

  function reDownload(existingFile: IVaultDownload) {
    //const workerUrl = new URL('../workers/fileDownloader.ts', import.meta.url)
    WorkerService.createWorker(existingFile.id, fileDownloaderWorker)
    const options: RequestInit = {}
    const request: WorkerRequest = {
      url: existingFile.url,
      options,
      id: existingFile.id
    }

    WorkerService.postRequest(existingFile.id, request, (response) => {
      if (response.success) {
        if (response.data.status === 'progress') {
          if (response.data.id === existingFile.id) {
            const download = getDownloadFromList(existingFile.id)
            if (download) {
              download.progress = response.data.progress
            }
          }
        } else if (response.data.status === 'completed') {
          const download = getDownloadFromList(existingFile.id)
          if (download) {
            handleDownloadedFile(response.data.data, download.fileName)
            download.status = constants.vaultDownloadStatus.completed
          }
          WorkerService.terminateWorker(existingFile.id)
        } else if (response.data.status === 'error') {
          const download = getDownloadFromList(existingFile.id)
          if (download) {
            download.status = constants.vaultDownloadStatus.failed
          }
          WorkerService.terminateWorker(existingFile.id)
        }
      } else {
        const download = getDownloadFromList(existingFile.id)
        if (download) {
          download.status = constants.vaultDownloadStatus.failed
        }
      }
    })
  }

  function handleDownloadedFile(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  function stopDownload(id: string) {
    const download = getDownloadFromList(id)
    if (download) {
      download.status = constants.vaultDownloadStatus.stopped
      WorkerService.terminateWorker(id)
    }
  }

  function removeDownloadFromList(id: string) {
    const downloadIndex = downloads.value.findIndex((d) => d.id === id)
    if (downloadIndex !== -1) {
      WorkerService.terminateWorker(id)
      downloads.value.splice(downloadIndex, 1)
    }
  }

  function getDownloadFromList(id: string) {
    return downloads.value.find((d) => d.id === id)
  }

  function removeAllDownloads() {
    downloads.value.forEach((d) => {
      if (d.status == constants.vaultDownloadStatus.downloading) {
        WorkerService.terminateWorker(d.id)
      }
    })
    downloads.value = []
  }

  const getInProgressDownloads = computed(() => {
    return downloads.value.filter(
      (d) => d.status == constants.vaultDownloadStatus.downloading
    )
  })

  // download modals
  function cancelAllDownloads() {
    const downloadString = `<span class="place-center">Some items are still downloading. Are you sure you want to stop all of them?</span>`

    if (getInProgressDownloads.value.length > 0) {
      const { createSlot, closeModal, generateModal, HTMLtoComponent } =
        useModals()
      const el = generateModal({
        default: {
          headerText: 'Cancel Downloads',
          footerButtonLabel: 'Confirm'
        },
        slot: {
          content: createSlot('content', HTMLtoComponent(downloadString))
            .content
        },
        config: {
          showHeader: true,
          showBody: true,
          showFooter: true,
          addContentPadding: true,
          closeOnConfirm: true,
          showCloseButton: true,
          secondaryCTALabel: 'Cancel'
        },
        callback: {
          confirmFn: () => {
            removeAllDownloads()
          },
          secondaryFn: () => {
            closeModal(el)
          }
        }
      })
    } else {
      removeAllDownloads()
    }
  }

  //#endregion Downloads

  //#region Thumbnails
  async function startThumbnailFetch(itemID: number) {
    const id = `${Date.now().toString()}-${itemID}`
    addThumbnailWorkerId(id)

    WorkerService.createWorker(id, thumbnailFetcherWorker)

    const loginStore = useLoginStore()
    const authToken = loginStore.authToken

    const options: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        AID: 'Web',
        'App-Version': import.meta.env.VUE_APP_VERSION,
        'Trace-User': '0',
        Authorization: `Bearer ${authToken}`
      }
    }
    const url = `${fileControllerBaseUrl}/getAllThumbnailSasUrlsForItem?itemId=${itemID}`

    const workerRequest: WorkerRequest = {
      url,
      options,
      id
    }

    WorkerService.postRequest(id, workerRequest, (response) => {
      if (response.success) {
        switch (response.data.status) {
          case 'completed': {
            const fetch = getThumbnailFetchFromList(itemID)

            if (fetch) {
              handleFetchedThumbnail(response.data.data, fetch)
            }

            WorkerService.terminateWorker(id)
            break
          }
          case 'error': {
            const fetch = getThumbnailFetchFromList(itemID)

            if (fetch) fetch.urls = []

            WorkerService.terminateWorker(id)
            break
          }
          case 'unavailable': {
            //then the thumbnail needs to be generated
            const fetch = getThumbnailFetchFromList(itemID)
            if (fetch) fetch.urls = []

            WorkerService.terminateWorker(id)
            updateThumbnailStatusForItem(
              itemID,
              VaultThumbnailStatus.Generating
            )
            break
          }
        }

        return
      }

      const fetch = getThumbnailFetchFromList(itemID)
      if (fetch) fetch.urls = []
    })

    fetchedThumbnails.value.push({
      workerId: id,
      itemID: itemID,
      urls: null
    })
  }

  function addThumbnailWorkerId(id: string) {
    thumbnailWorkerIds.value.push(id)
  }

  function terminateAllThumbnailWorkers() {
    for (const id of thumbnailWorkerIds.value) {
      WorkerService.terminateWorker(id)
    }

    setThumbnailWorkerIds([])
  }

  function setThumbnailWorkerIds(ids: string[]) {
    thumbnailWorkerIds.value = ids
  }

  function getThumbnailFetchFromList(id: number) {
    return fetchedThumbnails.value.find((t) => t.itemID === id)
  }

  function updateThumbnailStatusForItem(
    itemId: number,
    status?: VaultThumbnailStatus
  ) {
    const item = vaultItems.value.find((i) => i.itemID == itemId)
    if (item) {
      item.thumbnailStatus = status
    }
  }

  function handleFetchedThumbnail(urls: string[], fetch: IThumbnailFetch) {
    fetch.urls = urls
  }
  //#endregion Thumbnails

  return {
    apiError,
    sharedVaultItem,
    draggingVaultItem,
    vaultItems,
    pageSize,
    loadMoreVaultItems,
    setApiError,
    setSharedVaultItem,
    fetchSharedVaultItem,
    setLoadMoreVaultItems,
    isThumbableType,
    setDraggingVaultItem,
    setVaultItems,
    removeVaultItemFromList,
    fetchDownloadUrl,
    filePost,
    fetchFileItems,
    fileTrash,
    fileRestore,
    fileDelete,
    getOrCreateThumbnailSasUrl,
    folderPost,
    fetchSubFolders,
    folderTrash,
    folderRestore,
    folderDelete,
    uploadTokenPost,
    vaultAccountPost,
    fetchQuotaInfo,
    vaultAttachmentPost,
    fetchFileProviderItems,
    fetchTrashedItems,
    fetchStarredItems,
    search,
    fetchPreviewInfo,
    starVaultItem,
    renameVaultItem,
    editDescriptionVaultItem,
    moveVaultItem,
    checkRestoreLocation,
    deleteAll,
    vaultJournalAttachmentPost,
    sendShareEmail,
    getLink,
    getSharedItems,
    getCurrentFolderId,
    getAllFolders,
    treeData,
    setTreeData,
    updateFolderTree,
    uploadToAzureInBLocks,
    selectedVaultColumnFilter,
    setVaultColumnFilter,
    selectedVaultSortBy,
    setSelectedVaultSortBy,
    selectedVaultSortByColumn,
    selectedVaultSortByDirection,
    vaultListViewMode,
    setVaultListViewMode,
    startUploadProcess,
    upload,
    uploads,
    resumeUpload,
    searchTerm,
    setSearchTerm,
    findFolderPath,
    updateBreadcrumbs,
    downloads,
    stopDownload,
    startDownload,
    getCurrentExpireTimeInTicks,
    handleSearchTermUpdate,
    startThumbnailFetch,
    fetchedThumbnails,
    resetVaultItems,
    updateVaultQuota,
    hoveringOverVaultItem,
    setHoveringOverVaultItem,
    fetchRecentItems,
    fetchImageItems,
    fetchVideoItems,
    handleThumbnailGeneratedNotification,
    quotaAccountInfo,
    startUploadQueue,
    pauseUpload,
    cancelUpload,
    getCurrentUpload,
    getQueuedUploads,
    getHighestPriorityUpload,
    getPausedUploads,
    cancelAllUploads,
    removeDownloadFromList,
    reDownload,
    cancelAllDownloads,
    fetchFilteredItems,
    confirmDeleteAll,
    setvaultItemAttachments,
    removeVaultItemFromAttachments,
    vaultItemAttachments,
    getVaultItemAttachmentIndex,
    pushVaultItemToAttachments,
    selectedItemsList,
    pushVaultItemToSelectedList,
    removeVaultItemFromSelectedList,
    getVaultItemSelectedListIndex,
    setSelectedItemsList,
    editingItem,
    setEditingItem,
    navigateIntoFolder,
    currentFolderLevel,
    setCurrentFolderLevel,
    getCompletedUploads,
    reset,
    undoItems,
    removeUploadFromList,
    undoMove,
    setUndoItems,
    multiSelectMode,
    setMultiSelectMode,
    thumbnailWorkerIds,
    terminateAllThumbnailWorkers,
    addThumbnailWorkerId,
    setThumbnailWorkerIds,
    moveVaultItems,
    removeVaultItemsFromList,
    onDrop,
    currentFolder,
    setCurrentFolder,
    updateCurrentFolder,
    fetchSearchResults,
    handleTrashFileOrfolder,
    trashOrRestoreVaultItems,
    skeletonLoading,
    starVaultItems,
    fetchDownloadUrls,
    deleteItems
  }
})
