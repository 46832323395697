﻿import { Frequency, RRule, rrulestr, Weekday, type ByWeekday } from 'rrule'
import moment, { type Moment } from 'moment-timezone'
import type { ICalendarEntry, IRRuleForm } from '@/models/interfaces'

export default {
  isMultidayEventInDate(entry: ICalendarEntry, date: Moment) {
    const _date = date
    const _startDate = entry.startTime
    const _endDate = entry.endTime
    let isInDate = false
    if (_startDate.isSame(_date, 'day')) isInDate = true
    if (!isInDate && _endDate.isSame(_date, 'day')) isInDate = true
    if (!isInDate && _date.isBetween(_startDate, _endDate)) isInDate = true

    return isInDate
  },
  isRecurringEventInDate(entry: ICalendarEntry, date: Moment) {
    const rruleSet = rrulestr(entry.rRule)
    const recurringEventArray = rruleSet.between(
      moment(date).hour(0).minute(0).toDate(),
      moment(date).hour(23).minute(59).toDate()
    )
    return recurringEventArray.length > 0
  },
  getFirstOccurrence(rrule: string, startDate: Date) {
    //JS Date object - Sunday represents 0, Monday 1, etc.
    let startDateDayOfWeek = startDate.getDay() - 1
    if (startDateDayOfWeek < 0) startDateDayOfWeek = 7

    const rruleSet = rrulestr(rrule)

    //however, 0 here is Monday
    //null byweekday is replaced by the start date day of week to ensure the difference is zero
    const latestDayOfWeek = Math.max(
      ...(rruleSet.options?.byweekday ?? [startDateDayOfWeek])
    )

    const dayOfWeekDifference = latestDayOfWeek - startDateDayOfWeek

    //getting the latest day of the week's date
    const latestRecurrenceDate = moment(startDate)
      .add(dayOfWeekDifference, 'days')
      .toDate()

    //ensuring the "first occurrence" gotten from .after is the latest weekday
    //daily frequencies should have byweekday be null, other frequencies start with a non-null byweekday
    if (rruleSet.options?.byweekday)
      rruleSet.options.byweekday = [latestDayOfWeek]

    const firstOcc = rruleSet.after(latestRecurrenceDate, false)
    return firstOcc
  },
  constructRRule(
    freq: string,
    until: Moment | null,
    interval: number,
    byweekday: string[],
    bysetpos: number | null
  ) {
    const rrule = new RRule({
      freq: this.getRRuleFreq(freq),
      until: until
        ? new Date(
            Date.UTC(
              until.year(),
              until.month(),
              until.date(),
              until.hour(),
              until.minute(),
              until.second(),
              until.millisecond()
            )
          )
        : null,
      interval: interval,
      byweekday: freq == 'Daily' ? [] : this.getRRuleByDay(byweekday),
      bysetpos: bysetpos
    })

    //console.log('returning RRule string...: ', rrule.toString())

    return rrule.toString()
  },
  getBySetPos(bysetpos: string) {
    switch (bysetpos) {
      case 'day':
      case undefined:
      case null:
        return null
      case 'lastday':
        return -1
      default:
        return parseInt(bysetpos)
    }
  },
  constructRRuleForDatePicker(
    freq: string,
    interval: number,
    byweekday: string[],
    bysetpos: number | null,
    dtStartAsMoment: Moment
  ) {
    const rrule = new RRule({
      freq: this.getRRuleFreq(freq),
      interval: interval,
      byweekday: this.getRRuleByDay(byweekday),
      bysetpos: bysetpos,
      dtstart: dtStartAsMoment.toDate()
    })

    //console.log('constructed rrule string: ', rrule.toString())
    return rrule.toString()
  },
  deconstructRRule(rruleString: string): IRRuleForm {
    // strip trailing ';'
    const _rrule = RRule.fromString(rruleString)
    const options = _rrule.origOptions
    const rruleForm = {
      freq: this.getDeconstructedRRuleFreq(options.freq),
      until: options.until,
      interval: options.interval ? options.interval : 1,
      byweekday: this.getDeconstructedRRuleDay(options.byweekday),
      bysetpos: options.bysetpos ?? null
    }
    return rruleForm
  },
  getRRuleFreq(val: string) {
    switch (val) {
      case 'Daily':
        return RRule.DAILY
      case 'Weekly':
      case 'Every Other Week':
        return RRule.WEEKLY
      case 'Monthly':
        return RRule.MONTHLY
    }
  },
  getDeconstructedRRuleFreq(val: Frequency | undefined): string {
    let _text = ''
    switch (val) {
      case RRule.DAILY:
        _text = 'Daily'
        break
      case RRule.WEEKLY:
        _text = 'Weekly'
        break
      case RRule.MONTHLY:
        _text = 'Monthly'
        break
    }
    return _text
  },
  getDeconstructedRRuleDay(vals: any) {
    if (vals) {
      const rule = vals.map(this.deconstructDayValue)
      return rule
    }
    return null
  },
  deconstructDayValue(val: Weekday) {
    switch (val) {
      case RRule.MO:
        return 'monday'
      case RRule.TU:
        return 'tuesday'
      case RRule.WE:
        return 'wednesday'
      case RRule.TH:
        return 'thursday'
      case RRule.FR:
        return 'friday'
      case RRule.SA:
        return 'saturday'
      case RRule.SU:
        return 'sunday'
    }
  },
  deconstructBySetPosValue(val: string | number | number[]) {
    switch (val) {
      case 1:
        return 'first'
      case 2:
        return 'second'
      case 3:
        return 'third'
      case 4:
        return 'fourth'
      case -1:
        return 'last'
      default:
        return 'each'
    }
  },
  getRRuleByDay(val: string[]): ByWeekday | ByWeekday[] | null | undefined {
    if (val) {
      const rule = val.map(this.getRRuleDayValue)
      return rule
    }
    return null
  },
  getRRuleDayValue(val: string): Weekday {
    let _weekday: Weekday
    switch (val.trim().toLowerCase()) {
      case 'monday':
        _weekday = RRule.MO
        break
      case 'tuesday':
        _weekday = RRule.TU
        break
      case 'wednesday':
        _weekday = RRule.WE
        break
      case 'thursday':
        _weekday = RRule.TH
        break
      case 'friday':
        _weekday = RRule.FR
        break
      case 'saturday':
        _weekday = RRule.SA
        break
      case 'sunday':
        _weekday = RRule.SU
        break
      default:
        _weekday = RRule.SU
    }
    return _weekday
  },
  getRRuleAsText(rruleString: string, startTimeAsMoment: Moment, timeZoneId?: string) {
    const startTime = startTimeAsMoment ? startTimeAsMoment : moment()

    if (rruleString && rruleString.length > 0) {
      const _rruleString = rruleString.replace(/;\s*$/, '')
      const ruleObj = this.deconstructRRule(_rruleString)

      switch (ruleObj.freq) {
        case 'Monthly':
          return this.createMonthlyText(_rruleString, startTime)
        case 'Weekly':
          return this.createWeeklyText(_rruleString, startTime)
        case 'Daily':
          return rrulestr(_rruleString, { tzid: timeZoneId }).toText()
      }
    }
    return ''
  },
  createWeeklyText(rruleString: string, startTimeAsMoment: Moment, timeZoneId?: string) {
    //console.log('createWeeklyText rruleString: ', rruleString)

    let rrule = rrulestr(rruleString, { tzid: timeZoneId }).toText()

    //console.log('createWeeklyText rrule: ', rrule)

    const pos = rrule.lastIndexOf('day, ')
    //console.log(`last index of 'day, ': `, pos)
    if (pos >= 0) {
      rrule = rrule.substring(0, pos + 3) + ' and ' + rrule.substring(pos + 5)
    }

    //console.log(rrule)

    //for single-day events that repeat every day every x weeks, where x > 1
    const grammarPos = rrule.lastIndexOf('weeks days')
    //console.log(`last index of 'weeks days': `, grammarPos)
    if (grammarPos >= 0) {
      rrule =
        rrule.substring(0, grammarPos) +
        'weeks every day' +
        rrule.substring(grammarPos + 10)
    }

    const untilPos = rrule.lastIndexOf('until')
    if (untilPos >= 0) {
      rrule =
        rrule.substring(0, untilPos) +
        ` starting on ${startTimeAsMoment.format('MMMM D, YYYY')} ` +
        rrule.substring(untilPos)
    } else {
      rrule += ` starting on ${startTimeAsMoment.format('MMMM D, YYYY')}`
    }

    //console.log('finally: ', rrule)
    //rrule += ` starting on `
    return rrule
  },
  createMonthlyText(rruleString: string, startTimeAsMoment: Moment) {
    //console.log('rruleString: ', rruleString)

    let _text = ' every'
    const ruleObj = this.deconstructRRule(rruleString)

    //console.log('ruleObj: ', ruleObj)

    // handly repeats every selection
    if (ruleObj.interval == 1) {
      _text += ' month'
    } else {
      _text += ` ${ruleObj.interval} months`
    }
    const recurringOnLastDayOfMonth =
      ruleObj.bysetpos == -1 &&
      ruleObj.byweekday &&
      ruleObj.byweekday.length == 7

    //console.log('recurringOnLastDayOfMonth: ', recurringOnLastDayOfMonth)

    if (recurringOnLastDayOfMonth) {
      _text += ' on the last day of the month'
    } else {
      // if there is an "on" selection
      if (ruleObj.bysetpos != undefined && ruleObj.bysetpos != null) {
        //console.log('bysetpos is not undefined nor null')
        _text += ` on the ${this.deconstructBySetPosValue(ruleObj.bysetpos)}`
      }

      //console.log('ruleObj.byweekday.length: ', ruleObj.byweekday?.length)

      // if there are day selections
      if (ruleObj.byweekday?.length) {
        const _days = this.parseByWeekday(ruleObj.byweekday)
        const _daysText = _days ? _days : ''
        _text +=
          ruleObj.bysetpos == undefined
            ? ` on each ${_daysText}`
            : ` ${this.parseByWeekday(ruleObj.byweekday)}`
      } else {
        _text += ` on the ${startTimeAsMoment.format('Do')}`
      }
    }

    const untilStartPos = rruleString.indexOf('UNTIL=', 0)

    if (untilStartPos >= 0) {
      const untilEndPos =
        rruleString.indexOf(';', untilStartPos) > -1
          ? rruleString.indexOf(';', untilStartPos)
          : rruleString.length
      const untilDate = rruleString.substring(untilStartPos + 6, untilEndPos) // 20201129T050000Z
      const untilDateAsMoment = moment.utc(untilDate)
      _text =
        _text +
        ` starting on ${startTimeAsMoment.format(
          recurringOnLastDayOfMonth ? 'MMMM' : 'MMMM D, YYYY'
        )} until ` +
        untilDateAsMoment.format('MMMM D, YYYY')
    } else {
      _text += ` starting on ${startTimeAsMoment.format(
        recurringOnLastDayOfMonth ? 'MMMM' : 'MMMM D, YYYY'
      )}`
    }

    return _text
  },
  parseByWeekday(byweekday: any) {
    if (byweekday.length == 1) {
      return this.capitalize(byweekday[0])
    } else {
      const _byweekday = byweekday.map((d: string) => this.capitalize(d))
      let _days = _byweekday.join(', ')
      const pos = _days.lastIndexOf(', ')
      _days = _days.substring(0, pos) + ' or ' + _days.substring(pos + 1)
      return _days
    }
  },
  capitalize(day: any) {
    if (typeof day !== 'string') return ''
    return day.charAt(0).toUpperCase() + day.slice(1)
  },
  getMonthStartDate(year: number, month: number) {
    return moment(`${year} ${month} 1`, 'YYYY MM DD').day('Sunday')
  },
  getMonthEndDate(year: number, month: number) {
    return moment(`${year} ${month} 1`, 'YYYY MM DD')
      .endOf('month')
      .day('Saturday')
  },
  getEventsForDate(date: string, events: ICalendarEntry[]) {
    // events for the date provided
    const _events = []
    const _convertedDateToLocalTime = moment(date)
    for (let i = 0; i < events.length; i++) {
      let isInDate = false
      //check if event is all day, if so check against utc time
      if (events[i].isAllDay) {
        // if (events[i].StartTime.format('YYYY-MM-DD') == date) {
        //     isInDate = true
        // }
        if (events[i].startTime.isSame(moment.utc(date), 'day')) {
          isInDate = true
        }
      } else {
        if (events[i].startTime.isSame(_convertedDateToLocalTime, 'day'))
          isInDate = true
        if (
          !isInDate &&
          events[i].endTime.isSame(_convertedDateToLocalTime, 'day')
        )
          isInDate = true
        if (
          !isInDate &&
          _convertedDateToLocalTime.isBetween(
            events[i].startTime,
            events[i].endTime
          )
        )
          isInDate = true
      }
      if (isInDate) _events.push(events[i])
    }
    return _events
  },
  convertEventDatesToUsersLocalTime(
    events: ICalendarEntry[],
    timezone: string
  ): ICalendarEntry[] {
    const _entries = Object.assign(events)
    for (let i = 0; i < _entries.length; i++) {
      _entries[i].startTime = _entries[i].isAllDay
        ? moment.utc(_entries[i].startTime)
        : moment.utc(_entries[i].startTime).tz(timezone)
      _entries[i].endTime = _entries[i].IsAllDay
        ? moment.utc(_entries[i].endTime)
        : moment.utc(_entries[i].endTime).tz(timezone)
      _entries[i].dTStart =
        _entries[i].dTStart && _entries[i].dTStart.length > 0
          ? moment.utc(_entries[i].dTStart).tz(timezone)
          : null
      _entries[i].until =
        _entries[i].until && _entries[i].until.length > 0
          ? moment.utc(_entries[i].until).tz(timezone)
          : null
      _entries[i].createdWhen = moment.utc(_entries[i].createdWhen).tz(timezone)
      _entries[i].lastViewed = _entries[i].lastViewed
        ? moment.utc(_entries[i].lastViewed).tz(timezone)
        : null
      _entries[i].lastEdited = _entries[i].lastEdited
        ? moment.utc(_entries[i].lastEdited).tz(timezone)
        : null
    }
    return _entries
  },
  isDayInMonth(dayString: string, currentDay: Moment) {
    return moment(dayString, 'YYYY-MM-DD').isSame(currentDay, 'month')
  },
  isEventMultiDay(event: ICalendarEntry) {
    return this.isMultiDay(event) && !event.isAllDay && !event.isNew
  },
  isEventSingleDay(event: ICalendarEntry) {
    return this.isSingleDay(event) && !event.isAllDay && !event.isNew
  },
  isEventGreaterThanWeek(event: ICalendarEntry) {
    return (
      event.endTime.diff(event.startTime, 'days') >= 7 &&
      !event.isAllDay &&
      !event.isNew
    )
  },
  isEventGreaterThanMonth(event: ICalendarEntry) {
    return (
      event.endTime == null ||
      event.startTime == null ||
      (event.endTime.diff(event.startTime, 'days') > 27 &&
        !event.isAllDay &&
        !event.isNew)
    )
  },
  isEventForHourlyGrouping(event: ICalendarEntry, currentDay: Moment) {
    return (
      this.isEventSingleDay(event) ||
      (this.isEventMultiDay(event) &&
        event.startTime.date() == currentDay.utc().date())
    )
  },
  isEventAllDay(event: ICalendarEntry, currentDay: Moment) {
    return (
      event.isAllDay ||
      (this.isEventMultiDay(event) &&
        event.startTime.isBefore(currentDay.utc()))
    )
  },
  isEventInDay(event: ICalendarEntry, day: string, timezone: string) {
    // if recurring, make sure the start date is the same as the request date
    if (event.isRecurring) {
      event.startTime = event.isAllDay
        ? moment.utc(event.startTime)
        : moment.utc(event.startTime).tz(timezone)
      return event.startTime.date() == moment.utc(day).date()
    }
    return true
  },
  isEventUnseen(event: ICalendarEntry) {
    return event.isNew
  },
  isMultiDay(event: ICalendarEntry) {
    return !event.endTime.isSame(event.startTime, 'days')
  },
  isSingleDay(event: ICalendarEntry) {
    return event.endTime.isSame(event.startTime, 'days')
  },
  isCalendarDayInWeek(start: Moment, end: Moment, calDate: any) {
    const _calDate = moment(calDate, 'YYYY-MM-DD')
    const result =
      _calDate.isSameOrAfter(start, 'day') && _calDate.isBefore(end)
    return result
  },
  areObjPropsEqual(object1: any, object2: any) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      return false
    }
    for (const key of keys1) {
      if (moment.isMoment(object1[key])) {
        if (!object1[key].isSame(object2[key], 'minute')) {
          return false
        }
      } else {
        if (object1[key] !== object2[key]) {
          return false
        }
      }
    }
    return true
  },
  getStartDateFromParamsForApiCall(dateAsMoment: Moment) {
    const startDate = dateAsMoment.startOf('month').day('Sunday').startOf('day')
    return startDate.utc().format('YYYY-MM-DD HH:mm:ss')
  },
  getEndDateFromParamsForApiCall(dateAsMoment: Moment) {
    const endDate = dateAsMoment.endOf('month').day('Saturday').endOf('day')
    return endDate.utc().format('YYYY-MM-DD HH:mm:ss')
  }
}
