import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'
import type {
  IBuyMinutesPostRequest,
  ICallStatusCallback,
  ICompleteCallingSetupRequest,
  IGatherRequest,
  IFirstLegPostRequest,
  IGetCallsRequest,
  IGetVoicemailsRequest,
  ISendCoparentReminderRequest,
  ISetAllowsVideoCallsRequest,
  ITwilioProxyRecordingCallback,
  INewSessionParam,
  IJoinRoomRequest,
  ICompleteRoomRequest,
  IUpdateCallRequest,
  IVideoCall,
  ICallingItem,
  ICallLogRecordingInfo,
  ISegment,
  ICallLog,
  IPhoneCallDetails,
  ICoparentCallingSettings,
  IVideoCallDetails,
  IUpdateVideoCallStatusRequest,
  ILogActionRequest,
  ISingleCallReportParam,
  ICallingDownload,
  WorkerRequest
} from '@/models/interfaces'
import httpClient from '@/httpClient'
import ErrorHelper from '../exports/error'
import type {
  ICreateRoomRequest,
  ICreateRoomResult,
  IJoinRoomResult
} from '@/models/modelsV3'
import helpers from '@/exports/helper'
import { isSupported } from 'twilio-video'
import {
  CallingCallDirection,
  CallingCallType,
  CallingFilter
} from '@/models/enums'
import moment from 'moment'
import { useAccountSettingsStore } from './AccountSettingsStore'
import { useCommonStore } from './CommonStore'
import constants from '@/exports/constants'
import WorkerService from '@/services/webWorkerService'
import fileDownloaderWorker from '../workers/fileDownloader?worker&url'
import { useModals } from '@/composables/useModal/useModal'
import type { DefaultNumberFormatSchema } from 'vue-i18n'

const baseUrl = '/mobile/api'

const callingControllerBaseUrl = `${baseUrl}/calling`
const buyMinutesControllerBaseUrl = `${baseUrl}/buyminutes`
const callLogControllerBaseUrl = `${baseUrl}/calllog`
const firstLegControllerBaseUrl = `${baseUrl}/firstleg`
const outOfSessionControllerBaseUrl = `${baseUrl}/outofsession`
const recordingControllerBaseUrl = `${baseUrl}/recording`
const secondLegControllerBaseUrl = `${baseUrl}/secondleg`
const sessionControllerBaseUrl = `${baseUrl}/session`

const videoCallingControllerBaseUrl = `${baseUrl}/videocalling`

const singleCallReportControllerBaseUrl = `${baseUrl}/singlecallreport`
const singleVideoCallReportControllerBaseUrl = `${baseUrl}/singlevideocallreport`
const singleVoicemailReportControllerBaseUrl = `${baseUrl}/singlevoicemailreport`

export const useCallingStore = defineStore(
  'calling',
  () => {
    //Options syntax, Composition syntax
    //state, ref
    const showVideoCall = ref<boolean>(false)
    const videoCallCheckId = ref<number | null>(null)
    const videoCallThreadId = ref<number>(0)
    const callThreadId = ref<number>(0)
    const startVideoCall = ref<boolean>(false)
    const roomResult = ref<ICreateRoomResult | null>(null)
    const activeCall = ref<IVideoCall | null>(null)
    const canCallStatus = ref<Map<string, boolean>>(
      new Map([
        [constants.CAN_CALL_STATUS.allowed, false],
        [constants.CAN_CALL_STATUS.coParentIssue, false],
        [constants.CAN_CALL_STATUS.noMinutes, false],
        [constants.CAN_CALL_STATUS.notPremium, false],
        [constants.CAN_CALL_STATUS.phoneCallingDisabled, false],
        [constants.CAN_CALL_STATUS.videoCallingDisabled, false]
      ])
    )
    const callingItems = ref<ICallingItem[]>([
      {
        callType: CallingCallType.Phone,
        direction: CallingCallDirection.Outgoing,
        itemID: 0,
        caseID: 0,
        userID: 0,
        toUserID: 0,
        createdWhen: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        viewedWhen: new Date(),
        status: '',
        isMissedCall: false,
        isUnseen: false,
        callerName: '',
        isOutgoing: false,
        callStatus: '',
        missedCall: false,
        callLength: '',
        hasMessageThread: false,
        displayDate: '',
        skeletonLoading: true
      },
      {
        callType: CallingCallType.Phone,
        direction: CallingCallDirection.Outgoing,
        itemID: 0,
        caseID: 0,
        userID: 0,
        toUserID: 0,
        createdWhen: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        viewedWhen: new Date(),
        status: '',
        isMissedCall: false,
        isUnseen: false,
        callerName: '',
        isOutgoing: false,
        callStatus: '',
        missedCall: false,
        callLength: '',
        hasMessageThread: false,
        displayDate: '',
        skeletonLoading: true
      },
      {
        callType: CallingCallType.Phone,
        direction: CallingCallDirection.Outgoing,
        itemID: 0,
        caseID: 0,
        userID: 0,
        toUserID: 0,
        createdWhen: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        viewedWhen: new Date(),
        status: '',
        isMissedCall: false,
        isUnseen: false,
        callerName: '',
        isOutgoing: false,
        callStatus: '',
        missedCall: false,
        callLength: '',
        hasMessageThread: false,
        displayDate: '',
        skeletonLoading: true
      }
    ])
    const callingPageSize = ref<number>(25)
    const callingPageNumber = ref<number>(1)
    const loadMoreCallingItems = ref<boolean>(false)
    const selectedCallingItem = ref<ICallingItem | null>(null)
    const selectedCallingFilter = ref<CallingFilter>(CallingFilter.All)
    const callingSearchTerm = ref<string>('')
    const coparentCallingSettings = ref<ICoparentCallingSettings | null>(null)

    const showDetailsMenu = ref<boolean>(false)

    const hasCompletedCallingSetup = ref<boolean>(true)
    const callingSetupSuccess = ref<boolean>(false)
    const sentReminderWhen = ref<Date | null>(null)
    const showAudioCall = ref<boolean>(false)
    const proxyPhoneNumber = ref<number>(0)

    const downloads = ref<ICallingDownload[] | null>(null)

    const videoInputId = ref<string>('')
    const audioInputId = ref<string>('')
    const audioOutputId = ref<string>('')

    const unseenVoicemailCount = ref<number>(0)
    //getters, computed

    const newCallIsAvailable = computed(() => {
      return activeCall.value
    })

    const isBrowserSupportedForTwilio = computed(() => {
      return isSupported
    })

    const groupedCallingItems = computed(() => {
      const groupedMap = new Map()

      for (const item of callingItems.value) {
        const startOfDay = moment(item.createdWhen).startOf('day').format()

        let thisList = groupedMap.get(startOfDay)
        if (thisList === undefined) {
          thisList = []
          groupedMap.set(startOfDay, thisList)
        }
        thisList.push(item)
      }

      return Array.from(groupedMap.values()) as ICallingItem[][]
    })

    const isActiveCallIncoming = computed(() => {
      const commonStore = useCommonStore()
      return activeCall.value?.toUserID == commonStore.fullUserInfo.userId
    })

    const initialLoad = computed(
      () => !!callingItems.value.find((ci) => ci.skeletonLoading)
    )

    //actions, functions

    function isFirstInGroup(itemID: number) {
      const foo = groupedCallingItems.value.reduce((a, val) => {
        a.push(val[0])
        return a
      }, [])
      return foo.find((i) => i.itemID == itemID) != undefined
    }

    function startCheckingForNewCall() {
      videoCallCheckId.value = setInterval(() => {
        getActiveVideoCall()
      }, 1000 * 60) as unknown as number
    }

    function setShowVideoCall(val: boolean) {
      showVideoCall.value = val
    }

    function setStartVideoCall(val: boolean) {
      startVideoCall.value = val
    }

    function setCallingItems(items: ICallingItem[]) {
      callingItems.value = items
    }

    function resetCallingItems() {
      setCallingItems([
        {
          callType: CallingCallType.Phone,
          direction: CallingCallDirection.Outgoing,
          itemID: 0,
          caseID: 0,
          userID: 0,
          toUserID: 0,
          createdWhen: new Date(),
          startTime: new Date(),
          endTime: new Date(),
          viewedWhen: new Date(),
          status: '',
          isMissedCall: false,
          isUnseen: false,
          callerName: '',
          isOutgoing: false,
          callStatus: '',
          missedCall: false,
          callLength: '',
          hasMessageThread: false,
          displayDate: '',
          skeletonLoading: true
        },
        {
          callType: CallingCallType.Phone,
          direction: CallingCallDirection.Outgoing,
          itemID: 0,
          caseID: 0,
          userID: 0,
          toUserID: 0,
          createdWhen: new Date(),
          startTime: new Date(),
          endTime: new Date(),
          viewedWhen: new Date(),
          status: '',
          isMissedCall: false,
          isUnseen: false,
          callerName: '',
          isOutgoing: false,
          callStatus: '',
          missedCall: false,
          callLength: '',
          hasMessageThread: false,
          displayDate: '',
          skeletonLoading: true
        },
        {
          callType: CallingCallType.Phone,
          direction: CallingCallDirection.Outgoing,
          itemID: 0,
          caseID: 0,
          userID: 0,
          toUserID: 0,
          createdWhen: new Date(),
          startTime: new Date(),
          endTime: new Date(),
          viewedWhen: new Date(),
          status: '',
          isMissedCall: false,
          isUnseen: false,
          callerName: '',
          isOutgoing: false,
          callStatus: '',
          missedCall: false,
          callLength: '',
          hasMessageThread: false,
          displayDate: '',
          skeletonLoading: true
        }
      ])
    }

    function setSelectedCallingItem(item: ICallingItem | null) {
      selectedCallingItem.value = item
    }

    function setLoadMoreCallingItems(loadMore: boolean) {
      loadMoreCallingItems.value = loadMore
    }

    function setSelectedCallingFilter(filter: CallingFilter) {
      selectedCallingFilter.value = filter
    }

    function setPageNumber(page: number) {
      callingPageNumber.value = page
    }

    function setActiveCall(payload: IVideoCall | null) {
      activeCall.value = payload
    }

    function setHasCompletedCallingSetup(hasCompleted: boolean) {
      hasCompletedCallingSetup.value = hasCompleted
    }

    function setCallingSetupSuccess(success: boolean) {
      callingSetupSuccess.value = success
    }

    function setSentReminderWhen(date: Date | null) {
      sentReminderWhen.value = date
    }

    function setShowAudioCall(show: boolean) {
      showAudioCall.value = show
    }

    async function fetchCallingItems() {
      //page number is zero-based
      const items = (
        await fetchCalls({
          filter: selectedCallingFilter.value,
          pageNumber: callingPageNumber.value - 1,
          searchTerm: callingSearchTerm.value,
          pageSize: callingPageSize.value
        })
      )?.data.calls as ICallingItem[]

      //first page? overwrite, otherwise, append
      setCallingItems(
        callingPageNumber.value == 1 ? items : [...callingItems.value, ...items]
      )
      setLoadMoreCallingItems(items.length == callingPageSize.value)
    }

    async function fetchMoreCallingItems() {
      await fetchCallingItemsByPage(++callingPageNumber.value)
    }

    async function fetchCallingItemsByPage(page: number) {
      setPageNumber(page)

      await fetchCallingItems()
    }

    watch([callingSearchTerm, selectedCallingFilter], async () => {
      setSelectedCallingItem(null)
      resetCallingItems()
      await fetchCallingItemsByPage(1)
    })

    watch(callingItems, async (val) => {
      if (Array.isArray(val) && val.length > 0) {
        const commonStore = useCommonStore()
        commonStore.setShowingNoCount(false)
      } else if (
        selectedCallingFilter.value == CallingFilter.All &&
        !callingSearchTerm.value.length
      ) {
        const commonStore = useCommonStore()
        commonStore.setShowingNoCount(true)
      }
    })

    async function fetchPhoneCallDetails(item: ICallingItem) {
      const callLog = (await fetchCallLog(item.itemID))?.data as ICallLog

      const accountSettingsStore = useAccountSettingsStore()

      //don't want to do unnecessary API calls
      if (accountSettingsStore.subscriptionT2) {
        const recordingInfo = (await fetchCallLogRecordingInfo(item.itemID))
          ?.data.value as ICallLogRecordingInfo

        callLog.recordingSid = recordingInfo.recordingSid
        callLog.recordingDuration = recordingInfo.recordingDuration

        const segments = (await fetchTranscriptSegments(item.itemID))?.data
          .value as ISegment[]

        // this only works for older calling items i think
        const jobStatus = (await fetchTranscriptJobStatus(callLog.recordingSid))
          ?.data as string

        return {
          callLog: callLog,
          segments: segments,
          transcriptJobStatus: jobStatus
        } as IPhoneCallDetails
      }

      return {
        callLog: callLog,
        segments: null,
        transcriptJobStatus: null
      } as IPhoneCallDetails
    }

    async function fetchVideoCallDetails(item: ICallingItem) {
      const call =
        item.callType == CallingCallType.Video
          ? ((await getVideoCall(item.itemID)) as IVideoCall)
          : ((await getVideoVoicemail(item.itemID)) as IVideoCall)

      const accountSettingsStore = useAccountSettingsStore()

      if (accountSettingsStore.subscriptionT2) {
        let segments = null
        let recording = null

        switch (item.callType) {
          case CallingCallType.Video: {
            segments = await getVideoCallTranscript(item.itemID)
            recording = await getVideoCallRecording(item.itemID)

            return {
              videoCall: call,
              segments: segments,
              recording: recording
            } as IVideoCallDetails
          }
          case CallingCallType.Voicemail: {
            segments = await getVideoVoicemailTranscript(item.itemID)
            recording = await getVideoVoicemailRecording(item.itemID)
            updateVoicemailIsUnseenListItem(item.itemID, false)
            if (unseenVoicemailCount.value > 0 && !item.viewedWhen) {
              unseenVoicemailCount.value -= 1
            }

            return {
              videoCall: call,
              segments: segments,
              recording: recording
            } as IVideoCallDetails
          }
        }
        // standard users can see voicemail recordings, no need to block free users bc theyre paywalled from all calling
      } else if (item.callType == CallingCallType.Voicemail) {
        const recording = await getVideoVoicemailRecording(item.itemID)
        updateVoicemailIsUnseenListItem(item.itemID, false)
        if (unseenVoicemailCount.value > 0 && !item.viewedWhen) {
          unseenVoicemailCount.value -= 1
        }

        return {
          videoCall: call,
          segments: null,
          recording: recording
        } as IVideoCallDetails
      }

      return {
        videoCall: call,
        segments: null,
        recording: null
      } as IVideoCallDetails
    }

    function setShowDetailsMenu(show: boolean) {
      showDetailsMenu.value = show
    }

    function updateVoicemailIsUnseenListItem(itemID: number, unseen: boolean) {
      if (Array.isArray(callingItems.value)) {
        // index and length will be offset if the user has cancelled an upload in the queue
        const _index = callingItems.value.findIndex((c) => c.itemID == itemID)
        if (_index !== -1) {
          callingItems.value[_index].isUnseen = unseen
        }
      }
    }

    function setCallingSearchTerm(searchTerm: string) {
      callingSearchTerm.value = searchTerm
    }

    // API Calls
    //#region CallingController
    async function fetchCalls(payload: IGetCallsRequest) {
      const endpoint = `${callingControllerBaseUrl}/getcalls?Filter=${payload.filter}&PageNumber=${payload.pageNumber}&SearchTerm=${payload.searchTerm}&PageSize=${payload.pageSize}`

      try {
        return await httpClient.get(endpoint, {
          headers: { 'api-version': 3 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCalls')
      }
    }

    async function fetchVoicemails(payload: IGetVoicemailsRequest) {
      const endpoint = `${callingControllerBaseUrl}/getvoicemails?PageNumber=${payload.pageNumber}&SearchTerm=${payload.searchTerm}`

      try {
        return await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchVoicemails')
      }
    }

    async function fetchUnseenVoicemails() {
      const endpoint = `${callingControllerBaseUrl}/getunseenvoicemails`

      try {
        return await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchUnseenVoicemails')
      }
    }

    async function fetchCoparentCallingSettings() {
      const endpoint = `${callingControllerBaseUrl}/getcoparentcallingsettings`

      try {
        const results = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        coparentCallingSettings.value = results.data
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCoparentCallingSettings')
      }
    }

    //#region calling setup
    async function fetchHasCompleteCallingSetup() {
      const endpoint = `${callingControllerBaseUrl}/gethascompletecallingsetup`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        return result.data.hasCompleteCallingSetup
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchHasCompleteCallingSetup')
      }
    }

    async function completeCallingSetup(payload: ICompleteCallingSetupRequest) {
      const endpoint = `${callingControllerBaseUrl}/completecallingsetup`

      try {
        const result = await httpClient.post(endpoint, payload, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'completeCallingSetup')
      }
    }

    async function skipCallingSetup() {
      const endpoint = `${callingControllerBaseUrl}/skipcallingsetup`

      try {
        return await httpClient.post(endpoint, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'skipCallingSetup')
      }
    }

    async function sendCoparentReminder(payload: ISendCoparentReminderRequest) {
      const endpoint = `${callingControllerBaseUrl}/sendcoparentreminder`

      try {
        return await httpClient.post(endpoint, payload, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'sendCoparentReminder')
      }
    }
    //#endregion calling setup

    //#region enable/get allows video calls
    async function fetchAllowsVideoCalls() {
      const endpoint = `${callingControllerBaseUrl}/fetchallowsvideocalls`

      try {
        return await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchAllowsVideoCalls')
      }
    }

    async function setAllowsVideoCalls(payload: ISetAllowsVideoCallsRequest) {
      const endpoint = `${callingControllerBaseUrl}/setallowsvideocalls`

      try {
        return await httpClient.post(endpoint, payload, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'setAllowsVideoCalls')
      }
    }
    //#endregion enable/get allows video calls
    //#endregion CallingController

    //#region PhoneCalling/BuyMinutesController
    async function buyMinutesPost(payload: IBuyMinutesPostRequest) {
      const endpoint = buyMinutesControllerBaseUrl

      try {
        return await httpClient.post(endpoint, payload)
      } catch (e) {
        ErrorHelper.handleError(e, 'buyMinutes')
      }
    }

    async function fetchCallAddonMinutesPlans() {
      const endpoint = `${buyMinutesControllerBaseUrl}/getcalladdonminutesplans`

      try {
        return await httpClient.get(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCallAddonMinutesPlans')
      }
    }
    //#endregion

    //#region PhoneCalling/CallLogController
    async function fetchCallLogs() {
      const endpoint = `${callLogControllerBaseUrl}/getcalllogs`

      try {
        return await httpClient.get(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCallLogs')
      }
    }

    async function fetchCallLog(callLogItemId: number) {
      const endpoint = `${callLogControllerBaseUrl}/getcalllog?callLogItemID=${callLogItemId}`

      try {
        return await httpClient.get(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCallLog')
      }
    }

    async function fetchCallLogRecordingInfo(callLogItemId: number) {
      const endpoint = `${callLogControllerBaseUrl}/getcalllogrecordinginfo?callLogItemID=${callLogItemId}`

      try {
        return await httpClient.get(endpoint, { headers: { 'api-version': 2 } })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCallLogRecordingInfo')
      }
    }

    async function fetchRecordingSasUrl(callLogItemId: number) {
      const endpoint = `${callLogControllerBaseUrl}/getrecordingsasurl?callLogItemID=${callLogItemId}`

      try {
        return await httpClient.get(endpoint, { headers: { 'api-version': 2 } })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchRecordingSasUrl')
      }
    }

    async function fetchTranscriptSegments(callLogItemId: number) {
      const endpoint = `${callLogControllerBaseUrl}/gettranscriptsegments?callLogItemID=${callLogItemId}`

      try {
        return await httpClient.get(endpoint, { headers: { 'api-version': 2 } })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchTranscriptSegments')
      }
    }

    async function fetchTranscriptJobStatus(recordingSid: string) {
      const endpoint = `${callLogControllerBaseUrl}/gettranscriptjobstatus?recordingSid=${recordingSid}`

      try {
        return await httpClient.get(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchTranscriptJobStatus')
      }
    }
    //#endregion

    //#region PhoneCalling/FirstLegController
    async function firstLegPost(payload: IFirstLegPostRequest) {
      const endpoint = `${firstLegControllerBaseUrl}?session=${payload.session}&userToNumber=${payload.userToNumber}`

      try {
        return await httpClient.post(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'firstLegPost')
      }
    }

    async function firstLegGather(
      gatherRequest: IGatherRequest,
      session: string,
      attempt: number,
      userToNumber: string,
      proxyNumber: string
    ) {
      const endpoint = `${firstLegControllerBaseUrl}/firstleggather?session=${session}&attempt=${attempt}&userToNumber=${userToNumber}&proxyNumber=${proxyNumber}`

      try {
        return await httpClient.post(endpoint, gatherRequest)
      } catch (e) {
        ErrorHelper.handleError(e, 'firstLegGather')
      }
    }

    async function firstLegHold(
      session: string,
      userToNumber: string,
      proxyNumber: string
    ) {
      const endpoint = `${firstLegControllerBaseUrl}/firstleghold?session=${session}&userToNumber=${userToNumber}&proxyNumber=${proxyNumber}`

      try {
        return await httpClient.post(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'firstLegHold')
      }
    }

    async function firstLegConnect() {
      const endpoint = `${firstLegControllerBaseUrl}/firstlegconnect`

      try {
        return await httpClient.post(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'firstLegConnect')
      }
    }

    async function firstLegCallback(
      v: ICallStatusCallback,
      sessionSid: string
    ) {
      const endpoint = `${firstLegControllerBaseUrl}/firstlegcallback?sessionSid=${sessionSid}`

      try {
        return await httpClient.post(endpoint, v)
      } catch (e) {
        ErrorHelper.handleError(e, 'firstLegCallback')
      }
    }
    //#endregion FirstLegController

    //#region PhoneCalling/OutOfSessionController
    async function outOfSessionPost() {
      const endpoint = outOfSessionControllerBaseUrl

      try {
        return await httpClient.post(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'outOfSessionPost')
      }
    }
    //#endregion PhoneCalling/OutOfSessionController

    //#region PhoneCalling/RecordingController
    async function recordingCallback(
      v: ITwilioProxyRecordingCallback,
      sessionSid: string
    ) {
      const endpoint = `${recordingControllerBaseUrl}/recordingCallback?sessionSid=${sessionSid}`

      try {
        return await httpClient.post(endpoint, v)
      } catch (e) {
        ErrorHelper.handleError(e, 'recordingCallback')
      }
    }

    async function fetchDownloadUrl(nItemID: number) {
      const endpoint = `${recordingControllerBaseUrl}/getDownloadUrl?nItemID=${nItemID}`

      try {
        return await httpClient.get(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchDownloadUrl')
      }
    }

    async function fetchPreviewdUrl(nItemID: number) {
      const endpoint = `${recordingControllerBaseUrl}/getPreviewdUrl?nItemID=${nItemID}`

      try {
        return await httpClient.get(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchPreviewdUrl')
      }
    }
    //#endregion PhoneCalling/RecordingController

    //#region PhoneCalling/SecondLegController
    async function secondLegPost(session: string, attempt: number) {
      const endpoint = `${secondLegControllerBaseUrl}?session=${session}&attempt=${attempt}}`

      try {
        return await httpClient.post(endpoint)
      } catch (e) {
        ErrorHelper.handleError(e, 'secondLegPost')
      }
    }

    async function secondLegGather(
      gatherRequest: IGatherRequest,
      session: string,
      attempt: number,
      userToNumber: string,
      proxyNumber: string
    ) {
      const endpoint = `${secondLegControllerBaseUrl}/secondleggather?session=${session}&attempt=${attempt}&userToNumber=${userToNumber}&proxyNumber=${proxyNumber}`

      try {
        return await httpClient.post(endpoint, gatherRequest)
      } catch (e) {
        ErrorHelper.handleError(e, 'secondLegGather')
      }
    }

    async function secondLegCallback(
      v: ICallStatusCallback,
      sessionSid: string
    ) {
      const endpoint = `${secondLegControllerBaseUrl}/secondlegcallback?sessionSid=${sessionSid}`

      try {
        return await httpClient.post(endpoint, v)
      } catch (e) {
        ErrorHelper.handleError(e, 'secondLegCallback')
      }
    }
    //#endregion PhoneCalling/SecondLegController

    //#region PhoneCalling/SessionController
    async function sessionPost(nsp: INewSessionParam) {
      const endpoint = sessionControllerBaseUrl

      try {
        const result = (
          await httpClient.post(endpoint, nsp, {
            headers: { 'api-version': 2 }
          })
        ).data

        if (result) {
          if (result.success) {
            proxyPhoneNumber.value = result.value
            return proxyPhoneNumber.value
          }
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'sessionPost')
      }
    }
    //#endregion PhoneCalling/SessionController

    //#region VideoCalling/VideoCallingController
    async function createRoom(request: ICreateRoomRequest) {
      const endpoint = `${videoCallingControllerBaseUrl}/createRoom`

      try {
        const result = await httpClient.post(endpoint, request, {
          headers: { 'api-version': 3 }
        })
        roomResult.value = result.data
        return true
      } catch (e) {
        ErrorHelper.handleError(e, 'createRoom')
        return false
      }
    }

    async function getActiveVideoCall() {
      const endpoint = `${videoCallingControllerBaseUrl}/GetActiveVideoCall`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        if (result) {
          setActiveCall(result.data.value)
        }
        return true
      } catch (e) {
        ErrorHelper.handleError(e, 'getActiveVideoCall')
        return false
      }
    }

    async function joinRoom(
      request: IJoinRoomRequest
    ): Promise<IJoinRoomResult | undefined> {
      const endpoint = `${videoCallingControllerBaseUrl}/joinRoom?joinVideoCallItemID=${request.joinVideoCallItemID}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 3 }
        })
        if (result) {
          roomResult.value = result.data
        }
        return result.data as IJoinRoomResult
      } catch (e) {
        ErrorHelper.handleError(e, 'joinRoom')
      }
    }

    async function completeRoom(request: ICompleteRoomRequest) {
      const endpoint = `${videoCallingControllerBaseUrl}/completeRoom?type=${request.type}&itemID=${request.itemID}`

      try {
        return await httpClient.get(endpoint, {
          headers: { 'api-version': 3 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'completeRoom')
      }
    }

    async function updateCall(request: IUpdateCallRequest) {
      const endpoint = `${videoCallingControllerBaseUrl}/updateCall?itemID=${request.itemID}&callType=${request.callType}&newStatus=${request.newStatus}&action=${request.action}&actionData=${request.actionData}`

      try {
        return await httpClient.get(endpoint, {
          headers: { 'api-version': 3 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'updateCall')
      }
    }

    async function updateVideoCallStatus(
      request: IUpdateVideoCallStatusRequest
    ) {
      const endpoint = `${videoCallingControllerBaseUrl}/updateVideoCallStatus?videoCallId=${request.videoCallId}&newStatus=${request.newStatus}`

      try {
        return await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'updateVideoCallStatus')
      }
    }

    async function logAction(payload: ILogActionRequest) {
      const endpoint = `${videoCallingControllerBaseUrl}/LogAction`

      try {
        return await httpClient.post(endpoint, payload, {
          headers: { 'api-version': 2 }
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'completeCallingSetup')
      }
    }

    async function getVideoCall(itemID: number) {
      const endpoint = `${videoCallingControllerBaseUrl}/GetVideoCall?ItemID=${itemID}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        if (result) {
          return result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'GetVideoCallRecording')
        return false
      }
    }

    async function getVideoCallRecording(itemId: number) {
      const endpoint = `${videoCallingControllerBaseUrl}/GetVideoCallRecording?ItemID=${itemId}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })

        if (result) {
          return result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'getVideoCallRecording')
      }
    }

    async function getVideoVoicemailRecording(itemId: number) {
      const endpoint = `${videoCallingControllerBaseUrl}/GetVideoVoicemailRecording?ItemID=${itemId}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })

        if (result) {
          return result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'getVideoCallRecording')
      }
    }

    async function getActiveVideoCallByItemID(itemID: number) {
      const endpoint = `${videoCallingControllerBaseUrl}/GetVideoCall?ItemID=${itemID}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        if (result) {
          setActiveCall(result.data.value)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'GetVideoCallRecording')
        return false
      }
    }
    // works for voicemails
    async function getVideoCallRecordingURL(channelID: string) {
      const endpoint = `${videoCallingControllerBaseUrl}/GetRecordingUrl?Channel=${channelID}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        if (result) {
          return result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'GetRecordingUrl')
        return false
      }
    }

    async function getVideoCallTranscript(itemID: number) {
      const endpoint = `${videoCallingControllerBaseUrl}/GetVideoCallTranscript?ItemID=${itemID}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        if (result) {
          return result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'GetVideoCallTranscript')
        return false
      }
    }

    //#endregion VideoCalling/VideoCallingController
    //#region Video Voicemails
    async function getVideoVoicemail(itemID: number) {
      const endpoint = `${videoCallingControllerBaseUrl}/GetVideoVoicemail?ItemID=${itemID}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        if (result) {
          return result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'GetVideoVoicemail')
        return false
      }
    }

    async function getVideoVoicemailTranscript(itemID: number) {
      const endpoint = `${videoCallingControllerBaseUrl}/GetVideoVoicemailTranscript?ItemID=${itemID}`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        if (result) {
          return result.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'GetVideoVoicemailTranscript')
        return false
      }
    }

    async function fetchUnseenVoicemailCount() {
      const endpoint = `${videoCallingControllerBaseUrl}/GetUnseenVoicemailCount`

      try {
        const result = await httpClient.get(endpoint, {
          headers: { 'api-version': 2 }
        })
        if (result) {
          unseenVoicemailCount.value = result.data.unseenVoicemailCount
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'GetVideoVoicemailTranscript')
        return false
      }
    }
    //#endregion

    //#region Audio
    async function bothParentsPassPrecheck() {
      resetCanCallStatus()
      setCanCallStatus(constants.CAN_CALL_STATUS.allowed, true) // good

      const accountSettingsStore = useAccountSettingsStore()

      await accountSettingsStore.fetchSubscriptionInfo()
      // fetch phone info
      await accountSettingsStore.fetchPhoneInfo()
      // fetch calling balance
      await accountSettingsStore.fetchCallingBalance()
      // fetch coparent settings
      await fetchCoparentCallingSettings()

      if (!accountSettingsStore.subscriptionT2) {
        setCanCallStatus(constants.CAN_CALL_STATUS.notPremium, true) // subscription tier
        setCanCallStatus(constants.CAN_CALL_STATUS.allowed, false)
      }

      if (
        !(
          accountSettingsStore.callingBalance &&
          accountSettingsStore.callingBalance > 0
        )
      ) {
        setCanCallStatus(constants.CAN_CALL_STATUS.noMinutes, true) // not enough minutes
        setCanCallStatus(constants.CAN_CALL_STATUS.allowed, false)
      }

      if (
        showAudioCall.value &&
        (!accountSettingsStore.phoneInfo.allowCallsFromMatchedUser ||
          !accountSettingsStore.phoneInfo.isUserMobilePhoneVerified)
      ) {
        setCanCallStatus(constants.CAN_CALL_STATUS.phoneCallingDisabled, true) // disabled phone calling
        setCanCallStatus(constants.CAN_CALL_STATUS.allowed, false) // if checking for video call, this status does not matter
      }

      if (
        showVideoCall.value &&
        !accountSettingsStore.phoneInfo.allowsVideoCalls
      ) {
        setCanCallStatus(constants.CAN_CALL_STATUS.videoCallingDisabled, true) // disabled phone calling
        setCanCallStatus(constants.CAN_CALL_STATUS.allowed, false)
      }

      if (
        showAudioCall.value &&
        !coparentCallingSettings.value?.allowsPhoneCalls
      ) {
        setCanCallStatus(constants.CAN_CALL_STATUS.coParentIssue, true) // coparent issue
        setCanCallStatus(constants.CAN_CALL_STATUS.allowed, false)
      }

      if (
        showVideoCall.value &&
        !coparentCallingSettings.value?.allowsVideoCalls
      ) {
        setCanCallStatus(constants.CAN_CALL_STATUS.coParentIssue, true) // coparent issue
        setCanCallStatus(constants.CAN_CALL_STATUS.allowed, false)
      }
    }
    //#endregion

    //#region SingleCallReportController
    async function singleCallReportPost(p: ISingleCallReportParam) {
      try {
        const result = (
          await httpClient.post(singleCallReportControllerBaseUrl, p, {
            headers: { 'api-version': 2 }
          })
        ).data

        return result.success
      } catch (e) {
        ErrorHelper.handleError(e, 'singleCallReportPost')
        return false
      }
    }
    //#endregion SingleCallReportController
    //#region SingleVideoCallReportController
    async function singleVideoCallReportPost(p: ISingleCallReportParam) {
      try {
        const result = (
          await httpClient.post(singleVideoCallReportControllerBaseUrl, p, {
            headers: { 'api-version': 2 }
          })
        ).data

        return result.success
      } catch (e) {
        ErrorHelper.handleError(e, 'singleVideoCallReportPost')
        return false
      }
    }
    //#endregion SingleVideoCallReportController
    //#region SingleVoicemailReportController
    async function singleVoicemailReportPost(p: ISingleCallReportParam) {
      try {
        const result = (
          await httpClient.post(singleVoicemailReportControllerBaseUrl, p, {
            headers: { 'api-version': 2 }
          })
        ).data

        return result.success
      } catch (e) {
        ErrorHelper.handleError(e, 'singleVoicemailReportPost')
        return false
      }
    }
    //#endregion SingleVoicemailReportController

    function startDownload(
      url: string,
      fileName: string,
      callingItem: ICallingItem
    ) {
      const id = Date.now().toString() // Generate a unique ID for the download

      WorkerService.createWorker(id, fileDownloaderWorker)
      const options: RequestInit = {}
      const request: WorkerRequest = { url, options, id }

      WorkerService.postRequest(id, request, (response) => {
        if (response.success) {
          if (response.data.status === 'progress') {
            if (response.data.id === id) {
              const download = getDownloadFromList(id)
              if (download) {
                download.progress = response.data.progress
              }
            }
          } else if (response.data.status === 'completed') {
            const download = getDownloadFromList(id)
            if (download) {
              handleDownloadedFile(response.data.data, download.fileName)
              download.status = constants.vaultDownloadStatus.completed
            }
            WorkerService.terminateWorker(id)
          } else if (response.data.status === 'error') {
            const download = getDownloadFromList(id)
            if (download) {
              download.status = constants.vaultDownloadStatus.failed
            }
            WorkerService.terminateWorker(id)
          }
        } else {
          const download = getDownloadFromList(id)
          if (download) {
            download.status = constants.vaultDownloadStatus.failed
          }
        }
      })

      downloads.value = [
        ...(downloads.value ?? []),
        ...[
          {
            id,
            url,
            progress: 0,
            fileName,
            status: constants.vaultDownloadStatus.downloading,
            callingItem: callingItem
          }
        ]
      ]
    }

    function reDownload(existingFile: ICallingDownload) {
      //const workerUrl = new URL('../workers/fileDownloader.ts', import.meta.url)
      WorkerService.createWorker(existingFile.id, fileDownloaderWorker)
      const options: RequestInit = {}
      const request: WorkerRequest = {
        url: existingFile.url,
        options,
        id: existingFile.id
      }

      WorkerService.postRequest(existingFile.id, request, (response) => {
        if (response.success) {
          if (response.data.status === 'progress') {
            if (response.data.id === existingFile.id) {
              const download = getDownloadFromList(existingFile.id)
              if (download) {
                download.progress = response.data.progress
              }
            }
          } else if (response.data.status === 'completed') {
            const download = getDownloadFromList(existingFile.id)
            if (download) {
              handleDownloadedFile(response.data.data, download.fileName)
              download.status = constants.vaultDownloadStatus.completed
            }
            WorkerService.terminateWorker(existingFile.id)
          } else if (response.data.status === 'error') {
            const download = getDownloadFromList(existingFile.id)
            if (download) {
              download.status = constants.vaultDownloadStatus.failed
            }
            WorkerService.terminateWorker(existingFile.id)
          }
        } else {
          const download = getDownloadFromList(existingFile.id)
          if (download) {
            download.status = constants.vaultDownloadStatus.failed
          }
        }
      })
    }

    function handleDownloadedFile(blob: Blob, fileName: string) {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }

    function stopDownload(id: string) {
      const download = getDownloadFromList(id)
      if (download) {
        download.status = constants.vaultDownloadStatus.stopped
        WorkerService.terminateWorker(id)
      }
    }

    function removeDownloadFromList(id: string) {
      if (!downloads.value) {
        return
      }

      const downloadIndex = downloads.value.findIndex((d) => d.id === id)
      if (downloadIndex >= 0) {
        WorkerService.terminateWorker(id)
        downloads.value.splice(downloadIndex, 1)
      }
    }

    function getDownloadFromList(id: string) {
      return downloads.value?.find((d) => d.id === id)
    }

    function removeAllDownloads() {
      downloads.value?.forEach((d) => {
        if (d.status == constants.vaultDownloadStatus.downloading) {
          WorkerService.terminateWorker(d.id)
        }
      })
      downloads.value = null
    }

    const getInProgressDownloads = computed(() => {
      return downloads.value?.filter(
        (d) => d.status == constants.vaultDownloadStatus.downloading
      )
    })

    // download modals
    function cancelAllDownloads() {
      const downloadString = `<span class="place-center">Some items are still downloading. Are you sure you want to stop all of them?</span>`

      if (getInProgressDownloads.value?.length) {
        const { createSlot, closeModal, generateModal, HTMLtoComponent } =
          useModals()
        const el = generateModal({
          default: {
            headerText: 'Cancel Downloads',
            footerButtonLabel: 'Confirm'
          },
          slot: {
            content: createSlot('content', HTMLtoComponent(downloadString))
              .content
          },
          config: {
            showHeader: true,
            showBody: true,
            showFooter: true,
            addContentPadding: true,
            closeOnConfirm: true,
            showCloseButton: true,
            secondaryCTALabel: 'Cancel'
          },
          callback: {
            confirmFn: () => {
              removeAllDownloads()
            },
            secondaryFn: () => {
              closeModal(el)
            }
          }
        })
      } else {
        removeAllDownloads()
      }
    }

    function setMediatId(
      type: 'videoinput' | 'audioinput' | 'audiooutput',
      id: string
    ) {
      switch (type) {
        case 'videoinput':
          videoInputId.value = id
          break
        case 'audioinput':
          audioInputId.value = id
          break
        case 'audiooutput':
          audioOutputId.value = id
          break
      }
    }

    function setVideoCallThreadId(val: number) {
      videoCallThreadId.value = val
    }

    function setCanCallStatus(statusKey: string, statusValue: boolean) {
      canCallStatus.value.set(statusKey, statusValue)
    }

    function resetCanCallStatus() {
      for (const key of canCallStatus.value.keys()) {
        canCallStatus.value.set(key, false)
      }
    }
    function setCallThreadId(val: number) {
      callThreadId.value = val
    }

    function setProxyPhoneNumber(val: number) {
      proxyPhoneNumber.value = val
    }

    return {
      fetchCalls,
      fetchVoicemails,
      fetchUnseenVoicemails,
      fetchCoparentCallingSettings,
      fetchHasCompleteCallingSetup,
      completeCallingSetup,
      skipCallingSetup,
      sendCoparentReminder,
      fetchAllowsVideoCalls,
      setAllowsVideoCalls,
      buyMinutesPost,
      fetchCallAddonMinutesPlans,
      fetchCallLogs,
      fetchCallLog,
      fetchCallLogRecordingInfo,
      fetchRecordingSasUrl,
      fetchTranscriptSegments,
      fetchTranscriptJobStatus,
      firstLegPost,
      firstLegGather,
      firstLegHold,
      firstLegConnect,
      firstLegCallback,
      outOfSessionPost,
      recordingCallback,
      fetchDownloadUrl,
      fetchPreviewdUrl,
      secondLegPost,
      secondLegGather,
      secondLegCallback,
      sessionPost,
      createRoom,
      joinRoom,
      completeRoom,
      updateCall,
      startVideoCall,
      roomResult,
      setStartVideoCall,
      isBrowserSupportedForTwilio,
      getActiveVideoCall,
      newCallIsAvailable,
      callingItems,
      callingPageSize,
      callingPageNumber,
      loadMoreCallingItems,
      selectedCallingItem,
      selectedCallingFilter,
      callingSearchTerm,
      setCallingItems,
      resetCallingItems,
      setSelectedCallingItem,
      setLoadMoreCallingItems,
      setSelectedCallingFilter,
      fetchCallingItems,
      setPageNumber,
      fetchMoreCallingItems,
      fetchCallingItemsByPage,
      groupedCallingItems,
      fetchPhoneCallDetails,
      isFirstInGroup,
      activeCall,
      coparentCallingSettings,
      isActiveCallIncoming,
      getVideoCallRecordingURL,
      getVideoCall,
      getVideoCallTranscript,
      startCheckingForNewCall,
      getVideoVoicemail,
      getVideoVoicemailTranscript,
      fetchVideoCallDetails,
      showDetailsMenu,
      setShowDetailsMenu,
      getActiveVideoCallByItemID,
      showVideoCall,
      setShowVideoCall,
      logAction,
      updateVideoCallStatus,
      setActiveCall,
      hasCompletedCallingSetup,
      setHasCompletedCallingSetup,
      callingSetupSuccess,
      setCallingSetupSuccess,
      sentReminderWhen,
      setSentReminderWhen,
      bothParentsPassPrecheck,
      showAudioCall,
      setShowAudioCall,
      proxyPhoneNumber,
      singleCallReportPost,
      singleVideoCallReportPost,
      singleVoicemailReportPost,
      startDownload,
      handleDownloadedFile,
      stopDownload,
      removeDownloadFromList,
      removeAllDownloads,
      cancelAllDownloads,
      getInProgressDownloads,
      reDownload,
      downloads,
      getVideoCallRecording,
      getVideoVoicemailRecording,
      initialLoad,
      videoInputId,
      audioInputId,
      audioOutputId,
      setMediatId,
      videoCallThreadId,
      setVideoCallThreadId,
      canCallStatus,
      callThreadId,
      setCallThreadId,
      setProxyPhoneNumber,
      fetchUnseenVoicemailCount,
      unseenVoicemailCount,
      updateVoicemailIsUnseenListItem,
      setCallingSearchTerm
    }
  },
  {
    persist: {
      paths: ['videoInputId', 'audioInputId', 'audioOutputId']
    }
  }
)
