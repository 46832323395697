<template>
  <form @submit.prevent="submit">
    <fieldset>
      <legend>{{ t('messages.sortAndFilter.filter.legend') }}</legend>

      <div class="input-wrapper">
        <input v-model="state.all" id="all" type="checkbox" />
        <label for="all">
          {{ t('messages.sortAndFilter.filter.values.all') }}
        </label>
      </div>

      <div class="input-wrapper">
        <input
          v-model="state.filter"
          id="filter0"
          type="checkbox"
          :value="GlobalSearchResultType.Conversation"
        />
        <label for="filter0">
          {{ t('messages.sortAndFilter.filter.values.subject') }}
        </label>
      </div>

      <div class="input-wrapper">
        <input
          v-model="state.filter"
          id="filter2"
          type="checkbox"
          :value="GlobalSearchResultType.Message"
        />
        <label for="filter2">
          {{ t('messages.sortAndFilter.filter.values.message') }}
        </label>
      </div>

      <div class="input-wrapper">
        <input
          v-model="state.filter"
          id="filter1"
          type="checkbox"
          :value="GlobalSearchResultType.Attachment"
        />
        <label for="filter1">
          {{ t('messages.sortAndFilter.filter.values.attachment') }}
        </label>
      </div>
    </fieldset>

    <hr />

    <fieldset>
      <div class="input-wrapper">
        <input v-model="state.unread" id="unread" type="checkbox" />

        <label for="unread">
          {{ t('messages.sortAndFilter.showUnreadMessagesOnly') }}
        </label>
      </div>

      <div class="input-wrapper">
        <input
          v-model="state.sent"
          id="sent0"
          type="radio"
          name="sent"
          :value="OwnerFilter.Coparent"
          @click="
            state.sent == OwnerFilter.Coparent
              ? (state.sent = OwnerFilter.None)
              : false
          "
        />

        <label for="sent0">
          {{ t('messages.sortAndFilter.sent.values.coparent') }}
        </label>
      </div>

      <div class="input-wrapper">
        <input
          v-model="state.sent"
          id="sent1"
          type="radio"
          name="sent"
          :value="OwnerFilter.Mine"
          @click="
            state.sent == OwnerFilter.Mine
              ? (state.sent = OwnerFilter.None)
              : false
          "
        />
        <label for="sent1">
          {{ t('messages.sortAndFilter.sent.values.you') }}
        </label>
      </div>
    </fieldset>

    <hr />

    <fieldset>
      <legend>{{ t('messages.sortAndFilter.sort.legend') }}</legend>

      <div class="input-wrapper">
        <input
          v-model="state.sort"
          id="sort0"
          type="radio"
          name="sort"
          :value="SortOption.DateDescending"
        />
        <label for="sort0">
          {{ t('messages.sortAndFilter.sort.values.newestToOldest') }}
        </label>
      </div>

      <div class="input-wrapper">
        <input
          v-model="state.sort"
          id="sort1"
          type="radio"
          name="sort"
          :value="SortOption.DateAscending"
        />
        <label for="sort1">
          {{ t('messages.sortAndFilter.sort.values.oldestToNewest') }}
        </label>
      </div>

      <div class="input-wrapper" v-if="searchTerm.length">
        <input
          v-model="state.sort"
          id="sort2"
          type="radio"
          name="sort"
          :value="SortOption.Relevance"
        />
        <label for="sort2">
          {{ t('messages.sortAndFilter.sort.values.relevance') }}
        </label>
      </div>
    </fieldset>

    <!-- <hr /> -->

    <!-- <fieldset>
      <legend>{{ t('messages.sortAndFilter.date.legend') }}</legend>

      <DropdownMenu
        v-model="state.date"
        name="date"
        :options="dateMap"
        root-class="dropdown-root"
        select-class="dropdown-select"
        select-text-class="dropdown-select-text"
      />

      <div
        class="date-wrapper"
        v-if="state.date == MessagesSortFilterDateOptions.Custom"
      >
        <TPDatePicker
          v-model="state.rangeFrom"
          name="dateFrom"
          :label="t('messages.sortAndFilter.date.values.rangeFrom')"
          :auto-apply="true"
          :text-input="false"
          :utc="true"
          :placeholder="fullUserInfo.shortDateFormat?.toLocaleLowerCase()"
        />

        <TPDatePicker
          v-model="state.rangeTo"
          name="dateTo"
          :label="t('messages.sortAndFilter.date.values.rangeTo')"
          :auto-apply="true"
          :text-input="false"
          :utc="true"
          :placeholder="fullUserInfo.shortDateFormat?.toLocaleLowerCase()"
        />
      </div>
    </fieldset> -->

    <!-- <footer>
      <button type="submit" class="btn primary">
        <strong>{{ t('messages.sortAndFilter.applyFilters') }}</strong>
      </button>

      <span>
        {{ t('messages.sortAndFilter.results', { count: resultCount }) }}
      </span>
    </footer> -->
  </form>
</template>
<script setup lang="ts">
import { computed, nextTick, reactive, toRef, watch } from 'vue'
import moment, { type Moment } from 'moment'
import { useI18n } from 'vue-i18n'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import {
  GlobalSearchResultType,
  MessagesSortFilterDateOptions,
  MessagesSortFilterSentOptions,
  MessagesSortFilterSortOptions,
  OwnerFilter,
  SortOption
} from '@/models/enums'
import { useMessagesStore } from '@/stores/MessagesStore'
import { watchDebounced } from '@vueuse/core'
const { t } = useI18n({ useScope: 'global' })

const commonStore = useCommonStore()
const { searchTerm } = storeToRefs(commonStore)
const { setSearchMode } = commonStore

const messagesStore = useMessagesStore()
const { sortFilterSelectedOptions } = storeToRefs(messagesStore)
const { setSortFilterSelectedOptions } = messagesStore

const props = defineProps<{
  resultCount: number
}>()
const resultCount = toRef(props, 'resultCount')

const emit = defineEmits<{
  (e: 'submit'): void
}>()

interface IMessagesSortFilterMenuState {
  sort: SortOption
  filter: GlobalSearchResultType[]
  sent: OwnerFilter
  date: MessagesSortFilterDateOptions
  rangeFrom?: Moment
  rangeTo?: Moment
  all: boolean
  unread: boolean
}

const state = reactive<IMessagesSortFilterMenuState>({
  sort: sortFilterSelectedOptions.value.sortBy ?? SortOption.DateDescending,
  filter: [
    GlobalSearchResultType.Conversation,
    GlobalSearchResultType.Message,
    GlobalSearchResultType.Attachment
  ].every((f) => sortFilterSelectedOptions.value.dataTypes?.includes(f))
    ? []
    : sortFilterSelectedOptions.value.dataTypes ?? [],
  sent: sortFilterSelectedOptions.value.ownerFilter ?? OwnerFilter.None,
  date: MessagesSortFilterDateOptions.AllTime,
  all: [
    GlobalSearchResultType.Conversation,
    GlobalSearchResultType.Message,
    GlobalSearchResultType.Attachment
  ].every((f) => sortFilterSelectedOptions.value.dataTypes?.includes(f)),
  unread: sortFilterSelectedOptions.value.conversationFilter?.unread ?? false
})

const rangeFrom = computed(() =>
  state.date == MessagesSortFilterDateOptions.Custom
    ? state.rangeFrom
    : undefined
)

const rangeTo = computed(() =>
  state.date == MessagesSortFilterDateOptions.Custom ? state.rangeTo : undefined
)

const sortKeys = [
  t('messages.sortAndFilter.sort.values.newestToOldest'),
  t('messages.sortAndFilter.sort.values.oldestToNewest'),
  t('messages.sortAndFilter.sort.values.relevance')
]

const sortValues = [
  MessagesSortFilterSortOptions.NewestToOldest,
  MessagesSortFilterSortOptions.OldestToNewest,
  MessagesSortFilterSortOptions.Relevance
]

const sortMap = computed(
  () => new Map(sortKeys.map((k, i) => [k, sortValues[i]]))
)

const sentKeys = [
  t('messages.sortAndFilter.sent.values.both'),
  t('messages.sortAndFilter.sent.values.coparent'),
  t('messages.sortAndFilter.sent.values.you')
]

const sentValues = [
  MessagesSortFilterSentOptions.Both,
  MessagesSortFilterSentOptions.Coparent,
  MessagesSortFilterSentOptions.You
]

const sentMap = computed(
  () => new Map(sentKeys.map((k, i) => [k, sentValues[i]]))
)

const dateKeys = [
  t('messages.sortAndFilter.date.values.allTime'),
  t('messages.sortAndFilter.date.values.today'),
  t('messages.sortAndFilter.date.values.thisWeek'),
  t('messages.sortAndFilter.date.values.thisYear', {
    year: moment().format('YYYY')
  }),
  t('messages.sortAndFilter.date.values.lastYear', {
    year: `${+moment().format('YYYY') - 1}`
  }),
  t('messages.sortAndFilter.date.values.custom')
]

const dateValues = [
  MessagesSortFilterDateOptions.AllTime,
  MessagesSortFilterDateOptions.Today,
  MessagesSortFilterDateOptions.ThisWeek,
  MessagesSortFilterDateOptions.ThisYear,
  MessagesSortFilterDateOptions.LastYear,
  MessagesSortFilterDateOptions.Custom
]

const dateMap = computed(
  () => new Map(dateKeys.map((k, i) => [k, dateValues[i]]))
)

const dateFilter = computed(() =>
  !state.rangeFrom || !state.rangeTo
    ? undefined
    : {
        startDate: state.rangeFrom.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: state.rangeTo.format('YYYY-MM-DDTHH:mm:ss')
      }
)

const dataTypes = computed(() => {
  if (state.all) {
    return [
      GlobalSearchResultType.Conversation,
      GlobalSearchResultType.Message,
      GlobalSearchResultType.Attachment
    ]
  }

  return state.filter
})

function submit() {
  setSearchMode(true)

  setSortFilterSelectedOptions({
    ...sortFilterSelectedOptions.value,
    ownerFilter: state.sent,
    dataTypes: dataTypes.value,
    conversationFilter: { unread: state.unread },
    dateFilter: dateFilter.value,
    sortBy: state.sort
  })

  emit('submit')
}

watch(
  () => state.all,
  (val) => {
    if (!val && !state.filter.length) {
      if (searchTerm.value.length) {
        state.filter = [
          GlobalSearchResultType.Conversation,
          GlobalSearchResultType.Message,
          GlobalSearchResultType.Attachment
        ]
        return
      }

      state.filter = [GlobalSearchResultType.Conversation]
      return
    }

    if (val) {
      state.filter = []
    }
  }
)

watch(
  () => state.filter,
  (val) => {
    if (val.length) {
      state.all = false
      return
    }

    state.all = true
  }
)

watchDebounced(
  [
    () => state.sort,
    () => state.filter,
    () => state.sent,
    () => state.date,
    () => state.all,
    () => state.unread
  ],
  () => {
    submit()
  },
  { debounce: 600 }
)
</script>
<style scoped>
form {
  display: flex;
  flex-direction: column;
}

.form-content {
  display: flex;

  & > div {
    flex: 1;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  transition: background 0.1s ease-in-out;

  label {
    font-size: var(--font-size-0);
    cursor: pointer;
    color: var(--text-1);
    padding-block: var(--size-00);
    flex: 1 0 fit-content;
    padding-inline-start: var(--size-2);
    line-height: 1;
  }

  &:hover {
    background: var(--surface-2);
  }
}

.date-wrapper {
  display: flex;
  gap: var(--size-0);
  align-items: center;

  & > div {
    margin-block-start: 0 !important;
  }

  :deep(label) {
    font-size: var(--font-size-1);
    color: var(--text-1);
  }
}

fieldset {
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

legend {
  padding: 0;
  padding-inline-start: var(--size-2);
  font-size: var(--font-size-0);
  color: var(--text-2);
}

input[type='checkbox'] {
  --border-hover: transparent;
  border: 0 solid transparent;
  border-radius: var(--radius-sm);
  width: 0.75rem;
  height: 0.75rem;
  min-width: 0;
  margin-inline-start: 0.375rem;
  margin-block: var(--size-00);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  &:hover:not(:checked)::after {
    opacity: 0.5;
  }

  &::before {
    transition: all 0s;
    background: transparent;
    width: 100%;
    height: 100%;
    --scale: 1;
  }

  &::after {
    transition: all 0s;
    --scale: 1;
    border: 1.5px solid var(--text-0);
    border-top: 0;
    border-left: 0;
    margin-top: -0.2em;
    width: 30%;
    height: 65%;
    opacity: 0;
    transform: rotate(45deg);
    transition: opacity 0.1s ease-in-out;
  }

  &:checked::after {
    opacity: 1;
  }
}

input[type='radio'] {
  --border-hover: transparent;
  border: 0 solid transparent;
  border-radius: var(--radius-sm);
  width: 0.75rem;
  height: 0.75rem;
  min-width: 0;
  margin-inline-start: 0.375rem;
  margin-block: var(--size-00);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  &:hover:not(:checked)::after {
    opacity: 0.5;
  }

  &::before {
    transition: all 0s;
    background: transparent;
    width: 100%;
    height: 100%;
    --scale: 1;
  }

  &::after {
    transition: all 0s;
    --scale: 1;
    border: 1.5px solid var(--text-0);
    border-top: 0;
    border-left: 0;
    margin-top: -0.2em;
    width: 30%;
    height: 65%;
    opacity: 0;
    transform: rotate(45deg);
    background: transparent;
    border-radius: 0;
    transition: opacity 0.1s ease-in-out;
  }

  &:checked::after {
    opacity: 1;
  }
}

hr {
  margin-block: 0;
  background: var(--surface-4);
  width: 90%;
  margin-block: var(--size-000);
}

strong {
  --base-weight: 500;
}

footer {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  margin-block-start: var(--size-7);
}

:deep(.dropdown-root) {
  width: 100%;
}

:deep(.dropdown-select) {
  border-radius: var(--radius-lg);
}

:deep(.dropdown-select-text) {
  --base-weight: 400;
  font-size: var(--font-size-1);
  color: var(--text-0);
}
</style>
