<template>
  <div
    class="app-layout"
    ref="el"
    :class="{ 'viewport-height': layoutOptions.appLayoutHeightOfViewport }"
  >
    <TPHeader />
    <TPMobileHeader v-if="isMobileWidth" />
    <TPMainNav />
    <div v-if="!isOnline" class="tp-offline-container">
      You are currently offline.
    </div>
    <div v-if="loading" class="loading">
      <lottie-player
        :src="loadingIconJSONUrl"
        autoplay
        loop
        width="300px"
        height="300px"
      ></lottie-player>
    </div>
    <router-view v-else />

    <div>
      <TPFooter />
    </div>
    <AdblockCheckComponent></AdblockCheckComponent>
    <error-modal></error-modal>
    <video-call></video-call>
    <audio-call v-if="showAudioCall"></audio-call>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch, inject, onBeforeMount } from 'vue'
import TPHeader from '@/layouts/TPHeader.vue'
import TPMainNav from '@/layouts/TPMainNav.vue'
import TPFooter from '@/layouts/TPFooter.vue'
import TPMobileHeader from './TPMobileHeader.vue'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import rg4js, { type RaygunV2UserDetails } from 'raygun4js'
import { useRouter } from 'vue-router'
import { useMessagesStore } from '@/stores/MessagesStore'
import { useLoginStore } from '@/stores/LoginStore'
import SignalRHub from '@/services/signalrHub'
import { useCalendarStore } from '@/stores/CalendarStore'
import { useInfoLibraryStore } from '@/stores/InfoLibraryStore'
import { useRecordsStore } from '@/stores/RecordsStore'
import LottiePlayer from '@/components/LottiePlayerComponent.vue'

import ErrorModal from '@/components/ErrorModalComponent.vue'
import AdblockCheckComponent from '@/components/AdblockCheckComponent.vue'
import { useDebounceFn, useResizeObserver } from '@vueuse/core'
import { useMoneyTransferStore } from '@/stores/moneyTransferStore'
import type {
  ITrackingIdentifyWithIdFunction,
  IRealTimeNotification
} from '@/models/interfaces'
import { useVaultStore } from '@/stores/VaultStore'
import { realtimeDatabaseService } from '@/services/realtimeDBService'
import moment from 'moment'
import { useModals } from '@/composables/useModal/useModal'
import incomingVideoCall from '@/pages/calling/components/incomingVideoCall.vue'
import { useCallingStore } from '@/stores/CallingStore'
import videoCall from '@/pages/calling/VideoCall.vue'
import audioCall from '@/pages/calling/AudioCall.vue'

const loadingIconJSONUrl = '/loading/loadingIcon.json'

const trackingIdentifyWithId = inject<ITrackingIdentifyWithIdFunction>(
  '$trackingIdentifyWithId'
) as ITrackingIdentifyWithIdFunction

const router = useRouter()
const accountSettingsStore = useAccountSettingsStore()
const commonStore = useCommonStore()
const messageStore = useMessagesStore()
const loginStore = useLoginStore()
const calendarStore = useCalendarStore()
const recordsStore = useRecordsStore()
const infoLibraryStore = useInfoLibraryStore()
const moneyTransferStore = useMoneyTransferStore()
const vaultStore = useVaultStore()

const { handleNewThreadNotifications, handleNewReplyNotifications } =
  messageStore
const { handleCalendarNotifications } = calendarStore
const { handleInfoLibraryNotifications } = infoLibraryStore
const { requestPrintedReportInfo } = recordsStore
const { handleNewPaymentRequestNotification } = moneyTransferStore
const { handleThumbnailGeneratedNotification } = vaultStore

const { authToken } = storeToRefs(loginStore)

const {
  setObserverVal,
  loading,
  isOnline,
  isMobileWidth,
  fullUserInfo,
  fullUserInfoInit,
  isUserMatched,
  layoutOptions,
  fbAuthToken
} = storeToRefs(commonStore)

const {
  fetchBadgeCounts,
  fetchActivityCounts,
  fetchAppSettings,
  setLoading,
  setLayoutCommonDataLoaded,
  setSetObserverValMethod,
  setWindowWidthChanges,
  fetchFullUserInfo,
  fetchFirebaseAuthToken
} = commonStore

const { fetchSubscriptionInfo, getBetaToggleAvailability } =
  accountSettingsStore

const callingStore = useCallingStore()
const { getActiveVideoCallByItemID } = callingStore
const { showAudioCall } = storeToRefs(callingStore)

const { createSlot, closeModal, generateModal } = useModals()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let stopListening: any = null

watch(setObserverVal, async (val) => {
  if (val) {
    setObserver()
  }
})

const el = ref(null)
const pageHeight = ref()

useResizeObserver(el, (entries) => {
  const [entry] = entries
  const { height } = entry.contentRect
  pageHeight.value = height
})

onBeforeMount(() => {
  setLoading(true)
})

onMounted(async () => {
  document.title = 'Co-Parenting Communication Tools | TalkingParents'
  const _metaDesc: HTMLMetaElement | null = document.head.querySelector(
    'meta[name=description]'
  )
  if (_metaDesc) {
    _metaDesc.content =
      'TalkingParents is a communication and coordination platform offering co-parents the tools to focus on raising children.'
  }

  //make sure user is matched before calling everything else
  if (!fullUserInfoInit.value) await fetchFullUserInfo()

  if (!fullUserInfo.value) {
    // there was an error
    router.push({ name: 'logout' })
    return
  }

  if (isUserMatched.value) {
    Promise.all([
      fetchAppSettings(),
      fetchBadgeCounts(),
      fetchActivityCounts(),
      fetchSubscriptionInfo(),
      getBetaToggleAvailability()
    ]).then(() => {
      setLayoutCommonDataLoaded(true)
      setObserver()
      addUserInfoToRaygun()
      initSignalR()
      authenticatFirebase()

      // eslint-disable-next-line camelcase
      trackingIdentifyWithId({
        talkingparents_id: fullUserInfo.value.userId?.toString(),
        email: fullUserInfo.value.email,
        firstName: fullUserInfo.value.firstName,
        lastName: fullUserInfo.value.lastName,
        phone: fullUserInfo.value.phoneNumber,
        matched: true,
        consent: false
      })

      window.addEventListener('resize', handleResize)
      handleResize()

      setLoading(false)
    })
  } else {
    router.push({ name: 'logout' })
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  stopListening()
})

async function authenticatFirebase() {
  //get token
  await fetchFirebaseAuthToken()
  // authenticate user
  await realtimeDatabaseService
    .authenticateUser(fbAuthToken.value)
    .then(() => initRealTimeDBNotifications())
}

const initRealTimeDBNotifications = async () => {
  // Start listening for new data
  const path = '/notifications/' + fullUserInfo.value.caseId
  stopListening =
    realtimeDatabaseService.listenForNewEntries<IRealTimeNotification>(
      path,
      handleNewNotification
    )
}

const handleNewNotification = async (data: IRealTimeNotification | null) => {
  if (ignoreNotification(data)) {
    return
  }
  // means there is a new call, so get active call
  if (data?.roomId) {
    await getActiveVideoCallByItemID(data?.roomId)
    openVideoCallModal()
    console.log('New data received:', data)
    // Process the data as needed
  }
  //TODO: Some error checking here to make sure the active call is good to go
}

function ignoreNotification(data: IRealTimeNotification | null) {
  if (!data || data?.senderUserId == fullUserInfo.value.userId) return true
  if (moment.utc(data?.expires).isBefore(moment.utc())) {
    const path = '/notifications/' + fullUserInfo.value.caseId
    deleteNotification(path)
    return true
  }
}

function deleteNotification(path: string) {
  realtimeDatabaseService.deleteData(path)
}

function openVideoCallModal() {
  const el = generateModal({
    slot: {
      content: createSlot('content', incomingVideoCall, {
        onCancel: () => closeModal(el),
        onSuccess: async () => {
          closeModal(el)
        }
      }).content
    },
    config: {
      showHeader: false,
      showFooter: false,
      showCloseButton: false
    }
  })
}

const initSignalR = () => {
  console.log('initing SignalR')
  SignalRHub.client.on('onNewConversation', function (threadId, subject) {
    console.log('registering onNewConversation')
    handleNewThreadNotifications(threadId, subject)
  })
  SignalRHub.client.on('onNewReply', function (coparentId, threadId) {
    console.log('registering onNewReply')
    handleNewReplyNotifications(coparentId, threadId)
  })
  SignalRHub.client.on('onNewCalendarEvent', function (itemId) {
    console.log('registering onNewCalendarEvent')
    itemId
    handleCalendarNotifications(0)
  })
  SignalRHub.client.on('onEditedCalendarEvent', function (itemId) {
    console.log('registering onEditedCalendarEvent')
    itemId
    handleCalendarNotifications(1)
  })
  SignalRHub.client.on('onDeletedCalendarEvent', function (itemId) {
    console.log('registering onDeletedCalendarEvent')
    handleCalendarNotifications(2, itemId)
  })
  SignalRHub.client.on('onCanceledCalendarEvent', function (itemId) {
    console.log('registering onCanceledCalendarEvent')
    itemId
    handleCalendarNotifications(1)
  })
  SignalRHub.client.on('onNewInfoLibraryCard', function (cardId) {
    console.log('registering onNewInfoLibraryCard')
    handleInfoLibraryNotifications(cardId)
  })
  SignalRHub.client.on('onUpdatedInfoLibraryCard', function (cardId) {
    console.log('registering onUpdatedInfoLibraryCard')
    handleInfoLibraryNotifications(cardId)
  })
  SignalRHub.client.on('onPrintedReportCalculationComplete', function (jobId) {
    console.log('registering onPrintedReportCalculationComplete')
    requestPrintedReportInfo(jobId)
  })
  SignalRHub.client.on('onNewPaymentRequest', function () {
    console.log('registering onNewPaymentRequest')
    fetchBadgeCounts()
    handleNewPaymentRequestNotification()
  })
  SignalRHub.client.on('onThumbnailGenerated', function (itemId) {
    console.log('registering onThumbnailGenerated')
    handleThumbnailGeneratedNotification(itemId)
  })
  SignalRHub.client.on('NewVideoCall', function (itemId) {
    console.log('registering NewVideoCall')
    handleNewVideoCallRequestNotification(itemId)
  })

  console.log('starting SignalR')
  SignalRHub.start(authToken.value)
}

function handleNewVideoCallRequestNotification(itemId: unknown) {
  // TODO: Launch modal or something to alert the user
  // that a call is coming in.
  console.log(itemId)
}

const debouncedFn = useDebounceFn(
  () => {
    setWindowWidthChanges()
  },
  500,
  { maxWait: 1000 }
)

async function handleResize() {
  debouncedFn()
}

function setObserver() {
  setSetObserverValMethod(false)
}

function addUserInfoToRaygun() {
  rg4js('setUser', {
    identifier: fullUserInfo.value.userId,
    fullName: fullUserInfo.value.fullName,
    email: fullUserInfo.value.email,
    isAnonymous: false,
    uuid: 'Web App'
  } as unknown as RaygunV2UserDetails)
}
</script>
<style scoped lang="scss">
.viewport-height {
  height: 100dvh;
}
</style>
