import { defineStore, storeToRefs } from 'pinia'
import type {
  GetThreadResult,
  IMessagingThread,
  MessagingThreadItem,
  ThreadViewModel
} from '@/models/models'
import httpClient from '@/httpClient'
import ErrorHelper from '@/exports/error'
import type {
  IConversationFilter,
  IDateRange,
  ILatestThreadsPayload,
  IPagedPayload,
  IPagedThreadPayload
} from '@/models/interfaces'
import { useCommonStore } from './CommonStore'
import type { IMessagesState } from '@/models/stores/messages'
import { MessagingThread } from '@/models/models'
import type {
  IGlobalSearchResult,
  ISearchConversationsPagedRequest
} from '@/models/interfaces.ts'
import {
  GlobalSearchResultType,
  OwnerFilter,
  SortOption
} from '@/models/enums.ts'

const pageSize = 25
const previewLength = 250

export const useMessagesStore = defineStore('messages', {
  state: (): IMessagesState => ({
    messageThreads: [
      new MessagingThread(),
      new MessagingThread(),
      new MessagingThread()
    ],
    count: 0,
    selectedMessageThread: {
      subject: '',
      timeZoneIdentifier: '',
      threadItems: [],
      createdBy: '',
      createDate: new Date(Date.now())
    },
    selectedConversationSearchResult: {
      type: GlobalSearchResultType.Conversation,
      conversation: { itemID: 0 }
    },
    newReplyAlert: { newReply: false, threadId: 0 },
    newThreadAlert: { newThread: false, threadId: 0 },
    requestId: '',
    loadMoreMessages: false,
    threadLoaded: false,
    searchedMessageThreads: [
      {
        type: GlobalSearchResultType.Conversation,
        message: {
          itemID: 0,
          content: '\xa0',
          creatorID: 0,
          createdDate: new Date()
        },
        conversation: {
          itemID: 0,
          title: '\xa0',
          isNew: false,
          lastMessageDate: new Date()
        },
        skeletonLoading: true
      },
      {
        type: GlobalSearchResultType.Conversation,
        message: {
          itemID: 0,
          content: '\xa0',
          creatorID: 0,
          createdDate: new Date()
        },
        conversation: {
          itemID: 0,
          title: '\xa0',
          isNew: false,
          lastMessageDate: new Date()
        },
        skeletonLoading: true
      },
      {
        type: GlobalSearchResultType.Conversation,
        message: {
          itemID: 0,
          content: '\xa0',
          creatorID: 0,
          createdDate: new Date()
        },
        conversation: {
          itemID: 0,
          title: '\xa0',
          isNew: false,
          lastMessageDate: new Date()
        },
        skeletonLoading: true
      }
    ],
    loadMoreSearchResults: false,
    groupedSearchedMessages: [{ replies: [], subject: '' }],
    threadListSearchTerm: '',
    threadListPage: 1,
    threadListScrollPosition: 0,
    sortFilterSelectedOptions: {
      page: 1,
      searchTerm: '',
      ownerFilter: OwnerFilter.None,
      dataTypes: [GlobalSearchResultType.Conversation],
      conversationFilter: { unread: false },
      sortBy: SortOption.DateDescending
    }
  }),
  persist: {
    paths: ['selectedMessageThread']
  },
  getters: {
    getLastReplyItemID: (state) => {
      let itemId = 0
      const _ti = state.selectedMessageThread.threadItems

      if (_ti) {
        const revTi = [..._ti]
        revTi.reverse()
        const _item = revTi.find((el) => el.itemType == 1)
        itemId = _item?.itemID || 0
      }

      return itemId
    },
    areFiltersDefault: (state) => {
      const commonStore = useCommonStore()
      const { searchTerm } = storeToRefs(commonStore)

      return (
        state.sortFilterSelectedOptions.ownerFilter == OwnerFilter.None &&
        ((!searchTerm.value.length &&
          state.sortFilterSelectedOptions.dataTypes?.length == 1 &&
          state.sortFilterSelectedOptions.dataTypes[0] ==
            GlobalSearchResultType.Conversation) ||
          (!!searchTerm.value.length &&
            [
              GlobalSearchResultType.Conversation,
              GlobalSearchResultType.Message,
              GlobalSearchResultType.Attachment
            ].every((f) =>
              state.sortFilterSelectedOptions.dataTypes?.includes(f)
            ))) &&
        state.sortFilterSelectedOptions.conversationFilter?.unread == false &&
        state.sortFilterSelectedOptions.sortBy ==
          (searchTerm.value.length
            ? SortOption.Relevance
            : SortOption.DateDescending)
      )
    }
  },
  actions: {
    async fetchThreadsPaged(payload: IPagedPayload) {
      try {
        const url = `/web/api/Messaging/GetConversationsPaged?PageNumber=${payload.page}&PageSize=${pageSize}&PreviewLength=${previewLength}`

        // Call V2 for the API to get new preview message data
        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '2'
          }
        })

        if (!response?.data.success)
          throw new Error(response?.data?.errorMessage)

        const _messageThreads = (
          payload.page == 1
            ? response.data.value
            : [...this.messageThreads, ...response.data.value]
        ) as IMessagingThread[]

        //duplicate check based on ID
        this.messageThreads = Array.from(
          new Set(_messageThreads.map((m) => m.threadID))
        )
          .filter((i) => i != undefined)
          .map((threadId) =>
            _messageThreads.find((m) => m.threadID === threadId)
          ) as IMessagingThread[]

        this.count = _messageThreads.length
        this.loadMoreMessages = response.data.value.length == pageSize
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchThreadsPaged')
      }
    },
    async fetchSearchThreadsPaged(payload: ISearchConversationsPagedRequest) {
      try {
        let url = `/mobile/api/Messaging/SearchConversationsPaged?SearchTerm=${encodeURIComponent(payload.searchTerm)}&PageNumber=${payload.page}&PageSize=${pageSize}&PreviewLength=${previewLength}`

        if (payload.ownerFilter != undefined) {
          url += `&OwnerFilter=${payload.ownerFilter}`
        }

        if (payload.dataTypes != undefined) {
          for (const dataType of payload.dataTypes) {
            url += `&DataTypes=${dataType}`
          }
        }

        if (payload.conversationFilter != undefined) {
          url += `&ConversationFilter.Unread=${payload.conversationFilter.unread}`
        }

        if (payload.attachmentFileTypes != undefined) {
          for (const fileType of payload.attachmentFileTypes) {
            url += `&AttachmentFileTypes=${fileType}`
          }
        }

        if (payload.dateFilter != undefined) {
          url += `&DateFilter.StartDate=${payload.dateFilter.startDate}&DateFilter.EndDate=${payload.dateFilter.endDate}`
        }

        if (payload.sortBy != undefined) {
          url += `&SortBy=${payload.sortBy}`
        }

        // Call V2 for the API to get new preview message data
        //now v3 for cognitive search
        //and v5 for sorting and filtering
        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '5'
          }
        })

        if (!response?.data.success)
          throw new Error(response?.data?.errorMessage)

        const _messageThreads = (
          payload.page == 1
            ? response.data.value
            : [...this.searchedMessageThreads, ...response.data.value]
        ) as IGlobalSearchResult[]

        this.searchedMessageThreads = _messageThreads
        this.loadMoreSearchResults = !response.data.allResultsLoaded
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchSearchThreadsPaged')
      }
    },
    async fetchPagedThread(payload: IPagedThreadPayload) {
      try {
        this.threadLoaded = false

        const pageSize = payload.pageSize || 100
        const url = `/web/api/Conversations/GetPagedThread?ThreadID=${payload.threadID}&MostRecent=${payload.mostRecent}&PageSize=${pageSize}&CheckForNew=true&IncludeCallEvents=true`

        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '2'
          }
        })

        if (!response?.data.success)
          throw new Error(response?.data?.errorMessage)

        const _thread = response.data.value
        const _threadItems = _thread.threadItems

        this.selectedMessageThread = {
          ...this.selectedMessageThread,
          ..._thread
        }

        if (_threadItems.length == 0) {
          this.threadLoaded = true
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchPagedThread')
      }
    },
    async combineThreadItems(items: MessagingThreadItem[]) {
      const _existingItems = this.selectedMessageThread.threadItems || []

      // add new, not duplicate, items to array
      const ids = new Set(_existingItems.map((d) => d.itemID)) || []
      const newItems = items.filter(
        (d: MessagingThreadItem) => !ids.has(d.itemID)
      )
      const combinedArray = [..._existingItems, ...newItems]

      // order array by EntryDate
      combinedArray.sort((a, b) => {
        const da = new Date(a?.entryDateUtc || Date()).valueOf(),
          db = new Date(b?.entryDateUtc || Date()).valueOf()
        return da - db
      })

      return combinedArray
    },
    async fetchMorePagedThreadItems(payload: IPagedThreadPayload) {
      try {
        this.threadLoaded = false

        const pageSize = payload.pageSize || 100
        const url = `/web/api/Conversations/GetPagedThread?ThreadID=${payload.threadID}&MostRecent=${payload.mostRecent}&PageSize=${pageSize}&CheckForNew=true&IncludeCallEvents=true`

        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '2'
          }
        })

        if (!response.data.success) throw new Error(response.data.errorMessage)

        const _reponseCamel: GetThreadResult = response.data.value

        const _newMessageThread = _reponseCamel as ThreadViewModel
        const _threadItems = _newMessageThread?.threadItems || []

        if (!_newMessageThread) {
          this.threadLoaded = true
          return
        }

        // if no more thread items, set variable and return
        if (_threadItems && _threadItems?.length == 0) {
          this.threadLoaded = true
          return
        }

        _newMessageThread.threadItems =
          await this.combineThreadItems(_threadItems)

        this.selectedMessageThread = {
          ...this.selectedMessageThread,
          ..._newMessageThread
        }

        return
        // if (_threadItems.length > pageSize) {
        //   this.fetchMorePagedConversations({
        //     threadID: payload.threadID,
        //     mostRecent: _threadItems[0].entryDateUtc?.toDateString()
        //   })

        //   this.allMessageThreadsLoaded = false
        // } else {
        //   this.allMessageThreadsLoaded = true
        // }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchMorePagedThreadItems')
      }
    },
    async fetchLatestThreadItems(payload: ILatestThreadsPayload) {
      try {
        this.threadLoaded = false

        const pageSize = 100
        const url = `/web/api/Conversations/GetlatestThreadItems?ThreadID=${payload.threadID}&ItemID=${payload.itemID}&MostRecent=${payload.mostRecent}&PageSize=${pageSize}&CheckForNew=true&IncludeCallEvents=true`

        const response = await httpClient.get(encodeURI(url))
        const _reponseCamel: GetThreadResult = response.data

        if (!_reponseCamel.success) throw new Error(_reponseCamel.errorMessage)

        const _newMessageThread = _reponseCamel.value

        if (!_newMessageThread) return

        const _threadItems = _newMessageThread?.threadItems || []

        // if no more thread items, set variable and return
        if (_threadItems && _threadItems.length == 0) {
          this.threadLoaded = true
          return
        }

        _newMessageThread.threadItems =
          await this.combineThreadItems(_threadItems)

        this.selectedMessageThread = {
          ...this.selectedMessageThread,
          ..._newMessageThread
        }

        this.threadLoaded = true
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchLatestThreadItems')
      }
    },
    async createMessage(payload: FormData): Promise<boolean> {
      const url = '/web/api/Messaging/CreateMessage'
      const abortController = new AbortController()
      const common = useCommonStore()

      try {
        payload.append('RequestId', this.requestId)
        common.abortController = abortController

        const response = await httpClient.post(url, payload, {
          signal: abortController.signal,
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-version': '2'
          }
        })

        this.setUID()

        if (!response.data.success) {
          const err = new Error(response.data.errorMessage)
          ErrorHelper.handleError(
            err,
            'createMessage',
            response.data.errorCode > 0,
            response.data.errorMessage
          )
          return false
        }

        return true
      } catch (e) {
        if (e == 'Canceled') return false
        ErrorHelper.handleError(e, 'saveReply')
        return false
      }
    },
    async createThread(payload: FormData) {
      const url = '/web/api/Messaging/CreateConversation'
      const abortController = new AbortController()
      const common = useCommonStore()

      try {
        payload.append('requestId', this.requestId)
        common.abortController = abortController

        const response = await httpClient.post(url, payload, {
          signal: abortController.signal,
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-version': '3'
          }
        })

        this.setUID()

        if (!response.data.success) {
          const err = new Error(response.data.errorMessage)

          if (response.data.errorCode == 2) {
            ErrorHelper.handleError(
              err,
              'createThread',
              response.data.errorCode == 2,
              'You can only create one new message every 30 seconds. Please try again later.'
            )
          } else {
            ErrorHelper.handleError(
              err,
              'createThread',
              response.data.errorCode > 0,
              response.data.errorMessage
            )
          }
        } else {
          const _response = await response.data.threadID
          return _response
        }
      } catch (e) {
        if (e == 'Canceled') return
        // errorCode 2 = Too many requests
        ErrorHelper.handleError(
          e,
          'createThread',
          true,
          'You can only create one new message every 30 seconds. Please try again later.'
        )
      }
    },
    handleNewThreadNotifications(threadId: number, subject: string) {
      subject
      const common = useCommonStore()

      common.fetchBadgeCounts()

      this.newThreadAlert = { newThread: true, threadId: threadId }
    },
    handleNewReplyNotifications(coparentId: number, threadId: number) {
      coparentId
      const common = useCommonStore()

      common.fetchBadgeCounts()

      this.newReplyAlert = { newReply: true, threadId: threadId }
    },
    resetSelectedMessageThread() {
      this.selectedMessageThread = {
        subject: '',
        timeZoneIdentifier: '',
        threadItems: [],
        createdBy: '',
        createDate: new Date(Date.now())
      }
    },
    setSelectedMessageThread(selectedMessageThread: IMessagingThread) {
      this.selectedMessageThread = selectedMessageThread
    },
    setUID() {
      // Generate the UID from two parts here
      // to ensure the random number provide enough bits.
      const firstPart = (Math.random() * 46656) | 0
      const secondPart = (Math.random() * 46656) | 0

      this.requestId =
        ('000' + firstPart.toString(36)).slice(-3) +
        ('000' + secondPart.toString(36)).slice(-3)
    },
    setSelectedConversationSearchResult(
      selectedConversationSearchResult: IGlobalSearchResult
    ) {
      this.selectedConversationSearchResult = selectedConversationSearchResult
    },
    setThreadListSearchTerm(threadListSearchTerm: string) {
      this.threadListSearchTerm = threadListSearchTerm
    },
    setThreadListPage(threadListPage: number) {
      this.threadListPage = threadListPage
    },
    setThreadListScrollPosition(threadListScrollPosition: number) {
      this.threadListScrollPosition = threadListScrollPosition
    },
    setSortFilterSelectedOptions(options: ISearchConversationsPagedRequest) {
      this.sortFilterSelectedOptions = { ...options }
    },
    setOwnerFilter(filter: OwnerFilter) {
      this.sortFilterSelectedOptions.ownerFilter = filter
    },
    setDataTypes(types: GlobalSearchResultType[]) {
      this.sortFilterSelectedOptions.dataTypes = types
    },
    setConversationFilter(filter: IConversationFilter) {
      this.sortFilterSelectedOptions.conversationFilter = filter
    },
    setSortBy(sort: SortOption) {
      this.sortFilterSelectedOptions.sortBy = sort
    },
    setDateFilter(filter: IDateRange) {
      this.sortFilterSelectedOptions.dateFilter = filter
    },
    resetSearchedMessageThreads() {
      this.searchedMessageThreads = [
        {
          type: GlobalSearchResultType.Conversation,
          message: {
            itemID: 0,
            content: '\xa0',
            creatorID: 0,
            createdDate: new Date()
          },
          conversation: {
            itemID: 0,
            title: '\xa0',
            isNew: false,
            lastMessageDate: new Date()
          },
          skeletonLoading: true
        },
        {
          type: GlobalSearchResultType.Conversation,
          message: {
            itemID: 0,
            content: '\xa0',
            creatorID: 0,
            createdDate: new Date()
          },
          conversation: {
            itemID: 0,
            title: '\xa0',
            isNew: false,
            lastMessageDate: new Date()
          },
          skeletonLoading: true
        },
        {
          type: GlobalSearchResultType.Conversation,
          message: {
            itemID: 0,
            content: '\xa0',
            creatorID: 0,
            createdDate: new Date()
          },
          conversation: {
            itemID: 0,
            title: '\xa0',
            isNew: false,
            lastMessageDate: new Date()
          },
          skeletonLoading: true
        }
      ]
    },
    setLoadMoreMessages(loadMore: boolean) {
      this.loadMoreMessages = loadMore
    },
    resetMessageThreads() {
      this.messageThreads = [
        new MessagingThread(),
        new MessagingThread(),
        new MessagingThread()
      ]
    },
    resetThreads() {
      this.resetMessageThreads()
      this.resetSearchedMessageThreads()
      this.loadMoreMessages = false
      this.loadMoreSearchResults = false
    },
    clearFilters() {
      const commonStore = useCommonStore()
      const { searchTerm } = storeToRefs(commonStore)
      const { setSearchMode } = commonStore

      setSearchMode(false)

      const dataTypes = searchTerm.value.length
        ? [
            GlobalSearchResultType.Conversation,
            GlobalSearchResultType.Message,
            GlobalSearchResultType.Attachment
          ]
        : [GlobalSearchResultType.Conversation]

      const sortBy = searchTerm.value.length
        ? SortOption.Relevance
        : SortOption.DateDescending

      this.sortFilterSelectedOptions = {
        page: 1,
        searchTerm: '',
        ownerFilter: OwnerFilter.None,
        dataTypes: dataTypes,
        conversationFilter: { unread: false },
        sortBy: sortBy
      }
    }
  }
})
