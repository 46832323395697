<template>
  <Teleport to="body">
    <transition name="modal-animation">
      <div v-if="errorModal.showErrorModal" class="modal">
        <transition name="modal-animation-inner">
          <div
            ref="modalRef"
            v-show="errorModal.showErrorModal"
            class="modal-inner"
          >
            <div
              class="dialog-header text-center"
              style="padding-bottom: var(--size-00); position: relative"
            >
              <!-- Close Modal -->
              <button class="btn circle dialog-close" @click="close">
                <font-awesome-icon icon="fa-light fa-xmark" />
                <span class="sr-only">close</span>
              </button>
              <!-- Content -->
              <div class="stack align-center mt-1 mx-auto">
                <svg
                  class="logo-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  viewBox="0 0 750 517.58"
                  style="height: 2.5rem"
                >
                  <path
                    class="st2"
                    fill="#014847"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M458.48 406.25c-4.31-17.25-33.42-2.16-57.14 9.7-40.97 20.48-85.17 31.27-131.53 32.34-115.36 0-218.86-69-263.06-175.73-2.16-3.23-6.47-5.39-6.47-2.16l1.08 3.23C44.49 398.7 163.08 482.79 295.68 482.79c42.05 0 84.09-8.62 122.9-25.87 8.63-3.24 47.44-21.56 39.9-50.67"
                  />
                  <path
                    class="st2"
                    fill="#014847"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M738.79 406.33c-9.7 14.02-30.19-9.7-47.44-26.95-29.11-31.27-64.69-54.98-103.5-71.15-100.26-37.74-213.46-11.86-287.85 65.76-2.16 2.16-6.47 2.16-5.39-1.08l2.16-2.16c79.78-94.87 210.23-129.37 325.59-85.17 36.66 14.02 71.15 35.58 99.19 62.53 6.45 6.47 33.41 35.58 17.24 58.22"
                  />
                  <path
                    class="st3"
                    fill="#29b99a"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M329.11 296.37c3.23 0 6.47 1.08 9.7 1.08 7.55-5.39 16.17-10.78 24.8-15.09-6.47-1.08-12.94-2.16-19.41-3.23-69-6.47-134.76 31.27-162.79 93.8l-1.08 2.16c0 1.08 2.16 1.08 3.23 0 28.03-53.93 85.17-84.11 145.55-78.72"
                  />
                  <path
                    class="st2"
                    fill="#014847"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M501.6 36.54c51.75 0 93.8 46.36 93.8 103.5s-42.05 103.5-93.8 103.5-93.8-46.36-93.8-103.5 42.05-103.5 93.8-103.5"
                  />
                  <path
                    class="st2"
                    fill="#014847"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M112.41 113.09c51.75 0 93.8 46.36 93.8 103.5s-42.05 103.5-93.8 103.5-93.8-46.36-93.8-103.5 42.05-103.5 93.8-103.5"
                  />
                  <path
                    class="st3"
                    fill="#29b99a"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M306.47 134.65c30.19-1.08 54.98 25.87 56.06 59.3s-23.72 61.45-53.91 61.45c-30.19 1.08-54.98-25.87-56.06-59.3-1.08-33.42 23.72-60.37 53.91-61.45"
                  />
                </svg>
                <span
                  class="font-weight-2 font-size-5"
                  style="margin-top: 0.5rem"
                >
                  {{ errorModal.title }}
                </span>
              </div>
            </div>
            <div
              v-if="errorModal.showFixError"
              class="dialog-body stack font-size-1"
            >
              <div class="text-center">
                Please fix the error below and try again.<br />
                If the problem continues, contact us at
                <a
                  class="velvet underline"
                  href="mailto:support@talkingparents.com"
                >
                  support@talkingparents.com </a
                >.
              </div>
              <div
                class="px-1 py-00 text-center"
                style="background-color: #f5f5ff; border-radius: 0.5rem"
              >
                <span class="font-weight-2 text-left">Error: </span>
                {{ errorModal.errorMessage }}
              </div>
            </div>
            <div
              v-else-if="errorModal.showGenericError"
              class="dialog-body stack font-size-1"
            >
              <div class="text-center">
                Please check your connection and try again. If the problem
                continues, contact us at
                <a
                  class="velvet underline"
                  href="mailto:support@talkingparents.com"
                >
                  support@talkingparents.com
                </a>
              </div>
            </div>
            <div class="dialog-footer align-center py-3">
              <button class="btn primary font-weight-2" @click="close">
                Okay
              </button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { useCommonStore } from '@/stores/CommonStore'
import { onClickOutside } from '@vueuse/core'
import { ref, toRef } from 'vue'
import { storeToRefs } from 'pinia'

const commonStore = useCommonStore()
const { errorModal } = storeToRefs(commonStore)
const { resetErrorModal } = commonStore

const modalRef = ref(null)

onClickOutside(modalRef, (event) => {
  if (errorModal.value.showErrorModal) {
    close()
  }
})

function close() {
  resetErrorModal()
}
</script>

<style lang="scss" scoped>
.modal {
  margin-top: 0;
}
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}
.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to {
  transform: scale(0.8);
}
.modal {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 0.55);
  z-index: 3000;

  @media (min-width: 48em) {
    justify-content: center;
    align-items: center;
  }
}
.modal-inner {
  position: relative;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: var(--surface-1);

  @media (min-width: 48em) {
    max-width: 640px;
    width: 80%;
  }
}

.dialog-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  inset-block-start: 1.25rem;
  inset-inline-end: 1.25rem;
}
.content {
  margin-left: auto;
  margin-right: auto;
  margin-inline: auto;
  max-width: 60ch;
}
</style>
