<template>
  <div>
    <header class="dialog-header">
      <span>
        {{ props.headerText }}
      </span>
    </header>
    <div class="dialog-body gap-2">
      <slot></slot>
    </div>
    <footer class="dialog-footer flex align-middle">
      <button class="btn mx-auto primary" @click="routeToPaywall()">
        {{ props.upgradeButtonText }}
      </button>
    </footer>
  </div>
</template>
<script setup lang="ts">
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { useRouter, useRoute } from 'vue-router'
import { br } from '@/plugins/trackerPlugin'
import { inject } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const track: any = inject('$trackingTrack')
const router = useRouter()
const route = useRoute()

const accountSettingsStore = useAccountSettingsStore()
const { setPaywallPreviousPage } = accountSettingsStore

const props = defineProps({
  headerText: {
    type: String,
    required: true
  },
  upgradeButtonText: {
    type: String,
    default: 'Upgrade plan'
  }
})

async function routeToPaywall() {
  track(br.eventTypes.appAction, {
    feature: route.meta.trackingName,
    name: br.appActionEventNames.CTAUpgrade
  })

  await setPaywallPreviousPage({
    name: route.name?.toString(),
    params: route.params
  })
  router.push({ name: 'accountTypeStep1' })
}
</script>
