﻿<template>
  <div>
    <section class="relative">
      <div v-if="!state.showStep2">
        <button
        type="button"
        class="btn tertiary purple back-button"
        @click="sendToForgotEmailOrPassword"
        >
          <font-awesome-icon icon="fa-light fa-arrow-left-long" class="icon-size-2 purple" />
        </button>
      </div>
      <header class="pb-1 mb-2" :class="{'border-bottom' : isDesktopWidth}">
        <h1 class="dark-green text-center">{{ state.showStep2 ? 'Password updated' : 'Reset password' }}<span class="sr-only"> for your account</span></h1>
      </header>
    </section>
    <div>
      <form 
        v-if="state.showStep1" 
        class="stack size-3"
        @submit.prevent="onSubmit"
      >
        <p class="text-center black">
          Choose a new password for account:
          <strong class="font-weight-3">
              {{ state.email }}
          </strong>
        </p>
        <div class="stack size-3" role="form">
          <text-input
            id="NewPasswordInput"
            name="NewPasswordInput"
            label="New password"
            v-model="state.newPassword"
            type="password"
            aria-describedby="NewPasswordTip"
            :password-visibility-option="true"
            :required="true"
            :class="{
              invalid: !meta.valid,
              valid: meta.valid
            }"
            :hint="passwordHint"
          />
          <text-input
            id="ConfirmNewPasswordInput"
            name="ConfirmNewPasswordInput"
            label="Verify password"
            v-model="state.confirmNewPassword"
            type="password"
            aria-describedby="ConfirmNewPasswordTip"
            :password-visibility-option="true"
            :required="true"
            :class="{
              invalid: !meta.valid,
              valid: meta.valid
            }"
            :hint="'Re-enter your new password.'"
            :hint-class="state.hintClass"
          />

          <button
            type="submit"
            class="flex btn primary extended-btn"
            style="width: 13rem;"
            :class="{ disabled: !meta.dirty || !meta.valid || loading }"
            :disabled="!meta.dirty || !meta.valid || loading"
            >
            Update Password
          </button>
        </div>
      </form>
      <div v-if="state.showStep2" class="stack size-">
        <p class="text-center">Your password was successfully updated. <br/>
          You can now sign in with your new password.
        </p>
        <button
          type="button"
          class="btn primary extended-btn"
        >
          <router-link to="login" class="black"
            >Return to sign in</router-link>
        </button>
      </div>
      <div v-if="state.showResetError" class="stack">
        <p class="text-center">Sorry, this reset link has expired or is invalid.</p>
        <button
          type="button"
          class="btn primary extended-btn"
        >
          <router-link to="forgotEmailOrPassword" class="black"
            >Request new link</router-link>
        </button>
      </div>
      <google-recaptcha
        ref="googleRecaptcha"
        :verified-callback="resetPassword"
        @loaded="onRecaptchaLoaded"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ErrorHelper from '@/exports/error'
import { useLoginStore } from '@/stores/LoginStore'
import { useCommonStore } from '@/stores/CommonStore'
import { reactive, ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TextInput from '@/components/library/TextInput.vue'
import { useForm } from 'vee-validate'
import PasswordHelper from '@/exports/passwordHelper'
import { storeToRefs } from 'pinia'
import GoogleRecaptcha from '@/components/GoogleRecaptchaComponent.vue'

const router = useRouter()
const route = useRoute()

const loginStore = useLoginStore()
const commonStore = useCommonStore()
const { updatePassword, verifyPasswordResetCodeFromEmail } = loginStore
const { loading, isDesktopWidth } = storeToRefs(commonStore)
const { setLoading } = commonStore

const googleRecaptcha = ref(GoogleRecaptcha)

const formValidationSchema = {
  NewPasswordInput: 'required|password',
  ConfirmNewPasswordInput: () => {
    if (state.confirmNewPassword === state.newPassword) return true
    return 'Passwords do not match.'
  }
}

const { handleSubmit, meta, handleReset } = useForm({
  validationSchema: formValidationSchema
})

const state = reactive({
  email: '',
  showStep1: false,
  showStep2: false,
  newPassword: '',
  newPasswordInit: false,
  confirmNewPassword: '',
  confirmNewPasswordInit: false,
  showResetError: false,
  recaptchaLoaded: false,
  hintClass: 'font-weight-0'
})

function onRecaptchaLoaded() {
  state.recaptchaLoaded = true
}

const onSubmit = handleSubmit(() => {
  resetPassword()
})

const passwordHint = computed(() => {
  return PasswordHelper.getPasswordHintText()
})

onMounted(() => {
  setLoading(true)
  // Both reset modes must now verify using token before attempting to add new password
  const code = route.query.c?.toString()

  if (code) {
    const payload = {
      verifyCode: code
    }
    verifyPasswordResetCodeFromEmail(payload).then((email) => {
      if (email) {
        state.showStep1 = true
        state.email = email
        setLoading(false)
      } else {
        state.showResetError = true
        setLoading(false)
      }
    })
  } else {
    // something not right if you get here, redirect to login
    router.push('login')
  }
  
})

async function resetPassword(token?: string) {
  if (!token) {
    //GoogleRecaptcha.verify()
    googleRecaptcha.value?.verify()
    return
  }
  setLoading(true)
  // todo figure out what value for request code should be
  try {
    const payload = {
      newPassword: state.newPassword,
      email: state.email,
      requestCode: '',
      recaptchaToken: token
    }
    updatePassword(payload).then((result) => {
      if (result) {
        state.showStep1 = false
        state.showStep2 = true
        setLoading(false)
        handleReset()
      } else {
        state.showStep1 = false
        state.showStep2 = false
        state.showResetError = true
        setLoading(false)
      }
    })
  } catch (e) {
    state.showResetError = true
    ErrorHelper.handleError(e, 'resetPassword')
    setLoading(false)
    return
  }
}

function sendToForgotEmailOrPassword() {
  router.push({ name: 'forgotEmailOrPassword'})
}

</script>
<style scoped lang="scss">
:deep(.label-hint) {
  font-weight: var(--font-weight-1);
  margin-top: 0;
}
.back-button {
  position: absolute;
  bottom: 6rem;
  @media (min-width: 48em) {
    bottom: calc(100%);
    right: calc(100% - .5rem);
  }
}

.extended-btn {
  width: 13rem;
}
</style>