<template>
  <nav class="app-nav">
    <div class="app-nav-container">
      <!-- Nav List -->
      <ul class="nav-list">
        <li class="nav-item" :class="{ active: isActive('messages') }">
          <a
            class="nav-link"
            @click="goto('messages')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-messages" class="nav-icon" />
            <span class="nav-text">{{ t('nav.messaging') }}</span>
            <div v-if="badgeCounts.newMessageCount > 0" class="nav-notify">
              {{ badgeCounts.newMessageCount }}
            </div>
          </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('calling') }">
          <a
            class="nav-link"
            @click="goto('calling')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-video-calling" class="nav-icon" />
            <span class="nav-text">{{ t('nav.calling') }}<sup>SM</sup></span>
          </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('calendar') }">
          <a
            class="nav-link"
            @click="goto('calendar')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-calendar" class="nav-icon" />
            <span class="nav-text">{{ t('nav.calendar') }}</span>
            <div
              v-if="badgeCounts.newCalendarEventCount > 0"
              class="nav-notify"
            >
              {{ badgeCounts.newCalendarEventCount }}
            </div>
          </a>
        </li>
        <li
          class="nav-item"
          :class="{ active: isActive('accountablePayments') }"
        >
          <a
            class="nav-link"
            @click="goto('moneyTransfer')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-payments" class="nav-icon" />
            <span class="nav-text">{{ t('nav.payments') }}<sup>SM</sup></span>
            <div
              v-if="badgeCounts.newAccountablePaymentCount > 0"
              class="nav-notify"
            >
              {{ badgeCounts.newAccountablePaymentCount }}
            </div>
          </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('attachments') }">
          <a
            class="nav-link"
            @click="goto('attachments')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-attachments" class="nav-icon" />
            <span class="nav-text">{{ t('nav.attachments') }}</span>
          </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('infoLibrary') }">
          <a
            class="nav-link"
            @click="goto('infoLibrary')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-infolibrary" class="nav-icon" />
            <span class="nav-text">{{ t('nav.info') }}</span>
            <div
              v-if="badgeCounts.newInfoLibraryCardCount > 0"
              class="nav-notify"
            >
              {{ badgeCounts.newInfoLibraryCardCount }}
            </div>
          </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('records') }">
          <a
            class="nav-link"
            @click="goto('recordsHome')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-records" class="nav-icon" />
            <span class="nav-text">{{ t('nav.records') }}</span>
          </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('journal') }">
          <a
            class="nav-link"
            @click="goto('journal')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-journal" class="nav-icon" />
            <span class="nav-text">{{ t('nav.journal') }}</span>
          </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('vault') }">
          <a
            class="nav-link"
            @click="goto('vault')"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
            ref="buttonRef"
          >
            <font-awesome-icon icon="fak fa-vault" class="nav-icon" />
            <span class="nav-text">{{ t('nav.vault') }}</span>
          </a>
        </li>
        <!---->
        <li class="nav-item" :class="{ active: isActive('accountSettings') }">
          <a class="nav-link" @click="goto('accountSettingsHome')" tabindex="0">
            <font-awesome-icon icon="fa-light fa-user-gear" class="nav-icon" />
            <span class="nav-text">{{ t('nav.settings') }}</span>
          </a>
        </li>
        <li href="" class="nav-item">
          <a
            class="nav-link"
            target="_blank"
            href="https://support.talkingparents.com/hc/en-us"
          >
            <font-awesome-icon
              icon="fa-light fa-circle-question"
              class="nav-icon"
            />
            <span class="nav-text">{{ t('nav.help') }}</span>
          </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('contactus') }">
          <a class="nav-link" @click="goto('contactus')" tabindex="0">
            <font-awesome-icon icon="fa-light fa-envelope" class="nav-icon" />
            <span class="nav-text">{{ t('nav.contact') }}</span>
          </a>
        </li>
        <li href="" class="nav-item">
          <router-link to="/logout" class="nav-link">
            <font-awesome-icon
              icon="fa-light fa-arrow-right-from-bracket"
              class="nav-icon"
            />
            <span class="nav-text">{{ t('nav.signout') }}</span>
          </router-link>
        </li>
      </ul>

      <ColorModeCycle />

      <div class="mt-auto">
        <div v-if="showBetaToggle">
          <div class="new-look mb-1">
            <font-awesome-icon
              icon="fa-light fa-party-horn"
              class="icon-size-1"
            />
            <span class="font-size-000 label-small">New look!</span>
            <toggle
              name="UIToggle"
              label="Try the New Look!"
              class="white"
              v-model="fullUserInfo.brandConsistencyEnabled"
              @change.once="toggleBrandConsistencyEnabled()"
            ></toggle>
          </div>
        </div>
        <!-- User -->
        <article class="nav-user">
          <avatar :is-parent="true" :size="2"></avatar>
          <div class="nav-user-content">
            <strong style="word-break: break-all">{{
              fullUserInfo.fullName
            }}</strong>
            <div class="ellipsis font-size-0">
              {{ fullUserInfo.email }}
            </div>
            <button
              type="button"
              @click="navigateToPlanDetails"
              class="pill plan-type"
            >
              {{ getPlanName }}
            </button>
          </div>
        </article>
      </div>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { br } from '@/plugins/trackerPlugin'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { useCommonStore } from '@/stores/CommonStore'
import { useLoginStore } from '@/stores/LoginStore'
import { storeToRefs } from 'pinia'
import { inject, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Avatar from '@/components/library/Avatar.vue'
import Toggle from '@/components/library/Toggle.vue'
import ColorModeCycle from '@/components/ColorModeCycle.vue'
import type { ITrackingTrackFunction } from '@/models/interfaces'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({
  inheritLocale: true
})

/* */
const buttonRef = ref<HTMLElement | null>(null)

const handleKeyDown = (e: KeyboardEvent) => {
  const key = e.target as HTMLElement
  e.preventDefault()
  key.click()
}
/* */

const track = inject<ITrackingTrackFunction>(
  '$trackingTrack'
) as ITrackingTrackFunction

const commonStore = useCommonStore()
const { badgeCounts, fullUserInfo, showBetaToggle } = storeToRefs(commonStore)
const { goToVue2App } = commonStore
const loginStore = useLoginStore()

const accountSettingsStore = useAccountSettingsStore()
const { getPlanName } = storeToRefs(accountSettingsStore)

const router = useRouter()
const route = useRoute()

const weblayerId = import.meta.env.VITE_APP_BRANCON_FEEDBACK_WEBLAYERID
const launchWebLayer: any = inject('$trackingLaunchWebLayer')

const state = reactive({
  currentTrackingNavigationName: route.meta?.trackingName
    ? (route.meta.trackingName as string)
    : ''
})

function goto(routename: string) {
  router
    .push({
      name: routename
    })
    .then(() => {
      // Track the nav event
      const _trackingName = getTrackingNavigationName()
      // eslint-disable-next-line camelcase
      track(br.eventTypes.appAction, {
        feature: _trackingName,
        name: br.appActionEventNames.navigation
      })
      state.currentTrackingNavigationName = _trackingName
    })
}
async function toggleBrandConsistencyEnabled() {
  await accountSettingsStore.updateBrandConsistencyEnabled(false)
  // send to vue 2 app
  goToVue2App()
}

function isActive(pageName: string) {
  const splitPath = route.path.split('/')
  return splitPath[1] == pageName
}

function getTrackingNavigationName(): string {
  return route.meta?.trackingName ? (route.meta.trackingName as string) : ''
}

function navigateToPlanDetails() {
  // // bloomreach
  // track(br.eventTypes.appAction, {
  //   name: br.appActionEventNames.usermenuCTAUpgrade
  // })
  router.push({ name: 'planDetails' })
}

function callForWebLayer() {
  //window.exponea.showBanner(weblayerId)
  launchWebLayer(weblayerId)
}
</script>

<style lang="scss" scoped>
.feedback-link {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-inline: 1.5rem;
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  gap: 0.5rem;
  color: var(--purple);

  @media (min-width: 48em) {
    justify-content: center;
  }

  @media (prefers-color-scheme: dark) {
    color: #f069b7;
    color: #d772a2;
    color: var(--pink-4);
  }
}

.new-look {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: var(--brand-gradient-1);
  color: white;
  border-radius: 3rem;
  padding: 0.25rem 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: fit-content;

  &:deep(input[type='checkbox']:checked.switch) {
    background-color: #8dd6e9;
  }
  &:deep(input[type='checkbox'].switch::after) {
    --abg: white;
  }
  &:deep(.inline-label) {
    font-size: var(--font-size-1);
  }
  &:deep(.inline-label span) {
    margin-left: auto;
    white-space: nowrap;
  }
  &:deep(.inline-label input[type='checkbox']) {
    order: 2;
  }
}
.label-small {
  display: none;
}

@media (min-width: 48em) {
  .nav-collapse .new-look {
    flex-direction: column;
  }
  .nav-collapse .label-small {
    display: block;
    white-space: nowrap;
  }
  .nav-collapse .feedback-link {
    display: none;
  }
  .nav-collapse .new-look:deep(.inline-label span) {
    display: none;
  }
  .nav-collapse .new-look:deep(.switch) {
    margin-left: -0.25rem;
  }
  .nav-collapse .new-look {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-block: 0.5rem;

    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-inline: 0.5rem;

    border-radius: 0.75rem;
    gap: 0.25rem;
  }
}
.app-nav {
  box-shadow: var(--box-shadow-1);
  // background-color: var(--white);
  z-index: 1900;
  transition: width 0.5s ease;
  width: 100%;
  height: 100%;

  @media (min-width: 48em) {
    width: 295px;
    height: calc(100%);
  }

  @media (min-width: 1024px) {
    height: 100%;
    box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.08);
    position: relative;
    overflow-y: visible;
  }

  sup {
    font-size: 6px;
    top: -4px;
    position: relative;
  }
}

.app-nav-container {
  overflow-y: auto;
  overscroll-behavior: contain;
  position: sticky;
  top: 2.875rem;
  height: calc(100lvh - 2.875rem);
  height: calc(100dvh - 2.875rem);
  display: flex;
  flex-direction: column;

  @media (min-width: 48em) {
    top: 3.125rem;
    height: calc(100dvh - 3.125rem);
    padding-bottom: 0;
  }

  @media (min-width: 1024px) {
    top: 4.5rem;
    height: calc(100dvh - 4.5rem);
  }
}

@media (max-width: 1023.9px) {
  .app-nav {
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.5s var(--ease-out-4);
  }
  .nav-open .app-nav {
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s var(--ease-in-3);
  }
}

.nav-collapse .nav-user {
  background-color: transparent;
  padding: 0;
  align-items: flex-end;
}
.nav-collapse .nav-user-content {
  opacity: 0;
  visibility: 0;
  display: none;
}
.nav-collapse .avatar.user {
  margin-left: auto;
  margin-right: auto;
  margin-inline: auto;

  margin-bottom: 1.5rem;
}

.nav-collapse .avatar.size-md {
  width: 40px;
  height: 40px;
}
.avatar.size-md {
  width: 30px;
  height: 30px;
}
.nav-user {
  overflow: hidden;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  background-color: var(--surface-2);

  border-radius: 0.5rem;

  margin-left: 1rem;
  margin-right: 1rem;
  margin-inline: 1rem;

  padding: 1rem;
  align-items: flex-start;
  flex: none;

  .nav-user-content {
    min-width: 0;
    min-width: 40px;

    div {
      color: var(--text-2);
    }
  }

  .plan-type {
    background: var(--brand-gradient-1);
    font-size: 0.75rem;
    color: white;
    padding: 0.25rem 0.5rem;
    margin-top: 0.5rem;
    border: 0;
  }
}

@media (min-width: 1024px) {
  .nav-collapse .app-nav {
    width: 92px;
    transition: width 0.5s var(--ease-in-4);
  }
}

.nav-list {
  margin-top: var(--size-1);
  margin-bottom: var(--size-1);
  margin-block: var(--size-1);
  align-self: flex-start;
  height: fit-content;
  width: 100%;
}

.nav-link {
  display: flex;
  align-items: center;
  position: relative;
  padding: var(--size-0) var(--size-1);
  overflow: hidden;
  color: var(--text-1);
  width: 100%;

  @media (max-width: 768px) {
    font-size: var(--font-size-2);
  }

  /* Nav Hover */
  &:hover {
    opacity: 0.7;
  }
}

.active a {
  color: var(--purple);
  font-weight: var(--font-weight-2);
}

.active a::before {
  content: '';
  background-color: var(--purple);
  position: absolute;
  width: 3px;
  height: 2rem;
  left: 0;
}

.nav-notify {
  min-width: 25px;
  height: 18px;
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding-left: var(--size-000);
  padding-right: var(--size-000);
  padding-inline: var(--size-000);
  background-color: var(--focus-blue);
  font-size: var(--font-size-0);
  color: var(--white);
  border-radius: var(--radius-1);
}

/* Navigation Divider */
.nav-list li:nth-last-child(4) {
  position: relative;
  margin-top: var(--size-0);
  padding-top: var(--size-0);

  &::before {
    content: '';
    display: block;
    width: calc(100% - (var(--size-1) * 2));

    margin-left: var(--size-1);
    margin-right: var(--size-1);
    margin-inline: var(--size-1);

    height: 1px;
    background-color: var(--surface-4);
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1024px) {
  .nav-collapse .nav-list li:nth-last-child(4)::before {
    margin-left: 0;
    margin-right: 0;
    margin-inline: 0;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .nav-icon {
    width: var(--size-1) !important;
    height: var(--size-1) !important;
    margin-right: 1rem;
  }
}

/* Desktop */
@media (min-width: 1024px) {
  /* Navigation */
  .nav-collapse .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-inline: 1.5rem;
    gap: var(--size-2);
  }
  .nav-collapse .nav-text {
    opacity: 0;
  }
  /* Nav Icon */
  .nav-text {
    white-space: nowrap;
    transition: all 0.25s ease;
    opacity: 1;
  }

  /* Nav Icon */
  .nav-icon {
    width: var(--size-1) !important;
    height: var(--size-1) !important;
    margin-right: 1rem;
    transition: width 0.25s var(--ease-5);
  }

  .nav-collapse .nav-icon {
    width: var(--size-2) !important;
    height: var(--size-2) !important;
  }
}
</style>
