// src/services/realtimeDatabaseService.ts
import { database } from './firebase.ts'
import { ref, set, get, remove, child, onValue, off } from 'firebase/database'
import {
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence
} from 'firebase/auth'

class RealtimeDatabaseService {
  private db = database

  async authenticateUser(token: string) {
    const auth = getAuth()
    try {
      await setPersistence(auth, browserSessionPersistence)
      await signInWithCustomToken(auth, token)
      return true
    } catch (error) {
      console.error('Authentication failed:', error)
      return false
    }
  }

  async watchForUserStatusChange() {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User authenticated successfully')
        const uid = user.uid
        // ...
        return true
      } else {
        console.log('User is not authenticated')
        return false
      }
    })
  }

  // Write data to a specified path
  async writeData<T>(path: string, data: T): Promise<void> {
    try {
      console.log('Calling DB!')

      await set(ref(this.db, path), data)
        .then(() => {
          // Data saved successfully!
          console.log('Data saved successfully!')
        })
        .catch((error) => {
          console.error('Error saving data: ', error)
        })
      console.log('Data saved successfully!')
    } catch (error) {
      console.error('Error saving data: ', error)
    }
  }

  // Read data from a specified path
  async readData<T>(path: string): Promise<T | null> {
    try {
      const snapshot = await get(child(ref(this.db), path))
      if (snapshot.exists()) {
        return snapshot.val() as T
      } else {
        console.log('No data available at this path!')
        return null
      }
    } catch (error) {
      console.error('Error reading data: ', error)
      return null
    }
  }

  // Read data from a specified path
  async deleteData(path: string): Promise<void> {
    try {
      remove(child(ref(this.db), path))
    } catch (error) {
      console.error('Error deleting data: ', error)
    }
  }

  // Listen for new or updated data at a specified path
  listenForNewEntries<T>(
    path: string,
    callback: (data: T | null) => void
  ): () => void {
    const dbRef = ref(this.db, path)
    try {
      const unsubscribe = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            callback(snapshot.val() as T)
          } else {
            callback(null)
          }
        },
        (error) => {
          console.error('Error listening for data: ', error)
        }
      )
    } catch (error) {
      console.error('Error listenForNewEntries: ', error)
    }
    // Return a function to allow unsubscribing from the listener
    return () => off(dbRef)
  }
}

export const realtimeDatabaseService = new RealtimeDatabaseService()
