<template>
  <div class="calling-modal-meta">
    <div
      class="warn"
      v-if="canCallStatus.get(constants.CAN_CALL_STATUS.notPremium)"
    >
      You must 
        <RouterLink 
          @click="setShowVideoCall(false)"
          :to="{ name: 'accountTypeStep1' }"
        >
        <strong> upgrade to a Premium Plan </strong>
        </RouterLink> 
      to make a recorded phone or video call.
    </div>
    <div
      class="warn"
      v-if="canCallStatus.get(constants.CAN_CALL_STATUS.videoCallingDisabled)"
    >
      Video calling is disabled. 
      <RouterLink
        @click="setShowVideoCall(false)"
        :to="{ name: 'accountableCalling' }"
      >
        <strong> Go to settings </strong>
      </RouterLink> 
      to enable video calling.
    </div>
    <div
      class="warn"
      v-if="canCallStatus.get(constants.CAN_CALL_STATUS.coParentIssue)"
    >
      Your co-parent has disabled video calling.
    </div>
    <div
      class="warn"
      v-if="loading || canCallStatus.get(constants.CAN_CALL_STATUS.noMinutes) && !canCallStatus.get(constants.CAN_CALL_STATUS.notPremium)"
      v-skeleton="{
        loading: loading,
        minWidth: 30,
        maxWidth: 30
      }"
    >
      You are out of calling minutes. Please add minutes to your account to make
      a video call.
    </div>
    <div 
      v-if="!canCallStatus.get(constants.CAN_CALL_STATUS.notPremium)" 
      class="available-minutes"
    >
      <p 
        v-skeleton="{
          loading: loading,
          minWidth: 20,
          maxWidth: 20
        }"
      >Available minutes: <span class="font-bold">{{ callingBalance }}</span></p> 
      <button  
        class="btn-text font-bold"
        @click="addMinutesClick"
        v-skeleton="{
          loading: loading,
          minWidth: 20,
          maxWidth: 20
        }"
      >Add minutes</button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import constants from '@/exports/constants'
import { useCallingStore } from '@/stores/CallingStore'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { storeToRefs } from 'pinia'
import { useModals } from '@/composables/useModal/useModal'
import UpgradeMinutes from '@/components/UpgradeMinutes.vue'
import UpgradeDialog from '@/pages/infoLibrary/components/upgradeDialogComponent.vue'

const callingStore = useCallingStore()
const { canCallStatus } = storeToRefs(callingStore)
const { setShowVideoCall } = callingStore

const accountSettingsStore = useAccountSettingsStore()
const { callingBalance, subscriptionT2 } = storeToRefs(accountSettingsStore)

const { createSlot, generateModal } = useModals()

function addMinutesClick() {
  if (subscriptionT2.value) {
    showCallingMinutesModal()
  } else {
    showUpgradeModal()
  }
}

function showCallingMinutesModal() {
  generateModal({
    slot: {
      content: createSlot('content', UpgradeMinutes).content
    },
    default: {
      headerText: 'Select minutes package'
    },
    config: {
      showHeader: true,
      showFooter: false,
      showCloseButton: true,
      closeOnOutsideClick: true
    }
  })
}

// todo: wording
function showUpgradeModal() {
  generateModal({
      slot: createSlot(
        'content',
        UpgradeDialog,
        {
          'header-text': 'Unabled to add minutes',
          'upgrade-button-text': 'Upgrade'
        },
        {
          default: () =>
            'You must be a premium user to make phone or video calls.'
        }
      ),
      config: {
        showHeader: false,
        showFooter: false,
        addContentPadding: false
      }
  })
}

const props = withDefaults(
  defineProps<{
    loading: boolean
  }>(),
  {
    loading: false
  }
)
</script>

<style scoped>
.calling-modal-meta {
  display: flex;
  flex-direction: column;
  gap: var(--size-00);
  justify-content: center;
  margin: 0 var(--size-00);
  position: absolute;
  top: 0;
  z-index: 2500;
  background-color: var(--surface-1);
  max-width: 45ch;
  margin-inline: auto;
  inset-inline: 0;
  padding: .5rem 1rem;
  border-radius: 1rem;
  transform: translate3d(0,3.5rem,0); 
  transition: transform .2s ease-in-out;
  width: fit-content;
}
.calling-modal-meta > * { margin: 0; }
.calling-modal-meta > * + * {
  margin-top: 1em;
}

.available-minutes {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 0.25rem;
  width: fit-content;
}
.btn-text {
  white-space: none;
  width: fit-content;
  background-color: transparent;
  border-radius: 2.625rem;
  padding: 0;
  color: var(--text-1);
  text-decoration: none;
  color: var(--purple);
  box-shadow: none !important;
  padding:0; 
  border: none;
}

.warn {
  --color-opacity: .5;
  width: fit-content;
  background-color: var(--gray-cool-6);
  border-radius: var(--radius-2);
  padding: var(--space-3xs) var(--space-xs);
  color: var(--text-1);
  margin: 0 auto;
}

strong {
  color: var(--text-1)
}
</style>
