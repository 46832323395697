<template>
  <modal-v2-component
    :modalActive="modalActiveRef"
    :showFooter="false"
    title="Multi-factor authentication"
    @close="close()"
  >
    <template #content>
      <form class="p-3" role="form" @submit.prevent="onSubmit" >
        <div class="place-center">
          <header class="">
            <p class="text-center mt0 line-height-2">
              Enter the 6-digit code sent to {{ redactedPhoneNumber }}.<br>
              Not seeing it? Visit our
              <a
                href="https://support.talkingparents.com/hc/en-us"
                target="_self"
                class="font-size-1 purple underline text-center mt-3"
              >
                Help Center.
              </a>
            </p>
          </header>
          <code-input
            id="codes"
            ref="codes"
            style="border-radius: 0px"
            @codeResult="handleCodeResult"
            @focusedInput="resetMessages()"
            autocomplete="false"
          />
          <span
            v-if="mfaErrorMsg.length > 0"
            class="font-size-0 error text-center pt-00"
          >
            {{ mfaErrorMsg }}
          </span>
        </div>
        <footer class="stack size-1 mt-2">
          <button
            ref="ConfirmEnableMfaSmsButton"
            type="submit"
            class="btn primary"
            :disabled="!meta.dirty || !meta.valid || state.submitting || isLockedOut"
          >
          <loading :loading="state.submitting"/>
            Verify
          </button>
          <button
            id="codeResendButton"
            type="button"
            class="btn tertiary purple underline"
            @click="resendCode()"
            style="font-weight: var(--font-weight-1);"
            :disabled="isLockedOut"
            >
              send another code
          </button>
          <span
            v-if="state.showResendSuccess"
            class="bg-lt-blue code-resent mt-2 text-center ml-auto mr-auto"
          >
            Verification code sent to {{ redactedPhoneNumber }}
          </span>
        </footer>
      </form>
    </template>
  </modal-v2-component>
</template>
<script lang="ts" setup>
import { toRef, reactive, computed, watch, ref, onUnmounted } from 'vue'
import { useLoginStore } from '@/stores/LoginStore'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { useRoute, useRouter } from 'vue-router'
import type { ILoginRequest } from '@/models/interfaces'
import ErrorHelper from '@/exports/error'
import CodeInput from '@/components/CodeInputComponent.vue'
import ModalV2Component from '@/components/ModalV2Component.vue'
import Loading from '@/components/library/Loading.vue'

const props = defineProps({
  modalActive: {
    type: Boolean,
    required: true
  },
  email: {
    type: String,
    required: true
  },
  password: {
    type: String,
    required: true
  }
})

const modalActiveRef = toRef(props, 'modalActive')
const codes = ref(null)

const route = useRoute()

const loginStore = useLoginStore()
const { redactedPhoneNumber, mfaErrorMsg, isLockedOut } = storeToRefs(loginStore)
const { login } = loginStore

const state = reactive({
  mfaErrorMsg: '',
  codeResult: '',
  submitting: false,
  showResendSuccess: false,
})

const { handleSubmit, meta, handleReset } = useForm()

const onSubmit = handleSubmit(() => {
  submitAuthCode()
})

onUnmounted(() => {
  isLockedOut.value = false
  resetMessages()
})

async function resendCode() {
  state.submitting = true

  const payload: ILoginRequest = {
    username: props.email,
    password: props.password,
    returnUrl: ''
  }
  await login(payload)
  state.showResendSuccess = true
  state.submitting = false

  resetMfa()
}

function resetMfa() {
  state.submitting = false
  handleReset()
}

function handleCodeResult(value: string) {
  state.showResendSuccess = false
  state.codeResult = value
}

watch(
  () => mfaErrorMsg.value,
  async (val) => {
    if (val.length) resetMfa()
  }
)

function submitAuthCode() {
  state.showResendSuccess = false

  if (state.submitting) return
  state.submitting = true
  try {
    const payload: ILoginRequest = {
      username: props.email,
      password: props.password,
      mfaCode: state.codeResult,
      returnUrl: route.query.returnTo?.toString()
    }

    emit('submitLogin', payload)
  } catch (e) {
    ErrorHelper.handleError(e, 'submitAuthCode', false, '')
    state.submitting = false
    return
  }
}

// MFA
const emit = defineEmits<{
  (e: 'close'): void
  (e: 'submitLogin', payload: ILoginRequest): void
}>()

function close() {
  resetMfa()
  emit('close')
}

function resetMessages() {
  state.showResendSuccess = false

  if (!isLockedOut.value) {
    mfaErrorMsg.value = ''
  }
}
</script>
<style lang="scss" scoped>
:deep(.modal-inner) {
  @media (min-width: 48em) {
    width: 600px;
  }
}

.code-resent {
  border-radius: var(--radius-4);
  font-weight: var(--font-weight-2);
  padding: var(--size-1) var(--size-4);
  width: fit-content;
}
</style>
