<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div v-show="loadingRef" class="loading-div" ref="loadingDiv">
    <div class="loader">
      <!--<font-awesome-icon icon="fa-light fa-spinner-third" spin />-->
      <svg class="circular" view-box="25 25 50 50">
        <circle
          class="path"
          cx="50%"
          cy="50%"
          r="50%"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRef, ref, watch } from 'vue'
import { useElementSize, useParentElement } from '@vueuse/core'

const props = defineProps({
  loading: {
    type: Boolean,
    required: true
  },
  class: {
    type: String,
    required: false
  }
})

const loadingRef = toRef(props, 'loading')

const loadingDiv = ref<HTMLElement>()

const parentEl = useParentElement(loadingDiv)
const { width: parentElWidth, height: parentElHeight } =
  useElementSize(parentEl)

// const state = reactive({
//   dots: ''
// })

watch(
  () => [parentElWidth.value, parentElHeight.value],
  async ([widthVal, heightVal]) => {
    if (loadingDiv.value) {
      const computedStyle = getComputedStyle(parentEl.value as HTMLElement)

      loadingDiv.value.style.width = `${
        widthVal +
        parseInt(computedStyle.paddingLeft) +
        parseInt(computedStyle.paddingRight)
      }px`
      loadingDiv.value.style.height = `${
        heightVal +
        parseInt(computedStyle.paddingTop) +
        parseInt(computedStyle.paddingBottom)
      }px`

      loadingDiv.value.style.zIndex = (
        +(parentEl.value?.style.zIndex ?? '0') + 1
      ).toString()

      loadingDiv.value.style.marginTop = `${
        parseInt(computedStyle.paddingTop) * -1
      }px`
      loadingDiv.value.style.marginRight = `${
        parseInt(computedStyle.paddingRight) * -1
      }px`
      loadingDiv.value.style.marginBottom = `${
        parseInt(computedStyle.paddingBottom) * -1
      }px`
      loadingDiv.value.style.marginLeft = `${
        parseInt(computedStyle.paddingLeft) * -1
      }px`
    }
  }
)

// watch(
//   () => loading.value,
//   async (val) => {
//     if (val) {
//       do {
//         for (let i = 0; i < 4; i++) {
//           switch (i) {
//             case 0:
//               state.dots = '.'
//               break
//             case 1:
//               state.dots = '..'
//               break
//             case 2:
//               state.dots = '...'
//               break
//             case 3:
//               state.dots = ''
//               break
//           }
//           await helper.delay(250)
//         }
//       } while (loading.value)
//     }
//   }
// )
</script>

<style lang="scss" scoped>
.loading-div {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  color: var(--focus-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  pointer-events: none;
  padding-block: var(--size-000);
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 100px;
  height: 100%;
  max-height: 100px;

  &::before {
    content: '';
    display: block;
    //padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  width: unset;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: visible;
  aspect-ratio: 1 / 1;
  padding: 4px;
}

.path {
  stroke-dasharray: 1, 10000;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 10000;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 222.5%, 10000;
    stroke-dashoffset: -87.5%;
  }
  100% {
    stroke-dasharray: 222.5%, 10000;
    stroke-dashoffset: -310%;
  }
}

@keyframes color {
  0%,
  100% {
    stroke: var(--focus-blue);
  }
}
</style>
