{
    "layout": {
        "offline": "You are currently offline."
    },
    "footer": {
        "copyright": "Copyright"
    },
    "nav": {
        "messaging": "Secure Messaging",
        "attachments": "Attachment Library",
        "calendar": "Shared Calendar",
        "payments": "Accountable Payments",
        "info": "Info Library",
        "records": "Unalterable Records",
        "journal": "Personal Journal",
        "settings": "Account Settings",
        "help": "Help Center",
        "contact": "Contact Us",
        "signout": "Sign Out",
        "tryNewLook": "Try the New Look!",
        "tryNewLook_sm": "New Look!",
        "feedback": "Give us your feedback",
        "vault": "Vault File Storage",
        "calling": "Accountable Calling"
    },
    "titles": {
        "messaging": "Messaging with ",
        "messagingMobile": "Messaging",
        "attachments": "Attachment Library",
        "calendar": "Shared Calendar",
        "payments": "Accountable Payments",
        "info": "Info Library",
        "records": "Unalterable Records",
        "journal": "Personal Journal",
        "settings": "Account Settings",
        "help": "Help Center",
        "contact": "Contact Us",
        "vault": "Vault File Storage",
        "calling": "Accountable Calling"
    },
    "messages": {
        "newMessageBtn": "New message",
        "search": "Search messages",
        "searchMobile": "Search",
        "total": "total",
        "sortAndFilter": {
            "sort": {
                "legend": "Sort by",
                "values": {
                    "newestToOldest": "Newest to oldest activity",
                    "oldestToNewest": "Oldest to newest activity",
                    "relevance": "Relevance"
                }
            },
            "filter": {
                "legend": "Filter by",
                "values": {
                    "subject": "Subject",
                    "attachment": "Attachment",
                    "message": "Message",
                    "all": "All"
                }
            },
            "sent": {
                "legend": "Sent from",
                "values": {
                    "both": "Both",
                    "coparent": "From",
                    "you": "To"
                }
            },
            "date": {
                "legend": "Date",
                "values": {
                    "allTime": "All time",
                    "today": "Today",
                    "thisWeek": "This week",
                    "thisYear": "This Year ({year})",
                    "lastYear": "Last Year ({year})",
                    "custom": "Custom",
                    "rangeFrom": "From",
                    "rangeTo": "To"

                }
            },
            "showUnreadMessagesOnly": "Unread",
            "applyFilters": "Apply Filters",
            "results": "Results: {count}",
            "clearFilters": "Clear filters"
        },
        "isNewSubtitle": "New message from {name}",
        "conversationSubtitle": "{name}: {content}"
    },
    "banners": {
        "upgradeBannerFreeText": "Upgrade to a Standard or Premium Plan",
        "upgradeBannerStandardPlan": "Upgrade to a Premium Plan",
        "highlightWords": "Standard, Premium Plan",
        "upgradeBannerMessageTier0": "for unlimited access to all attachments.",
        "upgradeBannerRecordsTier0": "for unlimited PDF access, plus discounted rates on Printed Records.",
        "upgradeBannerRecordsTier1": "to save an additional $10 on every Printed Record."
    },
    "vault": {
        "header": {
            "searchPlaceholder": "Search",
            "newFolderButton": "New folder",
            "uploadFileButton": "Upload file"
        },
        "newFolderModal": {
            "header": "New folder",
            "folderNameLabel": "Folder name",
            "descriptionLabel": "Description (optional)",
            "submitButton": "Save"
        },
        "quickAccess": {
            "all": "All items",
            "favorites": "Favorites",
            "recent": "Recent",
            "files": "Files",
            "folders": "Folders",
            "images": "Images",
            "video": "Video",
            "trash": "Trash"
        },
        "item": {
            "settings": {
                "menu": "Menu",
                "open": "Open",
                "addToFavorites": "Add to favorites",
                "removeFromFavorites": "Remove from favorites",
                "download": "Download",
                "move": "Move",
                "edit": "Edit",
                "archive": "Move to trash",
                "share": "Share",
                "copyPrivateLink": "Copy private link",
                "privateLinkCopied": "Copied! Link will expire in 24 hours.",
                "restore": "Restore",
                "delete": "Delete"
            }
        },
        "emptyTrashModal": {
            "header": "Empty trash",
            "content": "This action will permanently delete all items in your trash.",
            "cancelButton": "Cancel",
            "emptyButton": "Delete all"
        },
        "modalOptions": {
            "moveToTrash": "Move to trash",
            "restore": "Restore",
            "remove": "Remove",
            "delete": "Delete",
            "moveToTrashContent": " will be moved to trash",
            "moveToTrashConfirmation": "Are you sure you want to permanently delete ",
            "move": "Move",
            "items": "items",
            "file": "file",
            "folder": "folder",
            "restoreConfirmation": "Are you sure you want to restore ",
            "cancelButton": "Cancel",
            "restoreButton": "Restore",
            "deleteButton": "Delete",
            "moveToTrashButton": "Move to trash"
        },
        "editing": {
            "header": "Editing",
            "cancelButton": "Cancel",
            "saveButton": "Save",
            "fileNameLabel": "File name",
            "folderNameLabel": "Folder name",
            "descriptionLabel": "Description (optional)"
        },
        "undo": {
            "textSingle": "Item moved",
            "textMultiple": "Items moved",
            "button": "Undo"
        },
        "sortDropdown": {
            "selectLabelledBy": "Sort Vault items"
        },
        "share": {
            "header": "Share \"{filename}\"",
            "sendInEmailLabel": "Send in email",
            "noteLabel": "Note",
            "expiresLegend": "Link expires after",
            "expires24Hours": "24 hours",
            "expires48Hours": "48 hours",
            "expiresNever": "Never",
            "copyLinkButton": "Copy link",
            "sendInviteButton": "Send invite",
            "copyLinkPopperBase": "Copied!",
            "copyLinkPopper24Hours": "Link expires in 1 day.",
            "copyLinkPopper48Hours": "Link expires in 2 days.",
            "foldersCannotBeSharedPopper": "Folders cannot be shared"
        }
    },
    "search": {
        "placeholder": "Search"
    },
    "downloads": {
        "inProgressCancelModal": {
            "header": "Cancel Downloads",
            "content": "Some items are still downloading. Are you sure you want to stop all of them?",
            "cancelButton": "Cancel",
            "confirmButton": "Confirm"
        },
        "list": {
            "headerSingle": "Downloading 1 item",
            "headerMultiple": "Downloading {count} items",
            "item": {
                "issue": "There was an issue downloading your file. Please try again.",
                "completed": "Completed",
                "reDownload": "Re-download"
            }
        }
    }
  }