<template>
  <MainContentComponent
    :class="{
      'layout-inner-sm': showingNoCount && !welcomePagesViewed.messages
    }"
    :padding="!isMobileWidth"
  >
    <router-view class="messages-container" />
  </MainContentComponent>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'
import { useMessagesStore } from '@/stores/MessagesStore'
import MainContentComponent from './components/MainContentComponent.vue'
import { onUnmounted } from 'vue'

const commonStore = useCommonStore()
const { showingNoCount, welcomePagesViewed, isMobileWidth } =
  storeToRefs(commonStore)

const messagesStore = useMessagesStore()
const {
  setThreadListSearchTerm,
  setThreadListPage,
  setThreadListScrollPosition
} = messagesStore

onUnmounted(() => {
  setThreadListSearchTerm('')
  setThreadListPage(1)
  setThreadListScrollPosition(0)
})
</script>

<style scoped>
.flow-space > * + * {
  margin-top: 1rem;
}
</style>
