<template>
  <div v-if="!isModal" class="welcome-root" :class="{'mobile-background' : isMobileWidth}">
    <h2 :class="isMobileWidth ? 'ml-1 mt-1' : 'mb-1'">
      Welcome to Secure Messaging!
    </h2>
    <layout-box class="fit-content-height">
      <welcome-component
        :sub-header-text="subHeaderText"
        :video-url="videoUrl"
        :video-title="videoTitle"
        :bullet-list="introBulletPoints"
      >
        <template #ctaButtons>
          <button
            class="btn primary mx-auto mt-4 place-center"
            type="button"
            @click="viewWelcomePage()"
          >
            {{ buttonText }}
          </button>
        </template>
      </welcome-component>
    </layout-box>
  </div>

  <Modal
    v-else
    :modal-active="isModal"
    title="About Secure Messaging"
    @close="setshowInfoModal(false)"
  >
    <template #content>
      <div :class="isMobileWidth ? 'p-1' : 'p-2'">
        <welcome-component
          :sub-header-text="subHeaderText"
          :video-url="videoUrl"
          :video-title="videoTitle"
          :bullet-list="introBulletPoints"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'
import LayoutBox from '@/layouts/components/LayoutBoxComponent.vue'
import Modal from '@/components/ModalV2Component.vue'
import WelcomeComponent from '@/components/WelcomeComponent.vue'

const props = defineProps({
  isModal: {
    type: Boolean,
    default: false,
    required: true
  }
})

const commonStore = useCommonStore()
const {
  setWelcomePageViewed,
  setshowInfoModal,
  setShowInfoButtonLayoutOption
} = commonStore
const { isMobileWidth } = storeToRefs(commonStore)

const subHeaderText =
  'Keep accountable communications with your co-parent in one place with Secure Messaging'

const introBulletPoints = [
  "Can't edit or delete messages once sent",
  'Timestamped',
  'Easily upload up to 5 attachments'
]

const videoTitle = 'Welcome to Secure Messaging!'
const videoUrl =
  'https://fast.wistia.net/embed/iframe/fknldyy3gf?videoFoam=true'

const buttonText = 'Get Started'

function viewWelcomePage() {
  setWelcomePageViewed('messages', true)
  setShowInfoButtonLayoutOption(true)
}
</script>
<style scoped>
.mobile-background {
  height: 100%;
  background-color: var(--surface-1);
}
</style>
