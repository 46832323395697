import httpClient, { httpClientAnonymous } from '../httpClient/index.ts'
import ErrorHelper from '../exports/error.ts'
import { defineStore } from 'pinia'
import { useCommonStore } from './CommonStore.ts'
import type {
  ConfirmEnableMfaSmsRequest,
  ISendResetCodeRequest,
  IVerifyResetCodeRequest
} from '@/models/models.ts'
import type {
  ILoginRequest,
  IUpdatePasswordRequest,
  IWebToggleLoginRequest
} from '@/models/interfaces.ts'
import type { ILoginState } from '@/models/stores/login.ts'
import { useAccountSettingsStore } from './AccountSettingsStore.ts'
import { useAttachmentsStore } from './AttachmentsStore.ts'
import { useCalendarStore } from './CalendarStore.ts'
import { useInfoLibraryStore } from './InfoLibraryStore.ts'
import { useJournalStore } from './JournalStore.ts'
import { useMessagesStore } from './MessagesStore.ts'
import { useMoneyTransferStore } from './moneyTransferStore.ts'
import { useRecordsStore } from './RecordsStore.ts'
import { useSignupStore } from './SignupStore.ts'
import { useVaultStore } from './VaultStore.ts'
import { useCallingStore } from './CallingStore.ts'

export const useLoginStore = defineStore('login', {
  state: (): ILoginState => ({
    logInId: 0,
    showMfaModal: false,
    loginError: '',
    requestedCode: '',
    authToken: '',
    mfaToken: '',
    mfaCookie: '',
    loggingOut: false,
    redactedPhoneNumber: '',
    verifyResetCodeError: '',
    mfaErrorMsg: '',
    isLockedOut: false
  }),
  persist: {
    paths: ['authToken', 'mfaToken', 'mfaCookie']
  },
  getters: {
    showLoginErrorMsg: (state) => {
      return state.loginError.length > 0
    }
  },
  actions: {
    reset() {
      this.logInId = 0
      this.showMfaModal = false
      this.loginError = ''
      this.requestedCode = ''
      this.authToken = ''
      this.loggingOut = false
      this.redactedPhoneNumber = ''
      this.verifyResetCodeError = ''
      this.mfaErrorMsg = ''
    },
    async confirmMfaSmsCode(
      payload: ConfirmEnableMfaSmsRequest
    ): Promise<string> {
      try {
        const endpoint = '/web/api/Token/ConfirmMfaSmsCode'
        const result = await httpClient.post(endpoint, payload)

        if (result.data.success) {
          this.authToken = result.data.authToken
          this.mfaCookie = result.data.mfaCookie
          return 'success'
        } else {
          return 'error'
        }
      } catch (error) {
        ErrorHelper.handleError(error, 'confirmMfaSmsCode')
        const common = useCommonStore()
        common.loading = false
        return 'error'
      }
    },
    async login(payload: ILoginRequest) {
      console.log('payload: ', payload)
      const common = useCommonStore()
      this.loggingOut = false
      const endpoint = '/web/api/Token/Login'
      try {
        // resetitng lockout and error messages
        this.isLockedOut = false
        this.mfaErrorMsg = ''
        //add mfa headers for token, and cookie
        const cookie = this.mfaCookie
        const result = await httpClientAnonymous.post(endpoint, payload, {
          headers: {
            MfaToken:
              payload.mfaCode && this.logInId > 0
                ? JSON.stringify({
                    LoginLogId: this.logInId,
                    MfaSmsCode: payload.mfaCode
                  })
                : '',
            MfaCookie: cookie
          }
        })

        console.log('login result: ', result)

        if (result.data.success) {
          this.authToken = result.data.authToken
          if (result.data.mfaCookie && result.data.mfaCookie.length > 0) {
            this.mfaCookie = result.data.mfaCookie
          }
          this.loginError = ''
          return 'success'
        } else {
          // Login error enum
          // 0: ServerError,
          // 1: Invalid,
          // 2: UserLockedOut,
          // 3: MfaSmsRequired,
          // 4: InvalidMfaSmsCode
          switch (result.data.errorCode) {
            case 0:
              throw new Error(result.data.errorMessage)
            case 1:
              this.loginError = result.data.errorMessage
              break
            case 2:
              this.isLockedOut = true
              this.mfaErrorMsg =
                'You have reached the maximum number of verification attempts within 24 hours for this phone number. If you need assistance, please contact support.'
              this.loginError = result.data.errorMessage
              break
            case 3: // two factor auth
              this.redactedPhoneNumber = result.data.redactedPhoneNumber
              this.logInId = result.data.loginLogId
              this.showMfaModal = true
              break
            case 4:
              if (
                result.data.errorMessage ==
                'Exceeded max verification attempts.'
              ) {
                this.mfaErrorMsg =
                  'You have reached the maximum number of attempts for this code. Try sending another code. If you need assistance, please contact support.'
              } else {
                this.mfaErrorMsg = 'Invalid authentication code.'
              }
              break
            default:
              throw new Error(result.data.errorMessage)
          }
          return 'failed'
        }
      } catch (error) {
        console.log('error: ', error)
        ErrorHelper.handleError(error, 'login')
        common.loading = false
        return 'error'
      }
    },
    async logout() {
      if (this.loggingOut) return false
      this.loggingOut = true
      //TODO: Set up signalR connection
      //stop signalr connection
      //Vue.prototype.$socket.stop()

      try {
        const endpoint = '/web/api/Token/Revoke'
        //revoke refresh token.
        //TODO: add withCredentials options to httpClient call
        await httpClientAnonymous.post(endpoint)

      } catch (error) {
        ErrorHelper.handleError(error, 'logout')
      }

      this.resetAllStores()

      return true
    },
    async requestPasswordReset(payload: ISendResetCodeRequest) {
      // payload {email, mobilePhone}
      const endpoint = '/web/api/accountRecovery/SendResetCode'
      try {
        //TODO: add version option for httoClientV2
        const result = await httpClientAnonymous.post(endpoint, payload, {
          headers: {
            'api-version': '2'
          }
        })

        if (result.data.success) {
          this.verifyResetCodeError = ''
          return result.data.value
        } else {
          const err = new Error(result.data.errorMessage)
          ErrorHelper.handleError(
            err,
            'requestPasswordReset',
            result.data.errorCode > 1,
            result.data.errorMessage
          )
          // Error Code = 4 = Too many requests
          if (result.data.errorCode == 4) {
            this.verifyResetCodeError = result.data.errorMessage
          } else {
            this.verifyResetCodeError = ''
          }
        }
      } catch (error) {
        ErrorHelper.handleError(error, 'requestPasswordReset')
        const common = useCommonStore()
        common.loading = false
      }
    },
    async verifyResetCode(payload: IVerifyResetCodeRequest) {
      // payload {VerifyCode, MobilePhone}
      this.requestedCode = payload.verifyCode || ''

      const endpoint = '/web/api/accountRecovery/VerifyResetCode'
      try {
        const result = await httpClientAnonymous.post(endpoint, payload, {
          headers: {
            'api-version': '2'
          }
        })
        if (result.data.success) {
          this.verifyResetCodeError = ''
          return result.data.value
        } else {
          const err = new Error(result.data.errorMessage)
          ErrorHelper.handleError(
            err,
            'requestPasswordReset',
            result.data.errorCode > 0,
            result.data.errorMessage
          )
          // ErrorCode = 1 = Wrong Verify Code
          // ErrorCode = 4 = Too many attempts
          if (result.data.errorCode == 1 || result.data.errorCode == 4) {
            this.verifyResetCodeError = result.data.errorMessage
          } else {
            this.verifyResetCodeError = ''
          }
        }
      } catch (error) {
        ErrorHelper.handleError(error, 'verifyResetCode')
        const common = useCommonStore()
        common.loading = false
      }
    },
    async verifyPasswordResetCodeFromEmail(payload: IVerifyResetCodeRequest) {
      // payload {VerifyCode, MobilePhone}
      this.requestedCode = payload.verifyCode || ''
      const endpoint =
        '/web/api/accountRecovery/verifyPasswordResetCodeFromEmail'
      try {
        const result = await httpClientAnonymous.post(endpoint, payload)
        if (result.data.success) {
          return result.data.value
        } else {
          const err = new Error(result.data.errorMessage)
          ErrorHelper.handleError(
            err,
            'requestPasswordReset',
            result.data.errorCode > 0,
            result.data.errorMessage
          )
        }
      } catch (error) {
        ErrorHelper.handleError(error, 'verifyPasswordResetCodeFromEmail')
        const common = useCommonStore()
        common.loading = false
      }
    },
    async updatePassword(payload: IUpdatePasswordRequest) {
      // payload {newPassword, userId}
      // add the requestedCode
      payload.requestCode = this.requestedCode
      const endpoint = '/web/api/accountRecovery/UpdatePasswordFromReset'
      try {
        const result = await httpClientAnonymous.post(endpoint, payload, {
          headers: {
            'api-version': '2'
          }
        })
        if (result.data.success) {
          return result.data.value
        } else {
          const err = new Error(result.data.errorMessage)
          ErrorHelper.handleError(
            err,
            'requestPasswordReset',
            result.data.errorCode == 2
          )
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'saveEntry')
        const common = useCommonStore()
        common.loading = false
      }
    },
    async getWebToggleToken(): Promise<string> {
      try {
        const endpoint = '/web/api/Token/GetWebToggleToken'
        const result = await httpClient.get(endpoint)
        if (result.data.success) {
          return result.data.webToggleToken
        } else {
          // if this failed, show the generic "Something went wrong"
          return ''
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'getWebToggleToken')
        return ''
      }
    },
    async webToggleLogin(payload: IWebToggleLoginRequest) {
      try {
        const endpoint = '/web/api/Token/WebToggleLogin'
        const result = await httpClientAnonymous.post(endpoint, payload)
        if (result.data.success) {
          this.setAuthTokenMethod(result.data.authToken)
        } else {
          // redirect to login (but maintain the return url?)
          this.router.push({ name: 'login' })
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'webToggleLogin')
      }
    },
    async setAuthTokenMethod(authToken: string) {
      this.authToken = authToken
    },
    async setMfaCookieMethod(mfaCookie: string) {
      this.mfaCookie = mfaCookie
    },
    async setShowMfaModal(value: boolean) {
      this.showMfaModal = value
    },
    async setVerifyErrorMessage(err: string) {
      this.verifyResetCodeError = err
    },
    resetAllStores() {
      const common = useCommonStore()
      const accountSettings = useAccountSettingsStore()
      const attachments = useAttachmentsStore()
      const calendar = useCalendarStore()
      const infoLibrary = useInfoLibraryStore()
      const journal = useJournalStore()
      const messages = useMessagesStore()
      const moneyTransfer = useMoneyTransferStore()
      const records = useRecordsStore()
      const signup = useSignupStore()
      const vault = useVaultStore()
      //const calling = useCallingStore()

      this.reset()
      common.$reset()
      accountSettings.$reset()
      attachments.$reset()
      calendar.$reset()
      infoLibrary.$reset()
      journal.$reset()
      messages.$reset()
      moneyTransfer.$reset()
      records.$reset()
      signup.$reset()
      vault.reset()
      //calling.$reset()
    }
  }
})
