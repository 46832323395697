<template>
  <div>
    <div class="stack size-000">
      <span class="font-size-3">Purchase minutes</span>
      <span class="line-height-1">
        Purchased minutes do not expire. They are only deducted from your
        available minutes after your monthly renewing minutes are used.
      </span>
      <span class="font-size-1 font-weight-2 mt-1">
        <strong>Available minutes</strong>
      </span>
      <span class="font-size-1 medium-gray" style="margin-top: 0">
        {{ callingBalance }} minutes
      </span>
    </div>
    <div class="flex align-center mt-2">
      <div class="stack gap-2">
        <button
          class="btn primary w-100"
          style="margin-top: 0"
          v-for="plan in plans"
          :key="plan.itemID"
          @click="purchaseMinutes(plan)"
          :disabled="state.purchaseMinutesSubmitting"
          :class="{ disabled: state.purchaseMinutesSubmitting }"
        >
          <loading
            :loading="
              plan.itemID == state.selectedPlanItemId &&
              state.purchaseMinutesSubmitting
            "
          />
          <strong>Add {{ plan.minutes }} minutes for ${{ plan.price }}</strong>
        </button>
        <!-- TODO V2
        <span class="dark-gray mt-5">
          Not seeing your purchased minutes?
        </span>
        <button
          type="button"
          class="btn secondary font-weight-1 w-100"
          style="margin-top: 0"
        >
          Restore my minutes
        </button> -->
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { useCallingStore } from '@/stores/CallingStore'
import Loading from './library/Loading.vue'
import {
  CreateCheckoutSessionRequest,
  SessionLineItemOptions
} from '@/models/models'
import type { IPlan } from '@/models/interfaces'

const route = useRoute()

const callingStore = useCallingStore()
const { showAudioCall, showVideoCall } = storeToRefs(callingStore)

const accountSettingStore = useAccountSettingsStore()
const { callingBalance, plans } = storeToRefs(accountSettingStore)
const {
  createCheckoutSessionForAddMinutes,
  getStripePriceIdForProduct,
  fetchPlans,
  fetchCallingBalance
} = accountSettingStore

const state = reactive({
  selectedPlanItemId: 1,
  purchaseMinutesSubmitting: false
})

onBeforeMount(async () => {
  await fetchPlans()
  await fetchCallingBalance()
})

async function purchaseMinutes(selectedPlan: IPlan) {
  state.purchaseMinutesSubmitting = true
  state.selectedPlanItemId = selectedPlan.itemID ?? 1

  let priceId = ''

  switch (selectedPlan.itemID) {
    case 1:
      priceId = (await getStripePriceIdForProduct('minutes100')) || ''
      break
    case 2:
      priceId = (await getStripePriceIdForProduct('minutes300')) || ''
      break
    case 3:
      priceId = (await getStripePriceIdForProduct('minutes1200')) || ''
      break
    default:
      priceId = (await getStripePriceIdForProduct('minutes100')) || ''
  }

  const lineItems = [
    new SessionLineItemOptions({
      price: priceId,
      quantity: 1
    })
  ]

  const metadata = {
    PlanItemID: selectedPlan.itemID?.toString() || '',
    TPCheckoutType: 'purchaseMinutes'
  }

  // success and cancel set by props
  const payload = new CreateCheckoutSessionRequest({
    successUrl: successUrl.value,
    cancelUrl: cancelUrl.value,
    lineItems: lineItems,
    metadata: metadata,
    allowPromotionCodes: true
  })

  const session = await createCheckoutSessionForAddMinutes(payload)
  if (session?.sessionUrl) {
    window.location.href = session.sessionUrl
  }
}

const successUrl = computed(
  () =>
    `${import.meta.env.VITE_APP_BASE_URL}minSuccess/{CHECKOUT_SESSION_ID}?p=${
      route.fullPath
    }&audio=${showAudioCall.value}&video=${showVideoCall.value}`
)

const cancelUrl = computed(
  () =>
    `${import.meta.env.VITE_APP_BASE_URL}minCancel/{CHECKOUT_SESSION_ID}?p=${
      route.fullPath
    }&audio=${showAudioCall.value}&video=${showVideoCall.value}`
)
</script>
<style scoped>
strong {
  --base-weight: 600;
}
</style>
