<script setup>
import { useColorMode, useCycleList } from '@vueuse/core'
import { watchEffect } from 'vue'

const mode = useColorMode({
  attribute: 'color-scheme'
})

const { state, next } = useCycleList(['dark', 'light'], { initialValue: mode })

watchEffect(() => {
  mode.value = state.value
})
</script>

<template>
  <div class="root flex flex-column items-start">
    <div class="btn-container flex items-center">
      <span class="label-light">Light</span>
      <button class="color-cycle" @click="next()">
        <span
          v-if="state === 'dark' || state === 'light'"
          class="icon-container"
        >
          <svg
            class="sun-and-moon"
            aria-hidden="true"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <mask class="moon" id="moon-mask">
              <rect x="0" y="0" width="100%" height="100%" fill="white" />
              <circle cx="24" cy="10" r="6" fill="black" />
            </mask>
            <circle
              class="sun"
              cx="12"
              cy="12"
              r="6"
              mask="url(#moon-mask)"
              fill="currentColor"
            />
            <g class="sun-beams" stroke="currentColor">
              <line x1="12" y1="1" x2="12" y2="3" />
              <line x1="12" y1="21" x2="12" y2="23" />
              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
              <line x1="1" y1="12" x2="3" y2="12" />
              <line x1="21" y1="12" x2="23" y2="12" />
              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
            </g>
          </svg>
        </span>
      </button>
      <span class="label-dark">Dark</span>
    </div>
    <span class="scheme-state">{{ state }} mode</span>
    <small class="scheme-directions p-4 opacity-50"
      >↑ Change the color mode</small
    >
  </div>
</template>

<style scoped>
.root {
  margin-left: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btn-container {
  gap: 0.5rem;
}

.color-cycle {
  display: inline-flex;
  border-radius: 3rem;
  width: 82px;
  border: 0;
  padding: 0.25rem;
  background-color: var(--surface-3);
  box-shadow: var(--inner-shadow-2);
}

/* Theme Toggle */
.nav-collapse .color-cycle {
  display: inline-block;
  padding: 0.5rem;
  width: 55px;
}

.icon-container {
  display: flex;
  background-color: var(--surface-2);
  border-radius: 50%;
  padding: 0.25rem;
  transform: translate3d(0, 0, 0) !important;
  transition: 0.5s var(--ease-elastic-3) !important;
  box-shadow: var(--shadow-1);
}

.nav-collapse .icon-container {
  transition: none !important;
}

@media (prefers-color-scheme: dark) {
  .icon-container {
    transform: translate3d(130%, 0, 0);
    transition: 0.5s var(--ease-elastic-3) !important;
  }
  .nav-collapse .icon-container {
    transform: translate3d(0, 0, 0);
    transition: none !important;
  }
}

[color-scheme='light'] {
  .icon-container {
    display: flex;
    background-color: white;
    border-radius: 50%;
    transform: translate3d(0, 0, 0) !important;
    transition: 0.5s var(--ease-elastic-1) !important;
  }
  .nav-collapse .icon-container {
    transition: none !important;
  }
}

[color-scheme='dark'] {
  .icon-container {
    transform: translate3d(130%, 0, 0) !important;
    transition: 0.5s var(--ease-elastic-3) !important;
  }
  .nav-collapse .icon-container {
    transform: translate3d(0, 0, 0) !important;
    transition: none !important;
  }
}

.nav-collapse :is(.label-light, .label-dark) {
  display: none;
}

.sun-and-moon {
  --icon-fill: var(--brand-7);
  --icon-fill-hover: var(--brand-6);
  stroke-linecap: round;

  filter: none;
}
.sun-and-moon > :is(.moon, .sun, .sun-beams) {
  transform-origin: center;
}

.sun-and-moon > :is(.moon, .sun) {
  fill: var(--icon-fill);
  stroke: none;
}

.theme-toggle:is(:hover, :focus-visible) > .sun-and-moon > :is(.moon, .sun) {
  fill: var(--icon-fill-hover);
}

.sun-and-moon > .sun-beams {
  stroke: var(--icon-fill);
  stroke-width: 2px;
}

.theme-toggle:is(:hover, :focus-visible) .sun-and-moon > .sun-beams {
  stroke: var(--icon-fill-hover);
}

[color-scheme='dark'] .sun-and-moon > .sun {
  transform: scale(1.75);
}

[color-scheme='dark'] .sun-and-moon > .sun-beams {
  opacity: 0;
}

[color-scheme='dark'] .sun-and-moon > .moon > circle {
  transform: translateX(-7px) !important;
}

@supports (cx: 1) {
  [color-scheme='dark'] .sun-and-moon > .moon > circle {
    cx: 17;
    transform: translateX(0) !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .sun-and-moon > .sun {
    transition: transform 0.5s var(--ease-elastic-3) !important;
  }

  .sun-and-moon > .sun-beams {
    transition:
      transform 0.5s var(--ease-elastic-4),
      opacity 0.5s var(--ease-3) !important;
  }

  .sun-and-moon .moon > circle {
    transition: transform 0.25s var(--ease-out-5) !important;
  }

  @supports (cx: 1) {
    .sun-and-moon .moon > circle {
      transition: cx 0.25s var(--ease-out-5) !important;
    }
  }

  [color-scheme='dark'] .sun-and-moon > .sun {
    transition-timing-function: var(--ease-3) !important;
    transition-duration: 0.25s !important;
    transform: scale(1.75) !important;
  }

  [color-scheme='dark'] .sun-and-moon > .sun-beams {
    transition-duration: 0.15s !important;
    transform: rotateZ(-25deg) !important;
  }

  [color-scheme='dark'] .sun-and-moon > .moon > circle {
    transition-duration: 0.5s !important;
    transition-delay: 0.25s !important;
  }
}

.scheme-state {
  display: none;
  text-transform: capitalize;
  margin-top: 0.25rem;
}
.scheme-directions {
  margin-top: 0.5rem;
}

.nav-collapse .scheme-directions {
  display: none;
}
.nav-collapse .scheme-state {
  display: inline-block;
  margin-left: -0.5rem;
}
</style>
