import * as SignalR from '@microsoft/signalr'
import { HubConnectionBuilder } from '@microsoft/signalr'

class SignalRHub {
  client: SignalR.HubConnection
  authToken = ''

  constructor() {
    this.client = new HubConnectionBuilder()
      .withUrl(import.meta.env.VITE_APP_SIGNALR_BASE_URL + 'hubs/chat', {
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => this.authToken
      })
      .withAutomaticReconnect()
      .configureLogging(SignalR.LogLevel.Debug)
      .build()
  }

  start(token: string) {
    this.authToken = token
    this.client.start()
  }
}

export default new SignalRHub()
